import React, { FC, ReactElement } from 'react';

import { POSITIONS } from '@savgroup-front-common/constants';
import { BackgroundDimmer } from '@savgroup-front-common/core/src/atoms/BackgroundDimmer';
import { FormGroup } from '@savgroup-front-common/core/src/atoms/Form';
import { MessageType } from '@savgroup-front-common/types';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';
import SearchInput from 'control/view/atoms/SearchInput';
import { SEARCH_ANIMATION_DURATION } from 'control/view/components/Header/Search/Search.contants';

import { SearchInputVariant } from '../../atoms/SearchInput/SearchInput.types';

import SearchHistory from './SearchHistory/SearchHistory';
import { useLatestHistoryItems } from './SearchHistory/SearchHistory.hooks';
import SearchResult from './SearchResult/SearchResult';
import { useSearchWithDimmer } from './SearchWithDimmer.hooks';
import {
  $ResultContainer,
  $SearchWithDimmerWrapper,
} from './SearchWithDimmer.styles';
import { ResultSearchType } from './SearchWithDimmer.types';

const SearchWithDimmer: FC<{
  query?: string;
  placeholder?: MessageType | string;
  results?: SearchResultList<ResultSearchType>[];
  isLoading?: boolean;
  onSearch?: (value: string) => void;
  onShowMore?: () => void;
  onHistoryChange?: (items: string[]) => void;
  searchHistoryItems?: string[];
  minWidthResult?: number;
  position?: POSITIONS;
  topResult?: number;
  searchFilter?: ReactElement;
  withoutMarginBottom?: boolean;
  isHightContrast?: boolean;
  variant?: SearchInputVariant;
}> = ({
  query = '',
  placeholder,
  results,
  isLoading = false,
  onSearch,
  onShowMore,
  searchHistoryItems = [],
  minWidthResult,
  position = POSITIONS.LEFT,
  topResult,
  searchFilter,
  withoutMarginBottom = false,
  isHightContrast = false,
  variant = SearchInputVariant.DEFAULT,
}) => {
  const { lastItemsSearched } = useLatestHistoryItems({
    searchHistoryItems,
  });
  const {
    value,
    isResultVisible,
    sizeResult,
    handleSearchInputChange,
    setIsResultVisible,
  } = useSearchWithDimmer({
    query,
    results,
    onSearch,
    lastItemsSearched,
  });

  return (
    <$SearchWithDimmerWrapper>
      <FormGroup withoutPaddingBottom={withoutMarginBottom}>
        <SearchInput
          value={value}
          placeholder={placeholder}
          onChange={(e) => handleSearchInputChange(e.target.value)}
          onFocus={() => setIsResultVisible(true)}
          isActive={isResultVisible}
          isLoading={isLoading}
          variant={variant}
          searchFilter={searchFilter}
          isHightContrast={isHightContrast}
          onClear={() => handleSearchInputChange('')}
        />
      </FormGroup>

      <$ResultContainer
        $isVisible={isResultVisible}
        $size={sizeResult}
        $minWidth={minWidthResult}
        $position={position}
        $top={topResult}
        animationDuration={SEARCH_ANIMATION_DURATION.CONTAINER}
      >
        <SearchHistory
          searchHistoryItems={searchHistoryItems}
          onClick={(value) => handleSearchInputChange(value)}
        />

        {results && results.length > 0 && (
          <SearchResult
            results={results}
            searchValue={value}
            onShowMore={onShowMore}
          />
        )}
      </$ResultContainer>

      <BackgroundDimmer
        onClick={() => setIsResultVisible(false)}
        isOpen={isResultVisible}
        animationDuration={SEARCH_ANIMATION_DURATION.CONTAINER}
        zIndex={11}
        opacity={0.25}
      />
    </$SearchWithDimmerWrapper>
  );
};

export default SearchWithDimmer;

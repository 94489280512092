import styled from 'styled-components';

export const $PreviewFeature = styled.div`
  display: flex;
  gap: 1rem;

  & > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  & > div::first-letter {
    text-transform: capitalize;
  }

  & > div:first-child {
    flex: none;
  }
`;

import {
  PRICING_PLAN,
  ReverseMoneyNumberAmount,
} from '@savgroup-front-common/types';

export enum PricingType {
  OnUsage = 'OnUsage',
  Custom = 'Custom',
  Packaged = 'Packaged',
}

export enum BillingCycle {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export interface FeatureDto {
  uniqueName: string;
  items: {
    name: string;
    localisationKey?: string;
  }[];
}

export interface PreviewFeatureDto {
  name: string;
}

export type PlanDto =
  | {
      id: string;
      name: string;
      pricingPlan: PRICING_PLAN;
      needToContactSales: boolean;
      pricingType: PricingType.OnUsage;
      usagePrice: {
        perFile: ReverseMoneyNumberAmount;
      };
      previewFeatures: PreviewFeatureDto[];
      features: FeatureDto[];
    }
  | {
      id: string;
      name: string;
      pricingPlan: PRICING_PLAN;
      needToContactSales: boolean;
      pricingType: PricingType.Packaged;
      billingCycle: BillingCycle;
      cyclePrice: ReverseMoneyNumberAmount;
      usagePrice?: {
        perFile: ReverseMoneyNumberAmount;
      };
      previewFeatures: PreviewFeatureDto[];
      features: FeatureDto[];
    }
  | {
      id: string;
      name: string;
      pricingPlan: PRICING_PLAN;
      needToContactSales: boolean;
      pricingType: PricingType.Custom;
      previewFeatures: PreviewFeatureDto[];
      features: FeatureDto[];
    };

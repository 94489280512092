import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  ProductQualification,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}productAdequacies`;

const getProductAdequaciesQuery = async ({
  sellerId,
}: {
  sellerId: string;
}): Promise<BackResponse<ProductQualification[]> | BackResponseFailure> => {
  try {
    const url = buildUrl(ENDPOINT, { sellerId });

    return await apiCall<BackResponse<ProductQualification[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getProductAdequaciesQuery;

import concat from 'lodash/concat';
import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import matches from 'lodash/matches';
import maxBy from 'lodash/maxBy';
import noop from 'lodash/noop';
import result from 'lodash/result';
import size from 'lodash/size';
import toLower from 'lodash/toLower';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import { matchPath } from 'react-router-dom';
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import {
  DOCUMENT_STATUS,
  FILE_STATES,
  RMA_DOCUMENT_TYPES,
} from '@savgroup-front-common/constants/src/shared';
import {
  ActionCreators as AttachmentsActionCreators,
  ActionTypes as AttachmentsActionTypes,
  Constants as AttachmentsConstants,
  Selectors as AttachmentsSelectors,
} from '@savgroup-front-common/core/src/domains/attachments';
import {
  ActionCreators as CarriersActionCreators,
  ActionTypes as CarriersActionTypes,
} from '@savgroup-front-common/core/src/domains/carriers';
import { ActionCreators as ClaimsActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import { loadFullClaimsByGroupId } from '@savgroup-front-common/core/src/domains/claims/actionCreators';
import { LOAD_FULL_CLAIMS_BY_GROUP_ID } from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { LoginSelectors } from '@savgroup-front-common/core/src/domains/login';
import { wasEveryActionLoadedSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors';
import { storeActionCreators } from '@savgroup-front-common/core/src/domains/store';
import {
  ActionCreators as WorkflowActionCreators,
  ActionTypes as WorkflowActionTypes,
  Selectors as WorkflowSelectors,
} from '@savgroup-front-common/core/src/domains/workflow';
import { setSyncUserActionSuccess } from '@savgroup-front-common/core/src/domains/workflow/actionCreators';
import { fibonacci } from '@savgroup-front-common/core/src/helpers';
import { Selectors as AdditionalInformationSelectors } from 'control/domains/additionalInformation';
import { fileSummarySelectors } from 'control/domains/workflow/fileSummary';
import {
  GET_FILE_FULL_SUMMARY,
  getFullFileSummary,
} from 'control/domains/workflow/fileSummary/actions';

import { extractFileAdditionalInformation } from '../../view/components/FileInfo/FileTabs/documents/Documents.helpers';
import {
  selectFullClaimsByFileId,
  selectOrderIdByFileId,
} from '../business/businessSelectors';
import * as WorkflowActions from '../business/workflow/actionCreators';
import { getDocumentsByClaimId } from '../claims/actionCreators';
import { GET_DOCUMENTS_BY_CLAIM_ID } from '../claims/actionTypes';
import { selectDocumentByClaimId } from '../claims/selectors';
import { LOGIN_SIGNED_IN } from '../login/actions';
import * as permissionsViewSelectors from '../login/permissionsViewSelectors';
import { ActionCreators as MarketplaceActionCreators } from '../marketplaceTickets';
import { getModelById } from '../models/actionCreators';
import { GET_MODEL_BY_ID } from '../models/actionTypes';
import { loadMarketplaceDetailedOrder } from '../orders/actionCreators';
import { LOAD_MARKETPLACE_DETAILED_ORDER } from '../orders/actionTypes';
import { getDetailedOrderByOrderId } from '../orders/selectors';
import { loadOwnerInfo } from '../owner/actionCreators';
import {
  ActionCreators as QuoteActionCreators,
  ActionTypes as QuoteActionTypes,
} from '../quote';
import * as RefundsActions from '../refunds/actionCreators';
import { getFileStates } from '../workflow/actionCreators';
import { GET_FILE_STATES } from '../workflow/actionTypes';

import { submitAttachments } from './actionCreators';
import * as FileInfoActionTypes from './actionTypes';
import {
  EXPLODE_FILE_REQUEST,
  FOLDERS_FORCE_CLOSE_FILE,
  HYDRATE_FILE_SUMMARY,
  SPLIT_FILE_REQUEST,
  SUBMIT_ATTACHMENTS,
} from './actionTypes';
import * as fileDetailsSelectors from './fileDetails';

const { MYACCOUNT, CONTROL } = AttachmentsConstants.attachmentsListOrigins;
const LONG_RELOAD_DELAY = 5000;
const MEDIUM_RELOAD_DELAY = 1500;
const SHORT_RELOAD_DELAY = 1000;
const MAX_RETRY_FILE_ACTIONS = 6;

function isFileClosed(state) {
  return get(state, ['state', 'name']) === FILE_STATES.CLOSED;
}

function* selectLatestState({ fileId }) {
  const states = yield select(fileDetailsSelectors.selectFileStatesById, {
    fileId,
  });

  return maxBy(states, (s) => get(s, 'stateDate'));
}

function* selectFileActions({ fileId }) {
  const fileActionsState = yield select(
    WorkflowSelectors.selectFileActionsState,
  );
  const fileActions = fileActionsState.getIn([fileId, 'value']);
  const isLoaded = fileActionsState.getIn([fileId, 'isLoaded']);

  return {
    fileActions,
    isLoaded,
  };
}

function* loadAndReturnFileActions({ fileId }) {
  yield all([
    put(WorkflowActionCreators.getFileActions(fileId)),
    take(
      matches({
        type: WorkflowActionTypes.GET_FILE_ACTIONS.END,
        meta: { fileId },
      }),
    ),
  ]);

  return yield call(selectFileActions, { fileId });
}

function* loadAndReturnLabels({ fileId }) {
  yield all([
    put(CarriersActionCreators.loadLabels({ fileId })),
    take(CarriersActionTypes.LOAD_LABELS.END),
  ]);

  return yield select(fileDetailsSelectors.selectLabelsByFileId, { fileId });
}

function* loadAndReturnFileSummary({ fileId }) {
  yield all([
    put(getFullFileSummary(fileId)),
    take(
      matches({
        type: GET_FILE_FULL_SUMMARY.END,
        meta: {
          fileId,
        },
      }),
    ),
  ]);

  const summary = yield select(fileSummarySelectors.fileSummary);

  return {
    hasErrors: summary?.[fileId]?.hasErrors,
    value: summary?.[fileId]?.value,
  };
}

function* loadAndReturnLatestFileState({ fileId }) {
  yield all([
    put(getFileStates(fileId)),
    take(
      matches({
        type: GET_FILE_STATES.END,
        meta: fileId,
      }),
    ),
  ]);

  return yield call(selectLatestState, { fileId });
}

function* forceCloseFileWorker({
  payload: { fileId, userId, closingType, comment, toCustomerComment },
}) {
  yield all([
    put(WorkflowActions.onWorkflowStateUpdateStarted({ fileId })),
    put(
      WorkflowActionCreators.forceCloseFile({
        fileId,
        userId,
        closingType,
        comment,
        toCustomerComment,
      }),
    ),
    take(
      matches({
        type: WorkflowActionTypes.FORCE_CLOSE_FILE.END,
        meta: { fileId },
      }),
    ),
  ]);

  yield call(loadAndReturnLatestFileState, { fileId });
}

function* processFileAfterExplodeWorker(
  explodedFileId,
  childFileId,
  toState,
  comment,
) {
  let fileState;

  while (true) {
    yield delay(700);

    const { value: summaryValue, hasErrors: summeryHasErrors } = yield call(
      loadAndReturnFileSummary,
      { fileId: childFileId },
    );

    const fileProducts = get(summaryValue, 'fileProducts');
    const isSingleProduct = size(fileProducts) === 1;

    if (!summeryHasErrors && isSingleProduct) {
      const fileReference = get(summaryValue, 'fileReference');

      yield put(
        WorkflowActionCreators.updateExplodeFileState({
          updatedFileState: { fileReference, processed: true },
          childFileId,
          explodedFileId,
        }),
      );
      fileState = get(summaryValue, ['currentState', 'name']);
      break;
    }
  }

  const { fileActions } = yield call(loadAndReturnFileActions, {
    fileId: childFileId,
  });

  const action = head(fileActions);
  const fromState = get(action, ['currentState', 'name']);
  const module = get(action, 'module');

  yield put(WorkflowActions.onWorkflowStatePending({ fileId: childFileId }));

  if (explodedFileId !== childFileId) {
    const attachments = yield select(AttachmentsSelectors.uploadAttachments);
    const attachedFiles = attachments.get(explodedFileId);
    const attachedFilesJs = result(attachedFiles, 'toJS');

    yield all(
      values(attachedFilesJs).map(({ value }) =>
        put(
          AttachmentsActionCreators.uploadFileAttachment({
            file: value.file,
            fileId: childFileId,
            service: 'control',
          }),
        ),
      ),
    );

    yield all(
      values(attachedFilesJs).map(({ value }) =>
        take(
          matches({
            type: AttachmentsActionTypes.UPLOAD_FILE_ATTACHMENT_DATA.END,
            meta: {
              id: value.id,
            },
          }),
        ),
      ),
    );
  }

  yield put(
    submitAttachments({
      fileId: childFileId,
      module,
      from: { name: fromState },
      to: { name: toState },
      completeTransition: noop,
    }),
  );

  yield put(
    WorkflowActionCreators.setUserActionAndHydrateFileSummary({
      fileId: childFileId,
      module,
      fromState,
      toState,
      comment,
    }),
  );
  const [succeeded] = yield race([
    take(
      matches({
        type: WorkflowActionTypes.SET_SYNC_USER_ACTION.SUCCEEDED,
        meta: childFileId,
      }),
    ),
    take(
      matches({
        type: WorkflowActionTypes.SET_SYNC_USER_ACTION.ERRORED,
        meta: childFileId,
      }),
    ),
  ]);

  if (succeeded) {
    while (true) {
      yield delay(500);

      const latestState = yield call(loadAndReturnLatestFileState, {
        fileId: childFileId,
      });
      const latestStateName = get(latestState, ['state', 'name']);

      if (latestStateName !== fileState) {
        yield put(
          WorkflowActionCreators.updateExplodeFileState({
            updatedFileState: {
              processed: true,
            },
            childFileId,
            explodedFileId,
          }),
        );
        break;
      }
    }
  } else {
    yield put(
      WorkflowActionCreators.updateExplodeFileState({
        updatedFileState: {
          failed: true,
        },
        childFileId,
        explodedFileId,
      }),
    );
  }
}

function* explodeFileWorker({ payload: { fileId, toState, comment } }) {
  yield put(WorkflowActions.onWorkflowStatePending({ fileId }));

  yield all([
    put(WorkflowActionCreators.explodeFile(fileId)),
    take(
      matches({
        type: WorkflowActionTypes.EXPLODE_FILE.END,
        meta: {
          fileId,
        },
      }),
    ),
  ]);

  const childFilesMap = (yield select(
    WorkflowSelectors.explodeFileStateSelector,
  )).getIn([fileId, 'value']);

  yield all(
    map(childFilesMap.toJS(), (file, childFileId) =>
      fork(
        processFileAfterExplodeWorker,
        fileId,
        childFileId,
        toState,
        comment,
      ),
    ),
  );
}

function* splitFileAsyncWorker({ payload: { fileId, ownerProductIds } }) {
  yield all([
    put(WorkflowActionCreators.splitFile({ fileId, ownerProductIds })),
    take(
      matches({
        type: WorkflowActionTypes.SPLIT_FILE.END,
        meta: {
          fileId,
        },
      }),
    ),
  ]);
  const splitFileId = (yield select(
    WorkflowSelectors.splitFileStateSelector,
  )).getIn([fileId, 'value']);

  if (splitFileId) {
    while (true) {
      yield delay(300);

      const { value: summaryValue, hasErrors: summaryHasErrors } = yield call(
        loadAndReturnFileSummary,
        { fileId: splitFileId },
      );

      if (!summaryHasErrors) {
        const fileReference = get(summaryValue, 'fileReference');

        yield put(
          SPLIT_FILE_REQUEST.success({
            fileId,
            splitFileId,
            fileReference,
          }),
        );
        break;
      }
    }
  }
  yield put(SPLIT_FILE_REQUEST.end({ fileId, splitFileId }));
}

function* loadRefundData({ fileId }) {
  const canManageRefundsPermission = yield select(
    permissionsViewSelectors.canManageRefundsPermissionSelector,
  );

  if (!canManageRefundsPermission) {
    return;
  }
  yield put(RefundsActions.loadRefunds(fileId));
}

function* getAllRmaSupplier({ fileId }) {
  const fileSummary = yield select(fileSummarySelectors.fileSummary);
  const fileProducts = fileSummary?.[fileId]?.value?.fileProducts;
  const groupedProducts = map(
    groupBy(fileProducts, 'modelId'),
    (fileProduct) => fileProduct,
  );

  yield all(
    map(groupedProducts, (groupedProduct) =>
      call(function* downloadProcedureInfos() {
        yield all([
          put(
            ClaimsActionCreators.getRmaSupplierInfos(
              get(head(groupedProduct), 'claimId'),
            ),
          ),
        ]);
      }),
    ),
  );
}

function* reloadRmaDocument({ fileId, rmaModules }) {
  const fileSummary = yield select(fileSummarySelectors.fileSummary);
  const fileProducts = fileSummary?.[fileId]?.value?.fileProducts;
  const entities = yield select(
    AdditionalInformationSelectors.getAdditionalInformationLabels,
  );
  const fileAdditionalInformation = yield call(
    extractFileAdditionalInformation,
    {
      file: fileProducts,
    },
  );
  const statusOfRmaDocument = fileAdditionalInformation.map(
    (fileAdditionalInformationDocument) => {
      const additionalInformationEntity = get(entities, [
        fileAdditionalInformationDocument.additionalInformationId,
        'additionalInformationEntity',
      ]);

      return {
        id: get(additionalInformationEntity, 'id'),
        status:
          get(additionalInformationEntity, 'uniqueName') ===
          RMA_DOCUMENT_TYPES.BILL_RMA
            ? DOCUMENT_STATUS.CREATED
            : DOCUMENT_STATUS.IN_PROGRESS,
        fileId,
      };
    },
  );
  const rmaDocumentCreated = statusOfRmaDocument.some(
    (doc) => doc.status === DOCUMENT_STATUS.CREATED,
  );

  if (
    !isEmpty(rmaModules) &&
    !rmaDocumentCreated &&
    statusOfRmaDocument.length > 0
  ) {
    yield call(loadAndReturnFileSummary, {
      fileId,
    });

    yield put(
      CarriersActionTypes.RMA_LABEL_DOCUMENT_GENERATION.base({
        rmaDocumentStatus: DOCUMENT_STATUS.IN_PROGRESS,
        fileId,
      }),
    );
  } else if (!isEmpty(rmaModules) && rmaDocumentCreated) {
    yield put(
      CarriersActionTypes.RMA_LABEL_DOCUMENT_GENERATION.base({
        rmaDocumentStatus: DOCUMENT_STATUS.CREATED,
        fileId,
      }),
    );
  }
}

function* reloadLabelsWatcher({ fileId }) {
  let reloadDelay = 500;
  let oldStateCount = 0;
  let tryCount = 0;
  let putLabelGenerationProgress = true;

  while (true) {
    yield delay(reloadDelay);
    const states = yield select(fileDetailsSelectors.selectFileStatesById, {
      fileId,
    });
    const labels = yield select(fileDetailsSelectors.selectLabelsByFileId, {
      fileId,
    });
    const latestLabels = mapValues(
      groupBy(labels, 'moduleInstanceId'),
      (item) => maxBy(item, 'wave'),
    );
    const transportModules = groupBy(
      filter(states, (s) => {
        const definitionId = toLower(s.module.definitionId);
        const fromActor = get(s, 'module.parameters.fromActor');
        const toActor = get(s, 'module.parameters.toActor');

        return (
          definitionId === 'transport' ||
          definitionId === 'externalcarrier' ||
          (fromActor && toActor)
        );
      }),
      'module.id',
    );
    const rmaModules = groupBy(
      filter(states, (s) => {
        const { definitionId } = s.module;

        return definitionId.includes('CRF_Demande_RMA_Auto');
      }),
      'module.id',
    );
    const statusOfLabels = map(transportModules, (item, moduleInstanceId) => {
      const lastTransportModule = maxBy(item, 'module.wave');
      const wave = get(lastTransportModule, 'module.wave');
      const moduleDefinitionId = get(
        lastTransportModule,
        'module.definitionId',
      );
      const fromActor = get(lastTransportModule, 'module.parameters.fromActor');
      const toActor = get(lastTransportModule, 'module.parameters.toActor');
      const moduleInstanceIdWave = get(latestLabels, [
        moduleInstanceId,
        'wave',
      ]);
      const labelGenerationInProgress =
        !moduleInstanceIdWave || moduleInstanceIdWave < wave;

      return {
        moduleInstanceId,
        status: labelGenerationInProgress
          ? DOCUMENT_STATUS.IN_PROGRESS
          : DOCUMENT_STATUS.CREATED,
        moduleDefinitionId,
        fromActor,
        toActor,
        fileId,
      };
    });

    if (size(states) > oldStateCount) {
      oldStateCount = size(states);
      tryCount = 0;
    }

    if (tryCount <= 10) {
      yield call(reloadRmaDocument, {
        fileId,
        rmaModules,
      });

      const areLabelsUpToDate = every(
        transportModules,
        (item, moduleInstanceId) => {
          const wave = get(maxBy(item, 'module.wave'), 'module.wave');
          const moduleInstanceIdWave = get(latestLabels, [
            moduleInstanceId,
            'wave',
          ]);

          return moduleInstanceIdWave >= wave;
        },
      );

      if (!areLabelsUpToDate) {
        reloadDelay = MEDIUM_RELOAD_DELAY;
        yield call(loadAndReturnLabels, { fileId });
        yield put(
          CarriersActionTypes.REGENERATE_LABEL_COMMAND.base({
            regenerateLabel: statusOfLabels,
            fileId,
          }),
        );
        putLabelGenerationProgress = true;
      } else {
        reloadDelay = 500;
        if (putLabelGenerationProgress) {
          putLabelGenerationProgress = false;
          yield put(
            CarriersActionTypes.REGENERATE_LABEL_COMMAND.base({
              regenerateLabel: statusOfLabels,
              fileId,
            }),
          );
        }
      }
      tryCount += 1;
    }
  }
}

function* loadAttachments({ fileId }) {
  yield put(
    AttachmentsActionCreators.loadFilesAttachmentList({
      fileId,
      type: CONTROL,
    }),
  );
  yield put(
    AttachmentsActionCreators.loadFilesAttachmentList({
      fileId,
      type: MYACCOUNT,
    }),
  );
}

function* loadFilesInformation({ fileId }) {
  const [{ fileActions }] = yield all([
    call(loadAndReturnFileActions, { fileId }),
    call(loadAndReturnFileSummary, { fileId }),
    call(loadAttachments, { fileId }),
    call(loadRefundData, { fileId }),
  ]);

  yield call(getAllRmaSupplier, { fileId });

  yield put(WorkflowActions.onWorkflowStateSuccessful({ fileId }));

  return fileActions;
}

const getStateInfo = (state) => {
  const stateDate = get(state, 'stateDate');
  const moduleId = get(state, ['module', 'id']);

  return [stateDate, moduleId];
};

function* pollAndReloadInfoWatcher({ fileId }) {
  let reloadDelay = LONG_RELOAD_DELAY;

  while (true) {
    const [isDelayFinished] = yield race([
      delay(reloadDelay),
      take(WorkflowActionTypes.FORCE_CLOSE_FILE.END),
      take(setSyncUserActionSuccess),
      take(SPLIT_FILE_REQUEST.END),
      take(HYDRATE_FILE_SUMMARY.BASE),
    ]);

    const oldState = yield call(selectLatestState, { fileId });

    const {
      stateDate: oldLatestStateDate,
      module: { id: oldModuleId },
    } = oldState;

    let currentState = null;
    let stateDate = null;
    let moduleId = null;
    let hasActions = false;
    let hasNewModule = false;
    let hasNewStateDate = false;

    if (!isDelayFinished) {
      reloadDelay = SHORT_RELOAD_DELAY;

      do {
        currentState = yield call(loadAndReturnLatestFileState, { fileId });
        [stateDate, moduleId] = getStateInfo(currentState);
        hasNewModule = moduleId !== oldModuleId;
        hasNewStateDate = stateDate > oldLatestStateDate;

        if (reloadDelay < LONG_RELOAD_DELAY) {
          reloadDelay += 1000;
        }

        if (hasNewStateDate || hasNewModule) {
          const actions = yield loadFilesInformation({ fileId });

          hasActions = get(actions, [0, 'transitions'], []).length > 0;
        }

        if (hasNewModule || isFileClosed(currentState)) {
          reloadDelay = LONG_RELOAD_DELAY;
          break;
        }

        yield delay(reloadDelay);
      } while (!hasNewStateDate || (!hasNewModule && !hasActions));
    }
  }
}

function* reloadState({ fileId }) {
  let latestState = yield call(selectLatestState, { fileId });

  let stepCount = 1;

  while (!latestState) {
    yield delay(fibonacci(stepCount) * MEDIUM_RELOAD_DELAY);

    latestState = yield call(loadAndReturnLatestFileState, { fileId });

    stepCount += 1;
  }

  return latestState;
}

function* reloadFileActions({ fileId }) {
  let tryCount = 0;

  while (tryCount < MAX_RETRY_FILE_ACTIONS) {
    const { fileActions, isLoaded } = yield selectFileActions({ fileId });

    if (!isEmpty(fileActions) && isLoaded) {
      return;
    }

    if (tryCount === MAX_RETRY_FILE_ACTIONS - 1) {
      yield put(
        WorkflowActionTypes.SET_USER_ACTIONS_IS_EMPTY.success(
          {},
          { indexer: fileId },
        ),
      );

      return;
    }

    yield delay(MEDIUM_RELOAD_DELAY);

    if (isLoaded) {
      tryCount += 1;
    }
  }
}

function* reloadFileInfo({ fileId }) {
  let latestState = yield call(selectLatestState, { fileId });

  if (!latestState) {
    latestState = yield call(reloadState, { fileId });
  }

  yield fork(reloadFileActions, { fileId });

  if (isFileClosed(latestState)) {
    return;
  }

  yield call(getAllRmaSupplier, { fileId });

  yield race([
    call(reloadLabelsWatcher, { fileId }),
    call(pollAndReloadInfoWatcher, { fileId }),
  ]);
}

function* pollDocumentsStatus({ fileId }) {
  const latestState = yield call(selectLatestState, { fileId });

  let oldLatestStateDate = get(latestState, 'stateDate');
  const fileProducts = get(
    yield select(fileSummarySelectors.getFileSummaryValue, { fileId }),
    ['value', 'fileProducts'],
  );
  const firstClaimId = head(fileProducts).claimId;

  yield all([
    put(AttachmentsActionCreators.getAllDocumentsStatusByFileId({ fileId })),
    take(AttachmentsActionTypes.GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID.END),
  ]);

  const refreshedDocumentsByClaimId = yield select(selectDocumentByClaimId, {
    claimId: firstClaimId,
  });
  const fullClaims = yield select(selectFullClaimsByFileId, { fileId });
  const fullClaimDocument = head(fullClaims.value)?.documents;

  const voucherDocumentsWithDuplica = concat(
    fullClaimDocument,
    refreshedDocumentsByClaimId,
  ).filter((doc) => doc?.name !== 'Image');

  const voucherDocuments = uniqBy(voucherDocumentsWithDuplica, 'name');

  const filterByDocumentsWithCreatedStatus = (document) =>
    document.items?.at(0)?.status === DOCUMENT_STATUS.CREATED;
  const filterByDocumentsWithInProgressStatus = (document) =>
    document.items?.at(0)?.status === DOCUMENT_STATUS.IN_PROGRESS;

  let documentsStatusByFileId = get(
    yield select(AttachmentsSelectors.getDocumentsStatusByFileIdValue, fileId),
    'value',
  );
  let documentsWithCreatedStatusCount = size(
    filter(documentsStatusByFileId, filterByDocumentsWithCreatedStatus),
  );
  let documentsCreatedCount = voucherDocuments.length;

  let iterationNumber = 0;

  do {
    const newLatestState = yield call(selectLatestState, { fileId });
    const newLatestStateDate = get(newLatestState, 'stateDate');

    const isFileStateGotUpdated = newLatestStateDate > oldLatestStateDate;
    const isNewlyCreatedFile = !oldLatestStateDate;

    if (isFileStateGotUpdated || isNewlyCreatedFile) {
      oldLatestStateDate = newLatestStateDate;
      iterationNumber = 0;
    }

    const documentInProgress = find(
      documentsStatusByFileId,
      filterByDocumentsWithInProgressStatus,
    );

    if (iterationNumber < 5 || documentInProgress) {
      const isNewDocumentNeedToBeDownloaded =
        documentsWithCreatedStatusCount > documentsCreatedCount;

      if (isNewDocumentNeedToBeDownloaded) {
        for (const product of fileProducts) {
          const claimId = get(product, 'claimId');

          yield all([
            put(getDocumentsByClaimId({ claimId })),
            take(GET_DOCUMENTS_BY_CLAIM_ID.END),
          ]);
        }
        documentsCreatedCount = size(
          filter(documentsStatusByFileId, filterByDocumentsWithCreatedStatus),
        );
      }

      yield delay(documentInProgress ? 1000 : 5000);

      yield all([
        put(
          AttachmentsActionCreators.getAllDocumentsStatusByFileId({ fileId }),
        ),
        take(AttachmentsActionTypes.GET_ALL_DOCUMENTS_STATUS_BY_FILE_ID.END),
      ]);

      iterationNumber += 1;
    }
    yield delay(2000);

    documentsStatusByFileId = get(
      yield select(
        AttachmentsSelectors.getDocumentsStatusByFileIdValue,
        fileId,
      ),
      'value',
    );
    documentsWithCreatedStatusCount = size(
      filter(documentsStatusByFileId, filterByDocumentsWithCreatedStatus),
    );
  } while (true);
}

function* submitAttachmentsWorker({ payload }) {
  const attachments = yield select(AttachmentsSelectors.uploadAttachments);

  if (attachments.isEmpty()) {
    payload.completeTransition();
  } else {
    const attachedFiles = attachments.get(payload.fileId);
    const attachedFilesJs = result(attachedFiles, 'toJS');

    const files = Object.keys(attachedFilesJs).map((fileHash) => ({
      fileId: payload.fileId,
      name: get(attachedFilesJs, [fileHash, 'value', 'name']),
      fileAttachmentId: get(attachedFilesJs, [
        fileHash,
        'value',
        'fileAttachmentId',
      ]),
      transitionInfo: {
        from: get(payload, ['from', 'name']),
        to: get(payload, ['to', 'name']),
        moduleId: get(payload.module, 'id'),
        moduleDefinitionId: get(payload.module, 'definitionId'),
        wave: get(payload.module, 'wave'),
      },
      service: CONTROL,
      extension: get(attachedFilesJs, [fileHash, 'value', 'extension']),
      type: get(attachedFilesJs, [fileHash, 'value', 'type']),
    }));

    yield all(
      files.map((file) =>
        put(AttachmentsActionCreators.setFileAttachmentInfo(file)),
      ),
    );
    payload.completeTransition();
  }
}
function* submitAttachmentsWatcher() {
  yield takeEvery(SUBMIT_ATTACHMENTS.BASE, submitAttachmentsWorker);
}

function* downloadTransportLabels({ payload: { value: carrierLabels } }) {
  const carrierLabelsEnabled = filter(
    carrierLabels,
    (carrierLabel) => !carrierLabel.disabled,
  );

  yield all(
    carrierLabelsEnabled.map(
      ({ fileId, moduleDefinitionId, moduleInstanceId, trackingUrl }) =>
        call(function* downloadLabel() {
          yield all([
            put(
              CarriersActionCreators.getShippingLabel({
                fileId,
                moduleDefinitionId,
                moduleInstanceId,
                trackingUrl,
              }),
            ),
            take(CarriersActionTypes.GET_SHIPPING_LABEL.END),
          ]);
        }),
    ),
  );
}

function* loadOrderTickets({ fileId }) {
  yield take(GET_FILE_FULL_SUMMARY.END);

  const orderId = yield select(selectOrderIdByFileId, { fileId });
  const canReadOrderPermission = yield select(
    permissionsViewSelectors.canReadOrdersPermissionSelector,
  );

  if (canReadOrderPermission) {
    yield all([
      put(loadMarketplaceDetailedOrder(orderId)),
      take(LOAD_MARKETPLACE_DETAILED_ORDER.END),
    ]);
    const details = yield select(getDetailedOrderByOrderId, { orderId });
    const storeId = details.purchaseStoreId;

    yield put(storeActionCreators.getStore({ storeId }));
  }

  const ticketReadingPermission = yield select(
    permissionsViewSelectors.ticketReadingPermissionSelector,
  );

  if (ticketReadingPermission) {
    yield put(MarketplaceActionCreators.loadMarketplaceTicketsByOrder(orderId));
  }
}

function* loadClaimInfoWorker() {
  const { payload } = yield take(GET_FILE_FULL_SUMMARY.SUCCEEDED);
  const ownerId = get(payload, ['value', 'ownerId']);
  const claimGroupId = get(payload, [
    'value',
    'claimInfoSummary',
    'claimGroupId',
  ]);

  const ownerAccountPermission = yield select(
    permissionsViewSelectors.ownerAccountPermissionSelector,
  );

  if (ownerAccountPermission) {
    yield put(loadOwnerInfo(ownerId));
  }

  yield all([
    put(loadFullClaimsByGroupId({ claimGroupId })),
    take(LOAD_FULL_CLAIMS_BY_GROUP_ID.END),
  ]);
}

function* getFileQuote({ fileId }) {
  const canManageQuotes = yield select(
    permissionsViewSelectors.canManageQuotesPermissionSelector,
  );

  if (!canManageQuotes) {
    return;
  }

  const [, { meta }] = yield all([
    put(QuoteActionCreators.getFileQuotes(fileId)),
    take(QuoteActionTypes.GET_FILE_QUOTES.END),
  ]);

  const quote = meta;

  if (quote) {
    yield put(QuoteActionCreators.getQuoteById(quote.id));
  }
}

function* loadFileInfoWorker({ payload }) {
  const fileId = payload?.fileId;

  yield reloadFileInfo({ fileId });

  yield put(FileInfoActionTypes.LOAD_FILE_INFO.end());
}
function* loadFileInfoWatcher() {
  yield takeEvery(FileInfoActionTypes.LOAD_FILE_INFO.BASE, loadFileInfoWorker);
}

function* watchers({ fileId }) {
  yield all([
    takeEvery(FOLDERS_FORCE_CLOSE_FILE.BASE, forceCloseFileWorker),
    takeEvery(
      CarriersActionTypes.LOAD_LABELS.SUCCEEDED,
      downloadTransportLabels,
    ),
    submitAttachmentsWatcher(),
    takeEvery(SPLIT_FILE_REQUEST.BASE, splitFileAsyncWorker),
    takeEvery(EXPLODE_FILE_REQUEST, explodeFileWorker),
    takeEvery(
      WorkflowActionTypes.SET_SYNC_USER_ACTION.BASE,
      function* setUpdatePending() {
        yield put(WorkflowActions.onWorkflowStatePending({ fileId }));
      },
    ),
    loadFileInfoWatcher(),
  ]);
}

function* loadModelsFromFile({ fileId }) {
  yield all([
    take(
      matches({
        type: GET_FILE_FULL_SUMMARY.SUCCEEDED,
        meta: {
          fileId,
        },
      }),
    ),
  ]);

  const fileProducts = get(
    yield select(fileSummarySelectors.getFileSummaryValue, { fileId }),
    ['value', 'fileProducts'],
    [],
  );

  const modelIds = uniq(fileProducts.map((fileProduct) => fileProduct.modelId));

  if (modelIds.length >= 1) {
    for (const modelId of modelIds) {
      const wasModelLoaded = yield select(wasEveryActionLoadedSelector, [
        { action: GET_MODEL_BY_ID, indexer: modelId },
      ]);

      if (!wasModelLoaded) {
        yield all([put(getModelById(modelId)), take(GET_MODEL_BY_ID.END)]);
      }
    }
  }
}

function* workers({ fileId }) {
  yield all([
    call(loadAndReturnLatestFileState, { fileId }),
    call(loadAndReturnFileActions, { fileId }),
    call(loadAndReturnFileSummary, { fileId }),
    call(loadModelsFromFile, { fileId }),
    call(loadAndReturnLabels, { fileId }),
    call(loadAttachments, { fileId }),
    call(loadRefundData, { fileId }),
    call(loadOrderTickets, { fileId }),
    call(loadClaimInfoWorker, { fileId }),
    call(getFileQuote, { fileId }),
  ]);
}

export default function* mainSaga(route) {
  const isLoggedIn = yield select(LoginSelectors.isLoggedInSelector);

  if (!isLoggedIn) {
    yield take(LOGIN_SIGNED_IN);
  }

  const path = yield select(pathname);

  const match = matchPath(route, path);

  const fileId = match?.params?.fileId;
  const fileTabId = match?.params?.fileTabId;

  if (!fileTabId || !fileId) {
    return;
  }

  yield put(WorkflowActions.onWorkflowStateUpdateReset(fileId));

  yield all([fork(watchers, { fileId }), call(workers, { fileId })]);

  yield fork(pollDocumentsStatus, { fileId });
  yield fork(reloadFileInfo, { fileId });
}

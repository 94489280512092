import { generatePath } from 'react-router-dom';

import { EMPTY_UUID } from '@savgroup-front-common/constants';
import {
  CheckIcon,
  CloudUploadIcon,
  FileIcon,
  GroupIcon,
  InventoryIcon,
  MessageIcon,
  NotificationIcon,
  OrderIcon,
  QueryStatsIcon,
  RebaseIcon,
  ReceiptIcon,
  RuleIcon,
  SettingsIcon,
  SettingsSuggestIcon,
  SparePartIcon,
  SpeedIcon,
  StockIcon,
  TaskIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import { CloudIcon } from '@savgroup-front-common/core/src/protons/icons/Cloud.icon';
import { HelpCircleIcon } from '@savgroup-front-common/core/src/protons/icons/Information/HelpCircle.icon';
import MenuBookIcon from '@savgroup-front-common/core/src/protons/icons/MenuBook/MenuBook.icon';

import {
  NAV,
  ROUTES,
  SETTINGS_ROUTES_WITH_PARAMS,
  SPARE_PART_ROUTES,
  SPARE_PARTS_SETTINGS_ROUTES,
  TABS_BILLING,
  TABS_SETTINGS,
  TABS_SPARE_PARTS,
} from '../../../constants/routing';
import {
  buildAlertsUrl,
  buildBillingUrl,
  buildBulkChangesUrl,
  buildBusinessIntelligenceUrl,
  buildConfigurationConsoleUrl,
  buildFilesUrl,
  buildHelpUrl,
  buildHistoricFilesUrl,
  buildMessagesUrl,
  buildOrdersUrl,
  buildSettingsUrl,
  buildSparePartUrl,
  buildTodosUrl,
} from '../../../helpers/route';
import { TABS_CONFIGURATION_CONSOLE } from '../../pages/ConfigurationConsole/ConfigurationConsoleContent.constants';

import messages from './messages';
import { MENU_NAMES } from './SidBarMenu.types';

export const MENU_CONF = {
  [MENU_NAMES.ALERTS]: {
    path: ROUTES.ALERTS,
    url: buildAlertsUrl(),
    icon: NotificationIcon,
    message: messages.alerts,
  },
  [MENU_NAMES.TODOS]: {
    path: ROUTES.TODOS,
    url: buildTodosUrl(),
    icon: TaskIcon,
    message: messages.todos,
  },
  [MENU_NAMES.FILES]: {
    path: ROUTES.FILES_NEW_UI,
    url: buildFilesUrl(),
    icon: FileIcon,
    message: messages.files,
  },
  [MENU_NAMES.FILES_HISTORIC]: {
    path: ROUTES.FILES_HISTORIC,
    url: buildHistoricFilesUrl(),
    icon: InventoryIcon,
    message: messages.filesHistoric,
  },
  [MENU_NAMES.BULK_CHANGES]: {
    path: ROUTES.BULK_CHANGES,
    url: buildBulkChangesUrl(),
    icon: RebaseIcon,
    message: messages.bulkChanges,
  },
  [MENU_NAMES.MESSAGES]: {
    path: ROUTES.MESSAGES,
    url: buildMessagesUrl(),
    icon: MessageIcon,
    message: messages.messages,
  },
  [MENU_NAMES.ORDERS]: {
    path: ROUTES.ORDERS,
    url: buildOrdersUrl(),
    icon: OrderIcon,
    message: messages.orders,
  },
  [MENU_NAMES.SPARE_PARTS]: {
    path: ROUTES.SPARE_PARTS,
    url: buildSparePartUrl(),
    icon: SparePartIcon,
    message: messages.spareParts,
  },
  [MENU_NAMES.SPARE_PART_REQUESTS_TO_VALIDATE]: {
    path: SPARE_PART_ROUTES.REQUESTS_TO_VALIDATE,
    url: buildSparePartUrl({
      sparePartMenuId: TABS_SPARE_PARTS.REQUESTS_TO_VALIDATE,
    }),
    icon: CheckIcon,
    message: messages.sparePartRequestsToValidate,
  },
  [MENU_NAMES.SPARE_PART_REQUESTS]: {
    path: SPARE_PART_ROUTES.REQUESTS,
    url: buildSparePartUrl({
      sparePartMenuId: TABS_SPARE_PARTS.REQUESTS,
    }),
    icon: OrderIcon,
    message: messages.sparePartRequests,
  },
  [MENU_NAMES.CREATE_SPARE_PART_REQUEST]: {
    path: SPARE_PART_ROUTES.CREATE_REQUEST,
    url: buildSparePartUrl({
      sparePartMenuId: TABS_SPARE_PARTS.CREATE_REQUEST,
    }),
    icon: SparePartIcon,
    message: messages.createSparePartRequest,
  },
  [MENU_NAMES.SPARE_PART_STOCK]: {
    path: SPARE_PART_ROUTES.STOCKS,
    url: buildSparePartUrl({ sparePartMenuId: TABS_SPARE_PARTS.STOCKS }),
    icon: StockIcon,
    message: messages.sparePartStock,
  },
  [MENU_NAMES.SETTINGS_SPARE_PART_CATALOG]: {
    path: SPARE_PARTS_SETTINGS_ROUTES.CATALOG,
    url: generatePath(SPARE_PARTS_SETTINGS_ROUTES.CATALOG, {
      sellerId: EMPTY_UUID,
    }),
    icon: MenuBookIcon,
    message: messages.sparePartCatalog,
  },
  [MENU_NAMES.SPARE_PART_INVENTORIES]: {
    path: SPARE_PART_ROUTES.INVENTORIES,
    url: buildSparePartUrl({
      sparePartMenuId: TABS_SPARE_PARTS.INVENTORIES,
    }),
    icon: InventoryIcon,
    message: messages.sparePartInventories,
  },
  [MENU_NAMES.BUSINESS_INTELLIGENCE]: {
    path: ROUTES.BUSINESS_INTELLIGENCE,
    url: buildBusinessIntelligenceUrl(),
    icon: QueryStatsIcon,
    message: messages.businessIntelligence,
  },
  [MENU_NAMES.SETTINGS]: {
    path: ROUTES.SETTINGS,
    url: buildSettingsUrl(),
    icon: SettingsIcon,
    message: messages.settings,
  },
  [MENU_NAMES.SETTINGS_SELLERS]: {
    path: SETTINGS_ROUTES_WITH_PARAMS.SELLERS,
    url: buildSettingsUrl({ settingTabId: TABS_SETTINGS.SELLERS }),
    icon: SettingsIcon,
    message: messages.account,
  },
  [MENU_NAMES.SETTINGS_MASTER]: {
    path: SETTINGS_ROUTES_WITH_PARAMS.MASTER,
    url: buildSettingsUrl({ settingTabId: TABS_SETTINGS.MASTER }),
    icon: SettingsIcon,
    message: messages.master,
  },
  [MENU_NAMES.SETTINGS_USERS]: {
    path: SETTINGS_ROUTES_WITH_PARAMS.USERS,
    url: buildSettingsUrl({ settingTabId: TABS_SETTINGS.USERS }),
    icon: GroupIcon,
    message: messages.users,
  },
  [MENU_NAMES.SETTINGS_TESTS]: {
    path: SETTINGS_ROUTES_WITH_PARAMS.TESTS,
    url: buildSettingsUrl({ settingTabId: TABS_SETTINGS.TESTS }),
    icon: SpeedIcon,
    message: messages.tests,
  },
  [MENU_NAMES.SETTINGS_IMPORTS]: {
    path: SETTINGS_ROUTES_WITH_PARAMS.IMPORTS,
    url: buildSettingsUrl({ settingTabId: TABS_SETTINGS.IMPORTS }),
    icon: CloudIcon,
    message: messages.imports,
  },
  [MENU_NAMES.SETTINGS_SPARE_PARTS]: {
    path: SETTINGS_ROUTES_WITH_PARAMS.SPARE_PARTS,
    url: buildSettingsUrl({ settingTabId: TABS_SETTINGS.SPARE_PARTS }),
    icon: SparePartIcon,
    message: messages.sparePartsSettings,
  },
  [MENU_NAMES.HELP]: {
    path: ROUTES.HELP,
    url: buildHelpUrl(),
    icon: HelpCircleIcon,
    message: messages.help,
  },
  [MENU_NAMES.CONFIGURATION_CONSOLE]: {
    path: ROUTES.CONFIGURATION_CONSOLE,
    url: `/${NAV.CONFIGURATION_CONSOLE}`,
    icon: SettingsSuggestIcon,
    message: messages.configurationConsole,
  },
  [MENU_NAMES.CONFIGURATION_CONSOLE_RULES]: {
    path: ROUTES.CONFIGURATION_CONSOLE,
    url: buildConfigurationConsoleUrl({
      consoleTabId: TABS_CONFIGURATION_CONSOLE.FOLLOW_UP,
    }),
    icon: RuleIcon,
    message: messages.configurationConsoleRules,
  },
  [MENU_NAMES.CONFIGURATION_CONSOLE_OPTIONS]: {
    path: ROUTES.CONFIGURATION_CONSOLE,
    url: buildConfigurationConsoleUrl({
      consoleTabId: TABS_CONFIGURATION_CONSOLE.OPTIONS,
    }),
    icon: SettingsIcon,
    message: messages.options,
  },
  [MENU_NAMES.CONFIGURATION_CONSOLE_IMPORT]: {
    path: ROUTES.CONFIGURATION_CONSOLE,
    url: buildConfigurationConsoleUrl({
      consoleTabId: TABS_CONFIGURATION_CONSOLE.IMPORT,
    }),
    icon: CloudUploadIcon,
    message: messages.configurationConsoleImport,
  },
  [MENU_NAMES.BILLING]: {
    path: ROUTES.BILLING,
    url: `/${NAV.BILLING}`,
    icon: ReceiptIcon,
    message: messages.billing,
  },
  [MENU_NAMES.BILLING_TRACKING]: {
    path: ROUTES.BILLING,
    url: buildBillingUrl({ billingTabId: TABS_BILLING.BILLING_TRACKING }),
    icon: ReceiptIcon,
    message: messages.billingTracking,
  },
  [MENU_NAMES.BILLING_CUSTOMERS_LIST]: {
    path: ROUTES.BILLING,
    url: buildBillingUrl({ billingTabId: TABS_BILLING.BILLING_CUSTOMERS_LIST }),
    icon: ReceiptIcon,
    message: messages.billingCustomersList,
  },
  [MENU_NAMES.BILLING_PARAMETERS]: {
    path: ROUTES.BILLING,
    url: buildBillingUrl({ billingTabId: TABS_BILLING.BILLING_PARAMETERS }),
    icon: SettingsIcon,
    message: messages.billingParameters,
  },
};

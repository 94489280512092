import { matchPath } from 'react-router-dom';

import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { CONFIGURATION_CONSOLE_ROUTES } from 'control/constants/routing';
import { RULE_SETTINGS_ROUTES } from 'control/view/pages/ConfigurationConsole/RulePages/RulePages.constants';

export enum SECTION_STYLE {
  CONSOLE_VIEW_MODE = 'CONSOLE_VIEW_MODE',
  CONSOLE_EDIT_MODE = 'CONSOLE_EDIT_MODE',
  NEW_BO_MODE = 'NEW_BO_MODE',
  CLASSIC_MODE = 'CLASSIC_MODE',
}

export const useGetSectionStyle = (): SECTION_STYLE | undefined => {
  const pathname = window.location?.pathname;
  const isNewUiEnabled = useIsNewUiEnabled();

  if (
    matchPath(RULE_SETTINGS_ROUTES.SHOW_SETTINGS, pathname) ||
    matchPath(CONFIGURATION_CONSOLE_ROUTES.ENTITIES, pathname)
  ) {
    return SECTION_STYLE.CONSOLE_VIEW_MODE;
  }

  if (
    matchPath(RULE_SETTINGS_ROUTES.NEW_RULE, pathname) ||
    matchPath(RULE_SETTINGS_ROUTES.MODIFY_RULE, pathname)
  ) {
    return SECTION_STYLE.CONSOLE_EDIT_MODE;
  }

  if (isNewUiEnabled) {
    return SECTION_STYLE.NEW_BO_MODE;
  }

  return SECTION_STYLE.CLASSIC_MODE;
};

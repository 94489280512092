import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { searchAlertsList } from 'control/domains/alertsList/actionCreators';
import {
  selectAlertsListErrors,
  selectAlertsListIsLoading,
  selectAlertsListWasLoaded,
} from 'control/domains/alertsList/selectors';
import { useControlTypedSelector } from 'control/hooks';

import { ROUTES } from '../../../../constants/routing';

const useLoadAlerts = (): void => {
  const dispatch = useDispatch();
  const isAlertRoute = useMatch(ROUTES.ALERTS);

  const wasLoaded = useControlTypedSelector((state) =>
    selectAlertsListWasLoaded(state, { page: 1 }),
  );
  const isLoading = useControlTypedSelector((state) =>
    selectAlertsListIsLoading(state, { page: 1 }),
  );
  const errors = useControlTypedSelector((state) =>
    selectAlertsListErrors(state, { page: 1 }),
  );

  useEffect(() => {
    if (!wasLoaded && !isLoading && !isAlertRoute && errors.length === 0) {
      dispatch(searchAlertsList());
    }
  }, [dispatch, errors.length, isAlertRoute, isLoading, wasLoaded]);
};

export default useLoadAlerts;

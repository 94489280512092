import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { EVENT_KEY } from '@savgroup-front-common/constants/src/shared';
import { setToLocalStorage } from '@savgroup-front-common/core/src/helpers';
import {
  useDebounce,
  useMouseHasMoved,
} from '@savgroup-front-common/core/src/hooks';
import { LOCAL_STORAGE_KEYS, RESULT_TYPE } from '@savgroup-front-common/types';

import { searchResults } from '../helpers';
import { searchReducer, searchReducerInit } from '../Search.reducers';
import {
  EmptyResponse,
  RESULT_DISPLAY_TYPE,
  SEARCH_ACTION_TYPES,
  SearchApiResult,
} from '../Search.types';

import useFocusOnShortCut from './useFocusOnShortCut';
import useSearchPermissions from './useSearchPermissions';
import useSearchScroll from './useSearchScroll';

const useSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [isSteady, watchMouse] = useMouseHasMoved();

  const searchPermissions = useSearchPermissions();

  const [
    {
      indexFocus,
      query,
      resultsAreOpened,
      focusedResultType,
      isLoading,
      page,
      results,
      showNoResult,
      showNoFilter,
      enabledResultTypes,
      containerHeight,
      listHeight,
    },
    dispatch,
  ] = useReducer(
    searchReducer,
    { defaultResultType: searchPermissions },
    searchReducerInit,
  );

  useEffect(() => {
    setToLocalStorage({
      key: LOCAL_STORAGE_KEYS.SEARCH_RESULT_TYPES,
      value: enabledResultTypes,
    });
  }, [enabledResultTypes]);

  const handleClose = useCallback(() => {
    dispatch({ type: SEARCH_ACTION_TYPES.CLOSE_RESULTS });
  }, []);

  const { containerRef } = useSearchScroll({ results, indexFocus });

  const onResults = useCallback(
    (
      response:
        | {
            order: SearchApiResult;
            file: SearchApiResult;
            sparePartRequest: SearchApiResult;
            stockItem: SearchApiResult;
          }
        | EmptyResponse,
    ) => {
      if (page === 1) {
        dispatch({
          type: SEARCH_ACTION_TYPES.SET_RESULTS,
          payload: { response },
        });
      } else {
        dispatch({
          type: SEARCH_ACTION_TYPES.SET_MORE_RESULTS,
          payload: { response },
        });
      }
    },
    [page],
  );

  const handleSearch = useCallback(async () => {
    if (query.length > 0) {
      try {
        dispatch({
          type: SEARCH_ACTION_TYPES.SET_LOADING,
          payload: { isLoading: true },
        });
        const response = await searchResults({
          query: query.trim().toUpperCase(),
          page,
          focusedResultType,
          enabledResultTypes,
          searchPermissions,
        });

        onResults(response);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_) {
        dispatch({
          type: SEARCH_ACTION_TYPES.SET_LOADING,
          payload: { isLoading: false },
        });
      }
    }
  }, [
    enabledResultTypes,
    query,
    page,
    focusedResultType,
    searchPermissions,
    onResults,
  ]);

  useDebounce(handleSearch, 600);

  const onUp = useCallback(() => {
    dispatch({ type: SEARCH_ACTION_TYPES.GO_UP });
    watchMouse();
  }, [watchMouse]);

  const onBur = useCallback(() => {
    dispatch({
      type: SEARCH_ACTION_TYPES.ON_BLUR,
    });
  }, []);
  const handleFocusInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useFocusOnShortCut(handleFocusInput);
  const onShowMore = useCallback(
    (type: RESULT_TYPE) => {
      dispatch({
        type: SEARCH_ACTION_TYPES.REQUEST_MORE_RESULTS,
        payload: { type },
      });
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [inputRef],
  );

  const onDown = useCallback(() => {
    dispatch({ type: SEARCH_ACTION_TYPES.GO_DOWN });
    watchMouse();
  }, [watchMouse]);

  const onEnter = useCallback(() => {
    const focused = results?.[indexFocus || 0];

    if (!(results.length > 0 && query.length > 0 && resultsAreOpened)) {
      return;
    }

    if (focused?.displayType === RESULT_DISPLAY_TYPE.RESULT) {
      handleClose();
      navigate(focused.value.url);
    }
    if (focused?.displayType === RESULT_DISPLAY_TYPE.SHOW_MORE) {
      onShowMore(focused.resultType);
    }
  }, [
    handleClose,
    navigate,
    indexFocus,
    query.length,
    results,
    onShowMore,
    resultsAreOpened,
  ]);
  const onEscape = useCallback(() => {
    onBur();
    if (inputRef.current) {
      inputRef.current.blur();
    }

    handleClose();
  }, [handleClose, inputRef, onBur]);

  return {
    results,
    enabledResultTypes,
    isLoading,
    containerRef,
    inputRef,
    isSteady,
    query,
    indexFocus,
    resultsAreOpened,
    handleClose,
    showNoResult,
    showNoFilter,
    focusedResultType,
    containerHeight,
    listHeight,
    onSearch: useCallback((event: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: SEARCH_ACTION_TYPES.UPDATE_QUERY,
        payload: { query: event.target.value },
      });
    }, []),
    onFocus: useCallback((event: FocusEvent<HTMLInputElement>) => {
      event.target.select();
      dispatch({ type: SEARCH_ACTION_TYPES.ON_FOCUS });
    }, []),
    onShowMore,
    onToggleSearchType: useCallback(
      (type: RESULT_TYPE) => {
        dispatch({
          type: SEARCH_ACTION_TYPES.TOGGLE_SEARCH_TYPE,
          payload: { type },
        });
        if (inputRef.current) {
          inputRef.current.focus();
        }
      },
      [inputRef],
    ),
    onClear: useCallback(() => {
      dispatch({
        type: SEARCH_ACTION_TYPES.UPDATE_QUERY,
        payload: { query: '' },
      });
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [inputRef]),
    onKeyDown: useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
          case EVENT_KEY.ARROW_UP: {
            event.preventDefault();
            onUp();
            break;
          }
          case EVENT_KEY.ARROW_DOWN: {
            event.preventDefault();
            onDown();
            break;
          }
          case EVENT_KEY.ENTER: {
            event.preventDefault();
            onEnter();
            break;
          }
          case EVENT_KEY.ESCAPE: {
            event.preventDefault();
            onEscape();
            break;
          }
          case EVENT_KEY.TAB: {
            onBur();
            break;
          }
          default:
            break;
        }
      },
      [onEscape, onDown, onEnter, onBur, onUp],
    ),
  };
};

export default useSearch;

import { fromJS } from 'immutable';
import get from 'lodash/get';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';

import { Selectors as CarriersSelectors } from '@savgroup-front-common/core/src/domains/carriers';
import { Selectors as ClaimsSelectors } from '@savgroup-front-common/core/src/domains/claims';
import { isActionLoadingSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import { pathname } from '@savgroup-front-common/core/src/domains/selectors/router';
import { Selectors as WorkflowSelectors } from '@savgroup-front-common/core/src/domains/workflow';
import { GET_FILE_ACTIONS } from '@savgroup-front-common/core/src/domains/workflow/actionTypes';
import { fileSummarySelectors } from 'control/domains/workflow/fileSummary';

import { fileStates } from '../workflow/selectors';

export const currentFileIdSelector = createSelector(
  [pathname],
  (path: string) => {
    const match = matchPath(
      `/:fileBase/:fileId/:fileTabId?/:fileSubTabId?`,
      path,
    );

    return match?.params?.fileId;
  },
);
export const currentFileTabIdSelector = createSelector(
  [pathname],
  (path: string) => {
    const match = matchPath(
      `/:fileBase/:fileId/:fileTabId?/:fileSubTabId?`,
      path,
    );

    return match?.params?.fileTabId;
  },
);

export const currentFileSelector = createSelector(
  [currentFileIdSelector, fileSummarySelectors.fileSummary],
  (fileId, fileSummary) => {
    return fileId ? fileSummary?.[fileId]?.value : undefined;
  },
);

export const getFileByFileId = createSelector(
  [
    fileSummarySelectors.fileSummary,
    (_: any, { fileId }: { fileId?: string }) => fileId,
  ],
  (fileSummary, fileId) => (fileId ? fileSummary?.[fileId]?.value : undefined),
);

export const currentFileHandlingSelector = createSelector(
  [currentFileIdSelector, WorkflowSelectors.handling],
  (fileId, handling) => handling.getIn([fileId]) || fromJS({}),
);

export const selectCurrentFileHandlingValue = createSelector(
  [currentFileHandlingSelector],
  (state) => state.get('value') || null,
);

export const currentActionsSelector = createSelector(
  [currentFileIdSelector, WorkflowSelectors.selectFileActionsState],
  (fileId, userActions) => userActions.getIn([fileId, 'value']),
);

export const currentActionsIsLoadingSelector = (state: any) => {
  return isActionLoadingSelector(state, GET_FILE_ACTIONS);
};

export const currentFileStatesSelector = createSelector(
  [currentFileIdSelector, fileStates],
  (fileId, fileStates) => (fileId ? fileStates[fileId]?.value : undefined),
);
export const selectFileStatesById = createSelector(
  [fileStates, (_: any, { fileId }: { fileId: string }) => fileId],
  (state, fileId) => state[fileId]?.value,
);

export const currentLabelsSelector = createSelector(
  [currentFileIdSelector, CarriersSelectors.labels],
  (fileId, labels) => labels.getIn([fileId, 'value'], []),
);
export const selectLabelsByFileId = createSelector(
  [
    CarriersSelectors.labels,
    (_: any, { fileId }: { fileId: string }) => fileId,
  ],
  (labels, fileId) => labels.getIn([fileId, 'value'], []),
);

export const currentOwnerIdSelector = createSelector(
  [currentFileSelector],
  (file) => get(file, 'ownerId'),
);

export const currentClaimIdSelector = createSelector(
  [currentFileSelector],
  (file) => get(file, ['claimInfoSummary', 'claimId']),
);

export const currentClaimSelector = createSelector(
  [currentClaimIdSelector, ClaimsSelectors.fullClaims],
  (claimId, fullClaims) => fullClaims.getIn([claimId, 'value']),
);

export const currentExplodeFileState = createSelector(
  [currentFileIdSelector, WorkflowSelectors.explodeFileStateSelector],
  (fileId, explodeFileState) => explodeFileState.getIn([fileId]),
);

export const selectFileClaimIds = createSelector([getFileByFileId], (file) =>
  (file?.fileProducts || []).map((product) => product.claimId),
);

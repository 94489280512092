import styled from 'styled-components';

export const $NoItemSelectedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  padding: 1rem 0.5rem;
  position: relative;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  flex-flow: column;
  font-size: ${({ theme }) => theme.fonts.size.large};
  line-height: 1.2;
  svg {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.paragraphTextColor};
  }
`;

export const $NoItemSelectedText = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;

import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $AvailablePlanContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(3, 1fr);
  gap: 1rem;

  margin-bottom: ${rem(8)};

  & > * {
    overflow: hidden;
  }
`;

export const $ChoosePricingHeader = styled.header`
  margin-bottom: 2rem;
`;

export const $ChoosePricingContainer = styled.div`
  padding: ${rem(30)} ${rem(120)};
`;

export const $EmptyColumn = styled.div``;

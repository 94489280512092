import React, { FC } from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import {
  formatReverseMoneyToString,
  formatStripeReverseMoneyNumberAmount,
} from '@savgroup-front-common/core/src/formatters/intl';
import { SafeFormattedMessageWithId } from '@savgroup-front-common/core/src/formatters/SafeFormattedMessageWithId';

import { PlanDto, PricingType } from '../../ChoosePlan.types';

import messages from './messages';
import { $Price, $PriceLine } from './PlanPrice.styles';

interface PlanPriceProps {
  plan: PlanDto;
}

export const PlanPrice: FC<PlanPriceProps> = ({ plan }) => {
  switch (plan.pricingType) {
    case PricingType.Custom:
      return (
        <$Price>
          <SafeFormattedMessageWithoutSpread message={messages.customPrice} />
        </$Price>
      );
    case PricingType.OnUsage:
      return (
        <$PriceLine>
          <SafeFormattedMessageWithoutSpread
            message={messages.onUsagePrice}
            values={{
              price: (
                <$Price>
                  {formatStripeReverseMoneyNumberAmount(
                    plan.usagePrice.perFile,
                  )}
                </$Price>
              ),
            }}
          />
        </$PriceLine>
      );
    case PricingType.Packaged:
      return (
        <$PriceLine>
          <SafeFormattedMessageWithoutSpread
            message={messages.packagedPrice}
            values={{
              price: (
                <$Price>
                  {formatReverseMoneyToString(plan.cyclePrice, {
                    shouldStripIfInteger: true,
                  })}
                </$Price>
              ),
              billingCycle: (
                <SafeFormattedMessageWithId
                  id={`pricing.billingCycle.${plan.billingCycle}`}
                />
              ),
            }}
          />
        </$PriceLine>
      );
    default:
      return <></>;
  }
};

import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Routes as ReactRouterRoutes,
  Route,
  useLocation,
} from 'react-router-dom';
import { useMedia } from 'react-use';

import {
  FEATURE_FLAGS_FOR_SELLER,
  media,
  ROLES,
} from '@savgroup-front-common/constants';
import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import { locationChange } from '@savgroup-front-common/core/src/domains/router/actionCreators';
import { retryImportChunk } from '@savgroup-front-common/core/src/helpers/retryImportChunk';
import { useHasRole } from '@savgroup-front-common/core/src/hooks';
import { useAppStatuses } from '@savgroup-front-common/core/src/molecules/AppStatuses';
import MaintenanceScreen from '@savgroup-front-common/core/src/molecules/AppStatuses/MaintenanceScreen';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { AppErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { PRICING_PLAN } from '@savgroup-front-common/types';

import { BASES } from '../../../constants/routing';
import { useGetFeatureFlagsQuery } from '../../../hooks';
import { FloatingWindowVideoCall } from '../../components/FileInfo/FileTabs/VideoCallLauncherTab/FloatingWindowVideoCall/FloatingWindowVideoCall';
import { VideoCallLauncherContextProvider } from '../../components/FileInfo/VideoCallLauncher/VideoCallLauncher.context';
import Header from '../../components/Header';
import SideBarMenu from '../../molecules/SideBarMenu';
import { useAppContext } from '../App.context';
import { ModalsPortal } from '../ModalsPortal';
import Routes from '../routes/Routes';

import { useMinimalLayout, useMobileMenu } from './hooks';
import { SECTION_STYLE, useGetSectionStyle } from './hooks/useGetSectionStyle';
import { $LayoutContainer, $LayoutMain, $Section } from './Layout.styles';
import { WeRecommendUsingDesktop } from './WeRecomandUsingDesktopModal/WeRecommendUsingDesktop';

const IframeRoutes = React.lazy(() =>
  retryImportChunk(() => import('../routes/IframeRoutes/IframeRoutes')),
);

const Layout: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isMenuOpen, onMenuToggle } = useMobileMenu();
  const isMinimalLayoutEnabled = useMinimalLayout();
  const { isUnderMaintenance } = useAppStatuses();
  const sectionStyleMode = useGetSectionStyle();
  const { selectedSeller } = useAppContext();

  const isMobileView = useMedia(media.maxWidth.xs);

  const canSwitchToNewBo = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.SWITCH_NEW_BO,
  });
  const { isNewUiEnabled, onToggleNewBoEnabled } = useNewUiContext();
  const isDemoAdmin = useHasRole(ROLES.DEMO_ADMINISTRATOR);

  const isBasicPricingPlan =
    selectedSeller?.pricingPlan === PRICING_PLAN.BASIC ||
    selectedSeller?.pricingPlan === PRICING_PLAN.NONE;

  const shouldSwitch =
    (!canSwitchToNewBo &&
      isNewUiEnabled &&
      !isDemoAdmin &&
      !isBasicPricingPlan) ||
    (!canSwitchToNewBo &&
      !isNewUiEnabled &&
      (isDemoAdmin || isBasicPricingPlan));

  useEffect(() => {
    dispatch(
      locationChange({
        location,
      } as any),
    );
  }, [dispatch, location]);

  useEffect(() => {
    if (shouldSwitch) {
      onToggleNewBoEnabled();
    }
  }, [onToggleNewBoEnabled, shouldSwitch, isNewUiEnabled]);

  if (isUnderMaintenance) {
    return <MaintenanceScreen />;
  }

  if (shouldSwitch) {
    return <BaseLoader />;
  }

  if (isMinimalLayoutEnabled) {
    return (
      <AppErrorBoundary>
        <ReactRouterRoutes>
          <Route path={`${BASES.IFRAME}/*`} element={<IframeRoutes />} />
        </ReactRouterRoutes>
      </AppErrorBoundary>
    );
  }

  return (
    <AppErrorBoundary>
      <VideoCallLauncherContextProvider>
        <FloatingWindowVideoCall />

        {isNewUiEnabled && isMobileView && <WeRecommendUsingDesktop />}

        <$LayoutContainer>
          <Header onLogoClick={onMenuToggle} isMenuOpen={isMenuOpen} />

          <$LayoutMain $isNewUiEnabled={isNewUiEnabled}>
            {sectionStyleMode !== SECTION_STYLE.NEW_BO_MODE && (
              <SideBarMenu isOpen={isMenuOpen} onToggleMenu={onMenuToggle} />
            )}

            <$Section $sectionStyleMode={sectionStyleMode}>
              <Routes />
            </$Section>
          </$LayoutMain>
          <ModalsPortal />
        </$LayoutContainer>
      </VideoCallLauncherContextProvider>
    </AppErrorBoundary>
  );
};

export default React.memo(Layout);

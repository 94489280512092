import React, { FC } from 'react';

import { Z_INDEX_MENU } from '@savgroup-front-common/constants/src/shared';
import { BackgroundDimmer } from '@savgroup-front-common/core/src/atoms/BackgroundDimmer';

import useGetMenuItems from './hooks/useGetMenuItems';
import { $MenuContainer, $MenuItem, $MenuList } from './Menu.styles';
import { MenuExternalLink } from './MenuExternalLink';
import { MenuListItem } from './MenuListItem/MenuListItem';

const Menu: FC = () => {
  const { menus } = useGetMenuItems();

  return (
    <>
      <BackgroundDimmer zIndex={Z_INDEX_MENU} />
      <$MenuContainer>
        <$MenuList>
          {menus.map((menu) => {
            if (menu.isExternal) {
              return (
                <$MenuItem
                  key={menu.to}
                  $hideCountOnHover={menu.count === 0 && menu.subCount > 0}
                  role="presentation"
                  data-testid={`sidebar_menucontainer_${menu.dataTestId}`}
                >
                  <MenuExternalLink message={menu.message} />
                </$MenuItem>
              );
            }

            return <MenuListItem key={menu.to} menu={menu} />;
          })}
        </$MenuList>
      </$MenuContainer>
    </>
  );
};

export default React.memo(Menu);

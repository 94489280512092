import { defineMessages } from 'react-intl';

export default defineMessages({
  logout: {
    id: 'view.header.logout',
    defaultMessage: 'Log out',
  },
  en: {
    id: 'view.changeLanguage.en',
    defaultMessage: 'English',
  },
  fr: {
    id: 'view.changeLanguage.fr',
    defaultMessage: 'Français',
  },
  es: {
    id: 'view.changeLanguage.es',
    defaultMessage: 'Español',
  },
  de: {
    id: 'view.changeLanguage.de',
    defaultMessage: 'Deutsch',
  },
  it: {
    id: 'view.changeLanguage.it',
    defaultMessage: 'Italian',
  },
  pt: {
    id: 'view.changeLanguage.pt',
    defaultMessage: 'Portuguese',
  },
  nl: {
    id: 'view.changeLanguage.nl',
    defaultMessage: 'Dutch',
  },
  languageLabel: {
    id: 'view.header.languageLabel',
    defaultMessage: 'Language',
  },
  darkmode: {
    id: 'view.header.darkmode',
    defaultMessage: 'Dark mode 🌙',
  },
  lightmode: {
    id: 'view.header.lightmode',
    defaultMessage: 'Light mode ☀️',
  },
  shortcuts: {
    id: 'view.header.shortcuts',
    defaultMessage: 'Shortcuts',
  },
  appStatus: {
    id: 'view.header.appHealthStatus',
    defaultMessage: 'Application health statuses',
  },
  operational: {
    id: 'view.header.appStatus.operational',
    defaultMessage: 'Operational',
  },
  degradedPerformance: {
    id: 'view.header.appStatus.degradedPerformance',
    defaultMessage: 'Degraded performance',
  },
  partialOutage: {
    id: 'view.header.appStatus.partialOutage',
    defaultMessage: 'Partial outage',
  },
  underMaintenance: {
    id: 'view.header.appStatus.underMaintenance',
    defaultMessage: 'Under maintenance',
  },
  nextScheduledMaintenance: {
    id: 'view.header.appStatus.nextScheduledMaintenance',
    defaultMessage: 'Next maintenance: {time}',
  },
  majorOutage: {
    id: 'view.header.appStatus.majorOutage',
    defaultMessage: 'Major outage',
  },
  loading: {
    id: 'view.header.appStatus.loading',
    defaultMessage: 'Status loading',
  },
  featureFlags: {
    id: 'view.header.featureFlags',
    defaultMessage: 'Feature flags',
  },
  stores: {
    id: 'view.header.stores',
    defaultMessage:
      '{count, plural, =0 {Store: <bold>{name}</bold>} =1 {Store: <bold>{name}</bold> and <tooltip>another store</tooltip>} other {Stores: <bold>{name}</bold> and <tooltip><bold>#</bold> other stores</tooltip>}}',
  },
  roles: {
    id: 'view.header.roles',
    defaultMessage:
      '{count, plural, =0 {Role: <bold>{name}</bold>} =1 {Roles: <bold>{name}</bold> and <tooltip>another role</tooltip>} other {Roles: <bold>{name}</bold> and <tooltip><bold>#</bold> other roles</tooltip>}}',
  },
  profiles: {
    id: 'view.header.profiles',
    defaultMessage:
      '{count, plural, =0 {Profile: <bold>{name}</bold>} =1 {Profiles: <bold>{name}</bold> and <tooltip>another profile</tooltip>} other {Profiles: <bold>{name}</bold> and <tooltip><bold>#</bold> other profiles</tooltip>}}',
  },

  selectSeller: {
    id: 'view.header.selectSeller',
    defaultMessage: 'Select seller',
  },
  seller: {
    id: 'view.header.seller',
    defaultMessage: 'Seller: <bold>{name}</bold>',
  },
});

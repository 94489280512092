import { darken, getValueAndUnit } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import {
  DefaultStyledProps,
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { getCustomComponentProperty, rem } from '../../helpers';

interface CardProps extends DefaultStyledProps {
  $withoutMarginTop: boolean;
  $withoutMarginBottom: boolean;
  $withoutPaddingTop: boolean;
  $withoutPaddingBottom: boolean;
  $withBorder: boolean;
  $isOpen: boolean;
  $isVerticalyFluid: boolean;
  $minimal: boolean;
  $colored: boolean;
  elevation?: number;
  $isFluid: boolean;
  $isNewUi?: boolean;
}
interface CardSeparatorProps extends DefaultStyledProps {
  $withoutPaddingTop: boolean;
  $withoutPaddingBottom: boolean;
  $withoutPadding: boolean;
}

const getPadding = ({ $minimal, theme }: CardProps) =>
  $minimal
    ? '1rem'
    : (getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.CARD,
        property: SUPPORTED_PROPERTIES.PADDING,
        fallback: rem(32),
      }) as string);

const getPaddingValues = (props: CardProps) =>
  getValueAndUnit(getPadding(props));

const getDividedPadding = (props: CardProps) => {
  const [value, unit] = getPaddingValues(props);

  return `${value / 2}${unit}`;
};
const getNormalPadding = (props: CardProps) => {
  const [value, unit] = getPaddingValues(props);

  return `${value}${unit}`;
};

const getDoublePadding = (props: CardProps) => {
  const [value, unit] = getPaddingValues(props);

  return `${value * 2}${unit}`;
};

export const $CardSeparator = styled.div<CardSeparatorProps>`
  &::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
  }

  width: 100%;

  padding-top: ${({ $withoutPaddingTop, $withoutPadding }) =>
    $withoutPaddingTop || $withoutPadding ? `0!important` : null};
  padding-bottom: ${({ $withoutPaddingBottom, $withoutPadding }) =>
    $withoutPaddingBottom || $withoutPadding ? `0!important` : null};
`;

export const $Card = styled.div<CardProps>`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
  background: ${({ theme }) => theme.systemColors.defaultBackground};
  position: relative;
  height: ${({ $isVerticalyFluid }) => ($isVerticalyFluid ? '100%' : null)};
  display: ${({ $isOpen }) => ($isOpen ? null : 'none')};

  border-width: ${({ $withBorder }) => ($withBorder ? rem(1) : 0)};
  border-color: ${({ theme, $isNewUi }) =>
    $isNewUi ? theme.newUI.variationColors.light4 : theme.colors.primary};
  border-style: solid;

  & ~ & {
    margin-top: ${({ theme, $withoutMarginTop }) => {
      if ($withoutMarginTop) {
        return '0';
      }

      return getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.CARD,
        property: SUPPORTED_PROPERTIES.MARGIN_TOP,
        fallback: rem(24),
      });
    }};
  }
  margin-bottom: ${({ theme, $withoutMarginBottom }) => {
    if ($withoutMarginBottom) {
      return null;
    }

    return getCustomComponentProperty({
      theme,
      componentName: SUPPORTED_COMPONENTS.CARD,
      property: SUPPORTED_PROPERTIES.MARGIN_BOTTOM,
      fallback: rem(32),
    });
  }};

  border-radius: ${({ theme }) =>
    getCustomComponentProperty({
      theme,
      componentName: SUPPORTED_COMPONENTS.CARD,
      property: SUPPORTED_PROPERTIES.BORDER_RADIUS,
      fallback: theme.borders.radius,
    })};
  box-shadow: ${({ $isNewUi }) =>
    $isNewUi ? undefined : `0 0 15px 0 rgba(0, 0, 0, 0.06)`};

  background-color: ${({ theme, $colored }) => {
    if ($colored) {
      return getCustomComponentProperty({
        theme,
        componentName: SUPPORTED_COMPONENTS.CARD,
        property: SUPPORTED_PROPERTIES.COLORED_BACKGROUND_COLOR,
        fallback: theme.colors.white,
      });
    }

    return theme.systemColors.defaultBackground;
  }};

  @media ${media.maxWidth.sm} {
    padding: ${getDividedPadding};
  }

  padding: ${(props) => {
    const { $isNewUi } = props;

    if ($isNewUi) {
      const padding = rem(24);

      return `${props.$withoutPaddingTop ? 0 : padding} ${padding} ${
        props.$withoutPaddingBottom ? 0 : padding
      }`;
    }

    const padding = getNormalPadding(props);

    return `${props.$withoutPaddingTop ? 0 : padding} ${padding} ${
      props.$withoutPaddingBottom ? 0 : padding
    }`;
  }};

  width: ${({ $isFluid }) => ($isFluid ? '100%' : undefined)};

  ${$CardSeparator} {
    padding: ${getNormalPadding} 0;
    position: relative;
    &::after {
      background: ${({ theme, $colored }) =>
        $colored
          ? darken(0.2, theme.systemColors.defaultBackground)
          : darken(0.1, theme.systemColors.defaultBackground)};
      transform: translateX(-${getNormalPadding});
      width: calc(100% + ${getDoublePadding});

      @media ${media.maxWidth.sm} {
        width: calc(100% + ${getNormalPadding});
        transform: translateX(-${getDividedPadding});
      }
    }
  }
`;

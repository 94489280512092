import React, { FunctionComponent } from 'react';
import { Transition } from 'react-transition-group';

import { Z_INDEX_DOCUMENT_PREVIEW } from '@savgroup-front-common/constants/src/shared';
import { BackgroundDimmerNaked } from '@savgroup-front-common/core/src/atoms/BackgroundDimmer';
import { ExternalPortal } from '@savgroup-front-common/core/src/protons/ExternalPortal/ExternalPortal';

import { $DocumentPreviewAnimation } from './DocumentPreview.styles';
import { DocumentPreviewAttachment } from './DocumentPreview.types';
import DocumentPreviewContent from './DocumentPreviewContent';

const ANIMATION_DURATION = 300;

interface DocumentPreviewArgs {
  isOpen: boolean;
  onClose: () => void;
  attachments: DocumentPreviewAttachment[];
  currentActivePreview?: number;
  isNavigationEnabled?: boolean;
}

const DocumentPreview: FunctionComponent<DocumentPreviewArgs> = ({
  isOpen,
  onClose,
  attachments,
  currentActivePreview,
  isNavigationEnabled = true,
}) => {
  return (
    <Transition in={isOpen} timeout={ANIMATION_DURATION} unmountOnExit>
      {(animationState) => (
        <ExternalPortal>
          <$DocumentPreviewAnimation
            animationState={animationState}
            onClick={onClose}
          >
            <DocumentPreviewContent
              onClose={onClose}
              attachments={attachments}
              currentActivePreview={currentActivePreview}
              isNavigationEnabled={isNavigationEnabled}
            />
          </$DocumentPreviewAnimation>
          <BackgroundDimmerNaked
            onClick={onClose}
            animationState={animationState}
            $zIndex={Z_INDEX_DOCUMENT_PREVIEW - 1}
            animationDuration={ANIMATION_DURATION}
            $opacity={0.9}
          />
        </ExternalPortal>
      )}
    </Transition>
  );
};

export default DocumentPreview;

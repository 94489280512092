import { PERMISSIONS } from '@savgroup-front-common/constants';
import {
  useHasPermission,
  useIsSuperAdmin,
} from '@savgroup-front-common/core/src/hooks';

const useHasSellerThemePermission = (): boolean => {
  const hasWriteThemePermission = useHasPermission(PERMISSIONS.WRITE_THEME);

  const isSuperAdmin = useIsSuperAdmin();

  return hasWriteThemePermission || isSuperAdmin;
};

export default useHasSellerThemePermission;

import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { Didactic } from '@savgroup-front-common/core/src/atoms/Didactic';
import { Tooltip } from '@savgroup-front-common/core/src/atoms/Tooltip';
import { safeFormattedIntlStringWithId } from '@savgroup-front-common/core/src/formatters/safeFormattedIntlStringWithId';
import { SafeFormattedMessageWithId } from '@savgroup-front-common/core/src/formatters/SafeFormattedMessageWithId';
import {
  CheckCircleIcon,
  QuestionIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { PlanDto } from '../ChoosePlan.types';

import { buildComparePlansDto } from './ComparePlans.helpers';
import {
  $Cell,
  $CompareLineLabel,
  $SectionHeader,
} from './ComparePlans.styles';

interface ComparePlansProps {
  plans: PlanDto[];
}

export const ComparePlans: FC<ComparePlansProps> = ({ plans }) => {
  const theme = useTheme();
  const comparePlanDto = buildComparePlansDto({ plans });

  const sectionUniqueNames = Object.keys(comparePlanDto);

  return (
    <>
      {sectionUniqueNames?.map((sectionUniqueName) => {
        const section = comparePlanDto[sectionUniqueName];

        const featureUniqueNames = Object.keys(section);

        return (
          <>
            <$SectionHeader>
              <SafeFormattedMessageWithId
                id={`pricing.comparePlans.sectionTitle.${sectionUniqueName}`}
              />
            </$SectionHeader>

            {featureUniqueNames?.map((featureUniqueName) => {
              return (
                <>
                  <$CompareLineLabel>
                    <div>
                      <SafeFormattedMessageWithId
                        id={`pricing.comparePlans.feature.${featureUniqueName}`}
                      />
                    </div>

                    <Tooltip
                      html={
                        <div>
                          {safeFormattedIntlStringWithId(
                            `pricing.comparePlans.feature.${featureUniqueName}.didactic`,
                          )}
                        </div>
                      }
                    >
                      <QuestionIcon size="20px" color={theme.colors.default} />
                    </Tooltip>
                  </$CompareLineLabel>

                  {plans.map((plan) => {
                    const checkStatus = section[featureUniqueName][plan.name];

                    if (!checkStatus) {
                      return (
                        <$Cell key={`${plan.name}_${featureUniqueName}`}>
                          -
                        </$Cell>
                      );
                    }

                    return (
                      <$Cell key={`${plan.name}_${featureUniqueName}`}>
                        {checkStatus?.isAvailable &&
                          !checkStatus?.localisationKey && (
                            <CheckCircleIcon color={theme.colors.primary} />
                          )}

                        {checkStatus?.isAvailable &&
                          checkStatus?.localisationKey && (
                            <Didactic>
                              <SafeFormattedMessageWithId
                                id={`pricing.comparePlans.feature.condition.${checkStatus.localisationKey}`}
                              />
                            </Didactic>
                          )}
                      </$Cell>
                    );
                  })}
                </>
              );
            })}
          </>
        );
      })}
    </>
  );
};

import React, { FC, Suspense } from 'react';

import { SwipeableDrawer } from '@savgroup-front-common/core/src/atoms/SwipeableDrawer';
import {
  DIRECTION,
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { ChoosePlan } from './ChoosePlan/ChoosePlan';
import { useChoosePlanContext } from './ChoosePlanDrawer.context';

export const ChoosePlanDrawer: FC = () => {
  const { isOpen, handleDrawerClose } = useChoosePlanContext();

  return (
    <SwipeableDrawer
      isOpen={isOpen}
      size={DRAWER_SIZE.FULL_SCREEN}
      direction={DIRECTION.TOP}
      hasPadding={false}
      onClose={handleDrawerClose}
    >
      <Suspense fallback={<BaseLoader />}>
        <ChoosePlan />
      </Suspense>
    </SwipeableDrawer>
  );
};

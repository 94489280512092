import { defineMessages } from 'react-intl';

import { TABS_SETTINGS } from '../../../constants/routing';

export default defineMessages({
  [TABS_SETTINGS.SELLERS]: {
    id: 'view.settings.titleAccountSettings',
    defaultMessage: 'Account Settings',
  },
  [TABS_SETTINGS.IMPORTS]: {
    id: 'view.nav.items.imports',
    defaultMessage: 'Imports',
  },

  account: {
    id: 'view.settings.seller.tab.account',
    defaultMessage: 'Account',
  },

  theme: {
    id: 'view.settings.tenants.theme',
    defaultMessage: 'Theme',
  },

  connectedApps: {
    id: 'view.settings.integrations',
    defaultMessage: 'Integrations',
  },
  userManagement: {
    id: 'view.settings.userManagement',
    defaultMessage: 'User management',
  },
  profile: {
    id: 'view.settings.seller.tab.profile',
    defaultMessage: 'Profile',
  },
  configurationConsole: {
    id: 'view.settings.configurationConsole',
    defaultMessage: 'Configuration console',
  },
  warranties: {
    id: 'view.settings.warranties',
    defaultMessage: 'Warranties',
  },
  catalog: {
    id: 'view.settings.catalog',
    defaultMessage: 'Catalog',
  },
  warehouses: {
    id: 'view.settings.warehouses',
    defaultMessage: 'Warehouses',
  },
  notifications: {
    id: 'view.settings.notifications',
    defaultMessage: 'Notifications',
  },
  billing: {
    id: 'view.settings.billing',
    defaultMessage: 'Billing',
  },
  subscription: {
    id: 'view.settings.subscription',
    defaultMessage: 'Subscription',
  },
  sellerUpdate: {
    id: 'view.settings.seller.titleSellerUpdate',
    defaultMessage: 'Seller update',
  },
  tenantUpdate: {
    id: 'view.settings.seller.titleTenantUpdate',
    defaultMessage: 'Tenant edition',
  },
  repairers: {
    id: 'view.settings.repairers',
    defaultMessage: 'Repairers',
  },
  lockedFeature: {
    id: 'view.settings.lockedFeature',
    defaultMessage: 'Feature only available for essential offer',
  },
  carriers: {
    id: 'view.settings.carriers',
    defaultMessage: 'Carriers',
  },
  stores: {
    id: 'view.settings.stores',
    defaultMessage: 'Stores',
  },
  suppliers: {
    id: 'view.settings.suppliers',
    defaultMessage: 'Suppliers',
  },
  rules: {
    id: 'view.settings.rules',
    defaultMessage: 'Rules',
  },
});

import React, { FC } from 'react';

import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import { Card } from '@savgroup-front-common/core/src/atoms/Cards';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SafeFormattedMessageWithId } from '@savgroup-front-common/core/src/formatters/SafeFormattedMessageWithId';

import { PlanDto } from '../ChoosePlan.types';

import { ChoosePlanButton } from './ChoosePlanButton/ChoosePlanButton';
import messages from './messages';
import {
  $PlanPreview,
  $PreviewFeatureContainer,
  $PreviewFeatureHeading,
} from './PlanPreview.styles';
import { PlanPrice } from './PlanPrice/PlanPrice';
import { PreviewFeature } from './PreviewFeature/PreviewFeature';

interface PlanPreviewProps {
  plan: PlanDto;
}

const CONTACT_SALES_URL =
  'https://meetings.hubspot.com/clement-ducrocq?uuid=ec8f0cd1-4f01-4f7c-9bb4-1dda224ff0b8';

export const PlanPreview: FC<PlanPreviewProps> = ({ plan }) => {
  return (
    <Card withoutMarginBottom withoutMarginTop>
      <$PlanPreview>
        <Heading level={4}>
          <SafeFormattedMessageWithId id={`pricing.${plan.name}`} />
        </Heading>

        <PlanPrice plan={plan} />

        <div>
          {plan.needToContactSales && (
            <ButtonLink secondary isExternal href={CONTACT_SALES_URL}>
              <SafeFormattedMessageWithoutSpread
                message={messages.contactSales}
              />
            </ButtonLink>
          )}

          {!plan.needToContactSales && <ChoosePlanButton plan={plan} />}
        </div>

        <div>
          <$PreviewFeatureHeading>
            <SafeFormattedMessageWithId
              id={`pricing.${plan.name}.previewFeaturesTitle`}
            />
            :
          </$PreviewFeatureHeading>

          <$PreviewFeatureContainer>
            {plan.previewFeatures.map((previewFeature) => {
              return (
                <PreviewFeature
                  key={previewFeature.name}
                  previewFeature={previewFeature}
                  planName={plan.name}
                />
              );
            })}
          </$PreviewFeatureContainer>
        </div>
      </$PlanPreview>
    </Card>
  );
};

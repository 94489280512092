import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { InterventionReportSummary } from './interventionReport/getInterventionReport/getInterventionReport';

const ENDPOINT = `${APIConfiguration.repairer}repairers/interventionreports/${COMMON_QUERY_PARAMS.FILE_ID}`;

interface GetInterventionReportArgs {
  fileId: string;
  continueIfNull?: boolean;
}

export default async function getInterventionReportQuery(
  payload: GetInterventionReportArgs,
): Promise<BackResponse<InterventionReportSummary> | BackResponseFailure> {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId);

    return await apiCall<BackResponse<InterventionReportSummary>>(
      buildUrl(url, {
        continueIfNull: payload.continueIfNull ?? true,
      }),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

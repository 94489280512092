import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $SectionHeader = styled.div`
  padding: 1rem ${rem(12)};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.large};

  grid-column: 1 / -1;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const $Cell = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const $CompareLineLabel = styled.div`
  display: flex;
  gap: 0.25rem;

  & > div:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'view.choosePricing.title',
    defaultMessage: 'Choose your plan',
  },
  didactic: {
    id: 'view.choosePricing.didactic',
    defaultMessage: 'Choose the perfect plan for your business needs',
  },
});

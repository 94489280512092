import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';

import {
  CONTROL_REPAIRER_DOMAIN_KEY,
  repairerReducer as controlRepairerReducer,
} from './controlRepairer';
import {
  constants as actorsConstants,
  reducer as actorsReducer,
} from '@savgroup-front-common/core/src/domains/actors';
import { reducer as attachmentsReducer } from '@savgroup-front-common/core/src/domains/attachments';
import { reducer as carriersReducer } from '@savgroup-front-common/core/src/domains/carriers';
import {
  constants as claimsConstants,
  reducer as claimsReducer,
} from '@savgroup-front-common/core/src/domains/claims';
import { reducer as fileUploadReducer } from '@savgroup-front-common/core/src/domains/fileUpload';
import {
  I18N_KEY,
  reducer as i18nReducer,
} from '@savgroup-front-common/core/src/domains/i18n';
import { reducer as loginReducer } from '@savgroup-front-common/core/src/domains/login';
import routerReducer from '@savgroup-front-common/core/src/domains/router/reducer';
import {
  SAGA_REQUEST_METADATA_KEY,
  reducer as sagaRequestMetadataReducer,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata';
import { reducer as sellerConfigurationReducer } from '@savgroup-front-common/core/src/domains/sellerConfiguration';
import {
  SELLER_DOMAIN_KEY,
  sellerReducer,
} from '@savgroup-front-common/core/src/domains/sellers';
import {
  STORE_DOMAIN_KEY,
  storeReducer,
} from '@savgroup-front-common/core/src/domains/store';
import {
  TECHNICAL_INTERVENTIONS_DOMAIN_KEY,
  technicalInterventionsReducer,
} from '@savgroup-front-common/core/src/domains/technicalInterventions';
import { reducer as additionalInformationReducer } from 'control/domains/additionalInformation';

import { reducer as accessReducer } from './access';
import { ALERTS_LIST_DOMAIN_KEY, alertsListReducer } from './alertsList';
import businessReducer from './business/businessReducer';
import { reducer as controlClaimsReducer } from './claims';
import { reducer as debugReducer } from './debug';
import { reducer as filesReducer } from './files';
import { FILES_LIST_DOMAIN_KEY, filesListReducer } from './filesList';
import { reducer as filtersReducer } from './filter';
import { INVOICE_DOMAIN_KEY, invoiceReducer } from './invoice';
import authReducer from './login/authReducer';
import { reducer as marketplaceTicketsReducer } from './marketplaceTickets';
import modalsReducer from './modals/reducer';
import { reducer as modelsReducer } from './models';
import { reducer as ordersReducer } from './orders';
import { reducer as ownerReducer } from './owner';
import {
  PRODUCT_SUPPLIER_DOMAIN_KEY,
  productSupplierReducer,
} from './productSupplier';
import { reducer as quoteReducer } from './quote';
import { reducer as refundsReducer } from './refunds';
import { REPAIRER_DOMAIN_KEY, repairerReducer } from './repairer';
import { reducer as ruleImportReducer } from './ruleImport';
import {
  SELLERS_INFO_DOMAIN_KEY,
  reducer as sellersInfoReducer,
} from './sellersInfo';
import {
  SPARE_PART_REQUEST_DOMAIN_KEY,
  sparePartRequestReducer,
} from './sparePartRequests';
import { SPARE_PARTS_DOMAIN_KEY, sparePartsReducer } from './spareParts';
import {
  SPARE_PART_SUPPLIER_DOMAIN_KEY,
  sparePartSupplierReducer,
} from './sparePartSupplier';
import { STOCKS_DOMAIN_KEY, stocksReducer } from './stocks';
import { TODOS_LIST_DOMAIN_KEY, todosListReducer } from './todosList';
import { USER_MANAGEMENT_KEY, userManagementReducer } from './userManagement';
import { USERS_DOMAIN_KEY, usersReducer } from './users';
import { reducer as workflowReducer } from './workflow';

const createRootReducer = () =>
  combineReducers({
    orders: ordersReducer,
    [SPARE_PART_REQUEST_DOMAIN_KEY]: sparePartRequestReducer,
    [USERS_DOMAIN_KEY]: usersReducer,
    [actorsConstants.ACTORS_DOMAIN_KEY]: actorsReducer,
    [claimsConstants.CLAIMS_DOMAIN_KEY]: claimsReducer,
    [I18N_KEY]: i18nReducer,
    [REPAIRER_DOMAIN_KEY]: repairerReducer,
    [CONTROL_REPAIRER_DOMAIN_KEY]: controlRepairerReducer,
    [SAGA_REQUEST_METADATA_KEY]: sagaRequestMetadataReducer,
    [SPARE_PART_SUPPLIER_DOMAIN_KEY]: sparePartSupplierReducer,
    [PRODUCT_SUPPLIER_DOMAIN_KEY]: productSupplierReducer,
    [STORE_DOMAIN_KEY]: storeReducer,
    [USER_MANAGEMENT_KEY]: userManagementReducer,
    [SPARE_PARTS_DOMAIN_KEY]: sparePartsReducer,
    access: accessReducer,
    additionalInformation: additionalInformationReducer,
    attachments: attachmentsReducer,
    auth: authReducer,
    business: businessReducer,
    carriers: carriersReducer,
    debug: debugReducer,
    fileUpload: fileUploadReducer,
    files: filesReducer,
    filters: filtersReducer,
    login: loginReducer,
    marketplaceTickets: marketplaceTicketsReducer,
    modals: modalsReducer,
    models: modelsReducer,
    oidc: oidcReducer,
    owner: ownerReducer,
    quote: quoteReducer,
    [SELLERS_INFO_DOMAIN_KEY]: sellersInfoReducer,
    [FILES_LIST_DOMAIN_KEY]: filesListReducer,
    [TODOS_LIST_DOMAIN_KEY]: todosListReducer,
    [ALERTS_LIST_DOMAIN_KEY]: alertsListReducer,
    refunds: refundsReducer,
    router: routerReducer,
    ruleImport: ruleImportReducer,
    sellerConfiguration: sellerConfigurationReducer,
    [SELLER_DOMAIN_KEY]: sellerReducer,
    [STOCKS_DOMAIN_KEY]: stocksReducer,
    [INVOICE_DOMAIN_KEY]: invoiceReducer,
    [TECHNICAL_INTERVENTIONS_DOMAIN_KEY]: technicalInterventionsReducer,
    workflow: workflowReducer,
    controlClaims: controlClaimsReducer,
  });

export default createRootReducer;

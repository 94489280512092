import styled from 'styled-components';

export const $Video = styled.video`
  width: 100%;
  height: 100%;
`;

export const $ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  background-color: ${({ theme }) => theme.systemColors.secondaryBackground};
  border-radius: ${({ theme }) => theme.newUI.borders.smallRadius};
`;

export const $Img = styled.img`
  height: 100%;
  width: unset;
`;

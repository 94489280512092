import { defineMessages } from 'react-intl';

export default defineMessages({
  youWillExitDemoContext: {
    id: 'ChoosePlanButton.youWillExitDemoContext',
    defaultMessage: 'You will exit the demo context',
  },
  youWillExitDemoContextDescription: {
    id: 'ChoosePlanButton.youWillExitDemoContextDescription',
    defaultMessage:
      'When you confirm, you will exit the demo context and start your subscription.',
  },
  stayInDemo: {
    id: 'ChoosePlanButton.stayInDemo',
    defaultMessage: 'Stay in demo',
  },
  confirm: {
    id: 'ChoosePlanButton.confirm',
    defaultMessage: 'Confirm',
  },
});

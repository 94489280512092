import { useMutation } from 'react-query';

import { delay } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';

import { SellerService } from '../../../../../../../api';
import { useAppContext } from '../../../../../../app/App.context';
import { PlanDto } from '../../../ChoosePlan.types';

export const useConfirmPushToProductionModalContent = ({
  plan,
}: {
  plan: PlanDto;
}) => {
  const { pushErrors } = useToasts();
  const { selectedSeller } = useAppContext();
  const { mutateAsync: handlePushToProduction, isLoading } = useMutation(
    'pushToProduction',
    async () => {
      if (!selectedSeller?.id) {
        return undefined;
      }

      const response = await SellerService.pushToProductionCommand({
        sellerId: selectedSeller?.id,
        pricingPlan: plan.pricingPlan,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      await delay(15 * 1000);

      window.location.reload();
    },
  );

  return { handlePushToProduction, isLoading };
};

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}shopifyIntegration`;

export type AddShopifyIntegrationCommandPayload = {
  sellerId: string;
  shopifyApiKey: string;
  shopifyPassword: string;
  shopifyShopName: string;
};

export const addShopifyIntegrationCommand = async (
  payload: AddShopifyIntegrationCommandPayload,
): Promise<BackResponseFailure | BaseBackResponse> => {
  try {
    return await apiCall<BaseBackResponse>(buildUrl(ENDPOINT), {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

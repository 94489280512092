import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { buildUrl } from '@savgroup-front-common/core/src/helpers';
import { useSearchParams } from '@savgroup-front-common/core/src/hooks';
import { NEW_SETTINGS_ROUTES } from 'control/view/pages/NewSettings/NewSettingsPage.routes';

import { useAppContext } from '../App.context';

const ShippingBoRedirectRoutes = ({
  isFromScratchSeller,
}: {
  isFromScratchSeller?: boolean;
}) => {
  const navigate = useNavigate();
  const { selectedSeller } = useAppContext();
  const searchParams = useSearchParams();
  const code = searchParams.get('code') ?? undefined;

  useLayoutEffect(() => {
    if (isFromScratchSeller === false && selectedSeller) {
      navigate(
        buildUrl(NEW_SETTINGS_ROUTES.CONFIGURATION_CONSOLE_CATALOG, {
          tenantId: selectedSeller.tenantId,
          sellerId: selectedSeller.id,
          code,
        }),
      );
    }
  }, [navigate, code, isFromScratchSeller, selectedSeller]);
};

export default ShippingBoRedirectRoutes;

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}Upload/${COMMON_QUERY_PARAMS.SELLER_ID}/${COMMON_QUERY_PARAMS.IMPORT_TYPE}`;

interface UploadFileWithImportTypeCommandPayload {
  file: File;
  sellerId: string;
  importType: string;
}

type UploadFileWithImportTypeCommand = (
  payload: UploadFileWithImportTypeCommandPayload,
) => Promise<BackResponse<string> | BackResponseFailure>;

export const uploadFileWithImportTypeCommand: UploadFileWithImportTypeCommand =
  async ({ file, importType, sellerId }) => {
    const body = new FormData();

    body.append('file', file);

    try {
      return await apiCall<BackResponse<string>>(
        buildUrl(
          ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
            COMMON_QUERY_PARAMS.IMPORT_TYPE,
            importType,
          ),
        ),
        {
          method: SUPPORTED_METHODS.POST,
          body,
        },
      );
    } catch (exception) {
      return prepareResponseFailure(exception);
    }
  };

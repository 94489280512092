import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  SearchOrders,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.owner}orders/search`;

export enum GET_ORDERS_BY_QUERY_CONTEXT {
  GLOBAL_SEARCH = 'GlobalSearch',
  CREATE_FILE = 'CreateFile',
}

export interface GetOrdersByQueryArgs {
  page?: number;
  pageSize?: number;
  query: string;
  sort?: SORT_TYPES;
  isDescending?: boolean;
  sellerId?: string;
  context?: GET_ORDERS_BY_QUERY_CONTEXT;
}

async function getOrdersByQuery({
  page = 1,
  pageSize = 10,
  isDescending,
  query,
  sort,
  sellerId,
  context,
}: GetOrdersByQueryArgs): Promise<
  BackResponse<SearchOrders[]> | BackResponseFailure
> {
  try {
    const url = buildUrl(ENDPOINT, {
      page,
      pageSize,
      term: query,
      sort,
      isDescending,
      sellerId,
      context,
    });
    const response = await apiCall<BackResponse<SearchOrders[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getOrdersByQuery;

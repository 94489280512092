const join = (paths: Record<string, unknown>) => Object.values(paths).join('|');

export enum FILES_BASES {
  TODOS = 'todos',
  FILES = 'files',
  ALERTS = 'alerts',
  FILES_HISTORIC = 'archives',
}

export enum TABS_SETTINGS {
  MASTER = `master`,
  SELLERS = `sellers`,
  USERS = `users`,
  TESTS = `tests`,
  IMPORTS = 'imports',
  SPARE_PARTS = 'spare-parts',
}

export enum TABS_CONSOLE_ACTORS {
  SUPPLIERS = `suppliers`,
  REPAIRERS = `repairers`,
  STORES = `stores`,
}

export enum TABS_CREATE {
  FILES = 'files',
  SPARE_PARTS = 'spare-parts',
}

export enum TABS_TESTS {
  SUMMARY = `summary`,
  ON_GOING = `ongoing`,
  TERMINATED = `terminated`,
}

export enum TABS_CREATE_FILE {
  PRODUCT_REF = 'product-ref',
  ORDER_REF = 'order-ref',
  OWNER = 'owner',
}
export enum TABS_CREATE_SPARE_PARTS {
  REQUEST = 'request',
}

export enum TABS_SPARE_PARTS {
  REQUESTS = `request-lines`,
  STOCKS = `stock`,
  CREATE_REQUEST = 'create-request',
  REQUESTS_TO_VALIDATE = 'requests-to-validate',
  INVENTORIES = 'inventories',
}

export enum TABS_HISTORIES {
  FILE = `file`,
  ASSIGNMENT = `assignment`,
}

export enum TABS_TENANTS {
  PROFILES = `profiles`,
  THEME = 'theme',
  ACCOUNT = `account`,
  CONNECTED_APPS = 'connected-apps',
  TENANT_UPDATE = 'tenant-update',
  IMPORT = 'import',
}
export enum TABS_SELLERS_TYPE {
  SELLER = `seller`,
  TENANT = `tenant`,
}
export enum TABS_SELLERS {
  ACCOUNT = `account`,
  BUSINESS_RULES = `business-rules`,
  ENTITIES = `entities`,
  APPLICATION = `application`,
  CONNECTED_APPS = `connected-apps`,
  SELLER_UPDATE = 'seller-update',
  THEME = 'theme',
  IMPORT = 'import',
  SALES_IMPORT = 'sales-import',
}
export enum TABS_MASTER {
  WORKFLOW_TRANSLATION = 'workflow-translation',
  WORKFLOW = 'workflow',
  BUSINESS_RULES = 'business-rules',
}
export enum TABS_SPARE_PARTS_SETTINGS {
  CATALOG = `catalog`,
}

export enum TABS_FILE {
  SUMMARY = 'summary',
  HISTORIES = 'histories',
  CLIENT = 'client',
  DOCUMENTS = 'documents',
  INVOICES = 'invoices',
  PRODUCT = 'product',
  QUOTATION = 'quotation',
  TRANSPORT = 'transport',
  COMMENTS = 'comments',
  SPARE_PARTS = 'spare-parts',
  TECHNICAL_INTERVENTIONS = 'technical-interventions',
  LOAN = 'loan',
  ACCOUNTING = 'accounting',
  VIDEO_CALL = 'video-call',
}

export enum TABS_FILE_HISTORIC {
  SUMMARY = 'summary',
  PRODUCT = 'product',
  TECHNICAL_INTERVENTIONS = 'technical-interventions',
  COMMENTS = 'comments',
}

export enum TABS_CONFIGURATION_CONSOLE {
  FOLLOW_UP = 'follow-up',
  ENTITIES = 'entities',
  SETTINGS = 'settings',
  OPTIONS = 'options',
  IMPORT = 'import',
}

export enum TABS_BILLING {
  BILLING_TRACKING = 'billing-tracking',
  BILLING_CUSTOMERS_LIST = 'billing-customers-list',
  BILLING_PARAMETERS = 'billing-parameters',
}

export enum TABS_CONSOLE_OPTIONS {
  ENTITIES = 'entities',
  RULES = 'rules',
}

export const NAV = {
  HOME: '',
  WELCOME_PAGE: 'home',
  FROM_SCRATCH: 'from-scratch',
  TODOS: FILES_BASES.TODOS,
  FILES: FILES_BASES.FILES,
  FILES_HISTORIC: FILES_BASES.FILES_HISTORIC,
  BULK_CHANGES: 'bulk-changes',
  ALERTS: FILES_BASES.ALERTS,
  MESSAGES: 'messages',
  ORDERS: 'orders',
  SETTINGS: 'settings',
  DASHBOARD: 'dashboard',
  SPARE_PARTS: 'spare-parts',
  BUSINESS_INTELLIGENCE: 'business-intelligence',
  IFRAME: 'iframe',
  HELP: 'help',
  QUOTATION: 'quotation',
  CONFIGURATION_CONSOLE: 'configuration-console',
  DEBUG_INSPECTOR: 'debug-inspector',
  BILLING: 'billing',
};

export const BASES = {
  HOME: `/${NAV.HOME}`,
  WELCOME_PAGE: `/${NAV.WELCOME_PAGE}`,
  FROM_SCRATCH: `/${NAV.FROM_SCRATCH}`,
  TODOS: `/${NAV.TODOS}`,
  FILES: `/${NAV.FILES}`,
  FILES_HISTORIC: `/${NAV.FILES_HISTORIC}`,
  BULK_CHANGES: `/${NAV.BULK_CHANGES}`,
  ALERTS: `/${NAV.ALERTS}`,
  FILE: `/:fileBase(${join(FILES_BASES)})/:fileId`,
  MESSAGES: `/${NAV.MESSAGES}`,
  ORDERS: `/${NAV.ORDERS}`,
  BUSINESS_INTELLIGENCE: `/${NAV.BUSINESS_INTELLIGENCE}`,
  SETTINGS: `/${NAV.SETTINGS}`,
  DASHBOARD: `/${NAV.DASHBOARD}`,
  SPARE_PARTS: `/${NAV.SPARE_PARTS}`,
  IFRAME: `/${NAV.IFRAME}`,
  HELP: `/${NAV.HELP}`,
  QUOTATION: `/${NAV.QUOTATION}`,
  CONFIGURATION_CONSOLE: `/${NAV.CONFIGURATION_CONSOLE}`,
  BILLING: `/${NAV.BILLING}`,
  DEBUG_INSPECTOR: `/${NAV.DEBUG_INSPECTOR}`,
};

export const ROUTES = {
  HOME: BASES.HOME,
  SHIPPING_BO: `/shippingBoCallback`,
  WELCOME_PAGE: `${BASES.WELCOME_PAGE}/:unknown?`, // use unknown to avoid blank Page on start
  FROM_SCRATCH: `${BASES.FROM_SCRATCH}`,
  DASHBOARD: BASES.DASHBOARD,
  TODOS: BASES.TODOS,
  BULK_CHANGES: BASES.BULK_CHANGES,
  TODOS_FILE: `${BASES.TODOS}/:fileId`,
  TODOS_FILE_TAB: `${BASES.TODOS}/:fileId/:fileTabId`,
  TODOS_WITH_PARAMS: `${BASES.TODOS}/:fileId?/:fileTabId?/:fileSubTabId?`,
  FILE_DETAILS: `${BASES.FILES}/:fileId/:fileTabId?/:fileSubTabId?`,
  FILES_NEW_UI: `${BASES.FILES}`,
  TODOS_NEW_UI: `${BASES.FILES}/todos`,
  FILES_HISTORIC: `${BASES.FILES_HISTORIC}/:fileId?/:fileTabId?`,
  ALL_FILES: `${BASES.FILE}?/:fileTabId?`,
  ALERTS: BASES.ALERTS,
  ALERTS_FILE: `${BASES.ALERTS}/:fileId`,
  ALERTS_WITH_PARAMS: `${BASES.ALERTS}/:fileId?/:fileTabId?/:fileSubTabId?`,
  FOLDER_QUOTATION: `${BASES.FILES}/:fileId/quotation`,
  MESSAGES: `${BASES.MESSAGES}/:ticketId?`,
  ORDERS: `${BASES.ORDERS}/:orderId?`,
  ORDER_DETAILS: `${BASES.ORDERS}/:orderId`,
  SETTINGS: `${BASES.SETTINGS}/:settingTabId?`,
  BUSINESS_INTELLIGENCE: `${BASES.BUSINESS_INTELLIGENCE}`,
  SELLERS_SETTINGS: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId?`,
  TESTS: `${BASES.SETTINGS}/${TABS_SETTINGS.TESTS}`,
  SPARE_PARTS: `${BASES.SPARE_PARTS}/:sparePartMenuId?`,
  IFRAME: `${BASES.IFRAME}`,
  HELP: `${BASES.HELP}`,
  ISSUE_A_QUOTATION: `${BASES.QUOTATION}/create-quotation/fromFile/:fileId`,
  CONFIGURATION_CONSOLE: `/${BASES.CONFIGURATION_CONSOLE}/:sellerId/:consoleTabId?/:routingKey?/:consoleSubTab?/:consoleSubTabId?`,
  BILLING: `/${NAV.BILLING}/:billingTabId?`,
  DEBUG_INSPECTOR: `${BASES.DEBUG_INSPECTOR}/:claimGroupId?`,
};

export const ORDERS_RELATIVE_ROUTES = {
  ORDER_DETAILS: `:orderId`,
  ORDERS: `orders`,
};

export const TODOS_RELATIVE_ROUTES = {
  TODO_DETAILS_DEFAULT_TAB: ':fileId',
  TODO_DETAILS: ':fileId/:fileTabId?/:fileSubTabId?',
};
export const ALERTS_RELATIVE_ROUTES = {
  ALERT_DETAILS_DEFAULT_TAB: ':fileId',
  ALERT_DETAILS: ':fileId/:fileTabId?/:fileSubTabId?',
};

export const FILES_RELATIVE_ROUTES = {
  FILE_DETAILS_DEFAULT_TAB: `:fileId`,
  FILES_NEW_UI: ``,
  TODOS_NEW_UI: `todos`,
};

export const CONFIGURATION_CONSOLE_ROUTES = Object.freeze({
  FOLLOW_UP: `${BASES.CONFIGURATION_CONSOLE}/:sellerId/${TABS_CONFIGURATION_CONSOLE.FOLLOW_UP}`,
  ENTITIES: `${BASES.CONFIGURATION_CONSOLE}/:sellerId/${TABS_CONFIGURATION_CONSOLE.ENTITIES}`,
  SETTINGS: `${BASES.CONFIGURATION_CONSOLE}/:sellerId/${TABS_CONFIGURATION_CONSOLE.SETTINGS}`,
  OPTIONS: `${BASES.CONFIGURATION_CONSOLE}/:sellerId/${TABS_CONFIGURATION_CONSOLE.OPTIONS}`,
  IMPORT: `${BASES.CONFIGURATION_CONSOLE}/:sellerId/${TABS_CONFIGURATION_CONSOLE.IMPORT}`,
});

export const IMPORT_MODULE_RELATIVE_ROUTE = 'import-module';

export const IMPORT_MODULE_ROUTES = {
  BUSINESS_RULES_IMPORT: `${CONFIGURATION_CONSOLE_ROUTES.FOLLOW_UP}/${IMPORT_MODULE_RELATIVE_ROUTE}/business-rules`,
  MASTER_IMPORT: `${CONFIGURATION_CONSOLE_ROUTES.FOLLOW_UP}/${IMPORT_MODULE_RELATIVE_ROUTE}/master`,
  SALES_IMPORT: `${CONFIGURATION_CONSOLE_ROUTES.FOLLOW_UP}/${IMPORT_MODULE_RELATIVE_ROUTE}/sales`,
};

export const IMPORT_MODULE_RELATIVE_ROUTES = {
  BUSINESS_RULES_IMPORT: `${IMPORT_MODULE_RELATIVE_ROUTE}/business-rules`,
  MASTER_IMPORT: `${IMPORT_MODULE_RELATIVE_ROUTE}/master`,
  SALES_IMPORT: `${IMPORT_MODULE_RELATIVE_ROUTE}/sales`,
};

export const BILLING_ROUTES = Object.freeze({
  BILLING_TRACKING: `${BASES.BILLING}/${TABS_BILLING.BILLING_TRACKING}`,
  BILLING_CUSTOMERS_LIST: `${BASES.BILLING}/${TABS_BILLING.BILLING_CUSTOMERS_LIST}`,
  BILLING_PARAMETERS: `${BASES.BILLING}/${TABS_BILLING.BILLING_PARAMETERS}`,
});

export const OPTIONS_CONSOLE_ROUTES = {
  ENTITIES: `entities`,
  RULES: `rules`,
};

export const FILE_ROUTES = {
  CLIENT: `/files/:fileId/${TABS_FILE.CLIENT}`,
  SUMMARY: `/files/:fileId/${TABS_FILE.SUMMARY}`,
  PRODUCT: `/files/:fileId/${TABS_FILE.PRODUCT}`,

  TODO_CLIENT: `${BASES.TODOS}/:fileId/${TABS_FILE.CLIENT}`,
  TODO_SUMMARY: `${BASES.TODOS}/:fileId/${TABS_FILE.SUMMARY}`,
  TODO_PRODUCT: `${BASES.TODOS}/:fileId/${TABS_FILE.PRODUCT}`,

  ALERT_CLIENT: `${BASES.ALERTS}/:fileId/${TABS_FILE.CLIENT}`,
  ALERT_SUMMARY: `${BASES.ALERTS}/:fileId/${TABS_FILE.SUMMARY}`,
  ALERT_PRODUCT: `${BASES.ALERTS}/:fileId/${TABS_FILE.PRODUCT}`,

  COMMENTS: `${BASES.FILE}/${TABS_FILE.COMMENTS}`,
  DEFAULT_TAB: TABS_FILE.SUMMARY,
  DOCUMENTS: `${BASES.FILE}/${TABS_FILE.DOCUMENTS}`,
  HISTORIES: `${BASES.FILE}/${TABS_FILE.HISTORIES}`,
  INVOICES: `${BASES.FILE}/${TABS_FILE.INVOICES}`,
  QUOTATION: `${BASES.FILE}/${TABS_FILE.QUOTATION}`,
  SPARE_PARTS: `${BASES.FILE}/${TABS_FILE.SPARE_PARTS}`,
  TRANSPORT: `${BASES.FILE}/${TABS_FILE.TRANSPORT}`,
  TECHNICAL_INTERVENTIONS: `${BASES.FILE}/${TABS_FILE.TECHNICAL_INTERVENTIONS}`,
  LOAN: `${BASES.FILE}/${TABS_FILE.LOAN}`,
  ACCOUNTING: `${BASES.FILE}/${TABS_FILE.ACCOUNTING}`,
  VIDEO_CALL: `${BASES.FILE}/${TABS_FILE.VIDEO_CALL}`,
};

export const HISTORIES_ROUTES = {
  [TABS_HISTORIES.FILE]: `${FILE_ROUTES.HISTORIES}/${TABS_HISTORIES.FILE}`,
  [TABS_HISTORIES.ASSIGNMENT]: `${FILE_ROUTES.HISTORIES}/${TABS_HISTORIES.ASSIGNMENT}`,
};

export const SPARE_PART_ROUTES = {
  REQUESTS: `${BASES.SPARE_PARTS}/${TABS_SPARE_PARTS.REQUESTS}/:requestLineId?`,
  STOCKS: `${BASES.SPARE_PARTS}/${TABS_SPARE_PARTS.STOCKS}/:stockItemId?`,
  CREATE_REQUEST: `${BASES.SPARE_PARTS}/${TABS_SPARE_PARTS.CREATE_REQUEST}`,
  CREATE_REQUEST_FROM_FILE: `${BASES.SPARE_PARTS}/${TABS_SPARE_PARTS.CREATE_REQUEST}/fromFile/:fileId?`,
  REQUESTS_TO_VALIDATE: `${BASES.SPARE_PARTS}/${TABS_SPARE_PARTS.REQUESTS_TO_VALIDATE}/:fileReference?`,
  INVENTORIES: `${BASES.SPARE_PARTS}/${TABS_SPARE_PARTS.INVENTORIES}`,
};

export const RELATIVE_SPARE_PART_ROUTES = {
  REQUESTS: `${TABS_SPARE_PARTS.REQUESTS}/:requestLineId?`,
  STOCKS: `${TABS_SPARE_PARTS.STOCKS}/:stockItemId?`,
  CREATE_REQUEST: `${TABS_SPARE_PARTS.CREATE_REQUEST}`,
  CREATE_REQUEST_FROM_FILE: `${TABS_SPARE_PARTS.CREATE_REQUEST}/fromFile/:fileId?`,
  REQUESTS_TO_VALIDATE: `${TABS_SPARE_PARTS.REQUESTS_TO_VALIDATE}/:fileReference?`,
  INVENTORIES: `${TABS_SPARE_PARTS.INVENTORIES}`,
};

export const SETTINGS_ROUTES = {
  SELLERS: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}`,
  USERS: `${BASES.SETTINGS}/${TABS_SETTINGS.USERS}`,
  TESTS: `${BASES.SETTINGS}/${TABS_SETTINGS.TESTS}`,
  IMPORTS: `${BASES.SETTINGS}/${TABS_SETTINGS.IMPORTS}`,
  MASTER: `${BASES.SETTINGS}/${TABS_SETTINGS.MASTER}`,
  SPARE_PARTS: `${BASES.SETTINGS}/${TABS_SETTINGS.SPARE_PARTS}`,
};
export const SETTINGS_ROUTES_WITH_PARAMS = {
  SELLERS: `${SETTINGS_ROUTES.SELLERS}/:sellerId?/:sellerType?`,
  SPARE_PARTS: `${SETTINGS_ROUTES.SPARE_PARTS}/sellers/:sellerId?`,
  USERS: `${SETTINGS_ROUTES.USERS}/:userId?`,
  TESTS: `${SETTINGS_ROUTES.TESTS}/:testTabId?`,
  IMPORTS: `${SETTINGS_ROUTES.IMPORTS}`,
  MASTER: `${SETTINGS_ROUTES.MASTER}`,
};

export const RELATIVE_SETTINGS_ROUTES_WITH_PARAMS = {
  SELLERS: `${TABS_SETTINGS.SELLERS}/:sellerId?/:sellerType?`,
  SPARE_PARTS: `${TABS_SETTINGS.SPARE_PARTS}/sellers/:sellerId?`,
  USERS: `${TABS_SETTINGS.USERS}/:userId?`,
  TESTS: `${TABS_SETTINGS.TESTS}/:testTabId?`,
  IMPORTS: `${TABS_SETTINGS.IMPORTS}`,
  MASTER: `${TABS_SETTINGS.MASTER}`,
};

export const TESTS_ROUTES = {
  SUMMARY: `${BASES.SETTINGS}/${TABS_SETTINGS.TESTS}`,
  ON_GOING: `${BASES.SETTINGS}/${TABS_SETTINGS.TESTS}/${TABS_TESTS.ON_GOING}`,
  TERMINATED: `${BASES.SETTINGS}/${TABS_SETTINGS.TESTS}/${TABS_TESTS.TERMINATED}`,
};

export const SETTINGS_SELLERS_PATH = {
  SELLER: `${SETTINGS_ROUTES.SELLERS}/:sellerId/seller/:sellerTabId?`,
  TENANT: `${SETTINGS_ROUTES.SELLERS}/:sellerId/tenant/:sellerTabId?`,
};

export const TENANTS_ROUTES = {
  PROFILES: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/tenant/${TABS_TENANTS.PROFILES}/:profileId?`,
  THEME: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/tenant/${TABS_TENANTS.THEME}`,
  ACCOUNT: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/tenant/${TABS_TENANTS.ACCOUNT}`,
  CONNECTED_APPS: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/tenant/${TABS_TENANTS.CONNECTED_APPS}`,
  TENANT_UPDATE: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/tenant/${TABS_TENANTS.TENANT_UPDATE}`,
  IMPORT: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/tenant/${TABS_TENANTS.IMPORT}`,
};

export const RELATIVE_TENANTS_ROUTES = {
  PROFILES: `${TABS_TENANTS.PROFILES}/:profileId?`,
  THEME: `${TABS_TENANTS.THEME}`,
  ACCOUNT: `${TABS_TENANTS.ACCOUNT}`,
  CONNECTED_APPS: `${TABS_TENANTS.CONNECTED_APPS}`,
  TENANT_UPDATE: `${TABS_TENANTS.TENANT_UPDATE}`,
  IMPORT: `${TABS_TENANTS.IMPORT}`,
};

export const SELLERS_ROUTES = {
  ACCOUNT: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_SELLERS.ACCOUNT}`,
  APPLICATION: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_SELLERS.APPLICATION}`,
  CONNECTED_APPS: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_SELLERS.CONNECTED_APPS}`,
  BUSINESS_RULES: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_SELLERS.BUSINESS_RULES}/:ruleType?`,
  ENTITIES: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_SELLERS.ENTITIES}/:entityType?`,
  SELLER_UPDATE: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_SELLERS.SELLER_UPDATE}`,
  THEME: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_TENANTS.THEME}`,
  IMPORT: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_TENANTS.IMPORT}`,
  SALES_IMPORT: `${BASES.SETTINGS}/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_SELLERS.SALES_IMPORT}`,
};

export const RELATIVE_SELLERS_ROUTES = {
  ACCOUNT: `${TABS_SELLERS.ACCOUNT}`,
  APPLICATION: `${TABS_SELLERS.APPLICATION}`,
  CONNECTED_APPS: `${TABS_SELLERS.CONNECTED_APPS}`,
  BUSINESS_RULES: `${TABS_SELLERS.BUSINESS_RULES}/:ruleType?`,
  ENTITIES: `${TABS_SELLERS.ENTITIES}/:entityType?`,
  SELLER_UPDATE: `${TABS_SELLERS.SELLER_UPDATE}`,
  THEME: `${TABS_TENANTS.THEME}`,
  IMPORT: `${TABS_TENANTS.IMPORT}`,
  SALES_IMPORT: `${TABS_SELLERS.SALES_IMPORT}`,
};

export const MASTER_ROUTES = {
  [TABS_MASTER.WORKFLOW_TRANSLATION]: `${BASES.SETTINGS}/${TABS_SETTINGS.MASTER}/${TABS_MASTER.WORKFLOW_TRANSLATION}`,
  [TABS_MASTER.WORKFLOW]: `${BASES.SETTINGS}/${TABS_SETTINGS.MASTER}/${TABS_MASTER.WORKFLOW}`,
  [TABS_MASTER.BUSINESS_RULES]: `${BASES.SETTINGS}/${TABS_SETTINGS.MASTER}/${TABS_MASTER.BUSINESS_RULES}`,
};
export const RELATIVE_MASTER_ROUTES = {
  [TABS_MASTER.WORKFLOW_TRANSLATION]: `${TABS_MASTER.WORKFLOW_TRANSLATION}`,
  [TABS_MASTER.WORKFLOW]: `${TABS_MASTER.WORKFLOW}`,
  [TABS_MASTER.BUSINESS_RULES]: `${TABS_MASTER.BUSINESS_RULES}`,
};

export const SPARE_PARTS_SETTINGS_ROUTES = {
  CATALOG: `${SETTINGS_ROUTES_WITH_PARAMS.SPARE_PARTS}/${TABS_SPARE_PARTS_SETTINGS.CATALOG}`,
};
export const RELATIVE_SPARE_PARTS_SETTINGS_ROUTES = {
  CATALOG: `${TABS_SPARE_PARTS_SETTINGS.CATALOG}`,
};

export const IFRAME_ROUTES = {
  FILE: TABS_CREATE.FILES,
  SPARE_PART: TABS_CREATE.SPARE_PARTS,
};

export const IFRAME_FILES_ROUTES = {
  ORDER_REF: `${TABS_CREATE_FILE.ORDER_REF}/:orderReference?`,
  OWNER: `${TABS_CREATE_FILE.OWNER}/:ownerId`,
};

export const IFRAME_SPARE_PARTS_ROUTES = {
  CREATE_SPARE_PARTS_REQUEST: `create-request/fromFile/:fileId?`,
};
export const IFRAME_QUOTATION_ROUTES = {
  CREATE_QUOTATION_REQUEST: `quotation/create-quotation/fromFile/:fileId?`,
};

export const IFRAME_BUSINESS_ROUTES = {
  IMPORT_BUSINESS_RULES: `settings/${TABS_SETTINGS.SELLERS}/:sellerId/${TABS_SELLERS_TYPE.SELLER}/${TABS_TENANTS.IMPORT}`,
};

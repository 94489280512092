import { createSelector } from 'reselect';

import {
  getActionErrorsSelector,
  isActionLoadingSelector,
  wasActionLoadedSelector,
} from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';

import { ControlRootState } from '../ControlRootState';

import {
  DELETE_USER_PROFIL,
  GET_ASSIGNABLE_PERMISSIONS,
  GET_CONFIGURABLE_ROLES,
  GET_MANAGEMENT_USERS,
  GET_USER_PROFILES,
  SET_USER_PROFIL,
} from './actionTypes';
import { DEFAULT_ENTITY_TYPE, USER_MANAGEMENT_KEY } from './constants';
import { USER_MANAGEMENT_DOMAIN } from './types';

const userManagementState = (state: ControlRootState) =>
  state[USER_MANAGEMENT_KEY];

export const selectUserConfiguration = createSelector(
  [userManagementState],
  (userManagement) =>
    userManagement[USER_MANAGEMENT_DOMAIN.CONFIGURATION_BY_USER],
);
export const selectUserConfigurationByUserId = createSelector(
  [
    selectUserConfiguration,
    (_: ControlRootState, { userId }: { userId?: string }) => userId,
  ],
  (userManagement, userId) => {
    if (!userId) {
      return undefined;
    }

    return userManagement.find((user) => user.userId === userId);
  },
);

export const selectUserRolesByUserId = createSelector(
  [selectUserConfigurationByUserId],
  (managementUser) =>
    (managementUser?.credentials || []).filter(
      ({ builtInRole }) => builtInRole !== 'CustomProfile',
    ),
);

export const selectUserProfilesByUserId = createSelector(
  [selectUserConfigurationByUserId],
  (managementUser) =>
    (managementUser?.credentials || []).filter(
      ({ builtInRole }) => builtInRole === 'CustomProfile',
    ),
);

export const selectAssignableUserRoles = createSelector(
  [userManagementState],
  (userManagement) =>
    userManagement[USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_ROLES],
);
export const selectAssignableUserPermissions = createSelector(
  [userManagementState],
  (userManagement) =>
    userManagement[USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PERMISSIONS],
);

const selectAssignableUserProfiles = createSelector(
  [userManagementState],
  (userManagement) =>
    userManagement[USER_MANAGEMENT_DOMAIN.ASSIGNABLE_USER_PROFILES],
);

export const selectAssignableUserProfilesByTenantId = createSelector(
  [
    selectAssignableUserProfiles,
    (_state: ControlRootState, { tenantId }: { tenantId: string }) => tenantId,
  ],
  (userProfiles, id) => userProfiles.filter(({ tenantId }) => tenantId === id),
);

export const selectAssignableUserProfileById = createSelector(
  [
    selectAssignableUserProfiles,
    (_state: ControlRootState, { profileId }: { profileId: string }) =>
      profileId,
  ],
  (userProfiles, profileId) =>
    userProfiles.find(({ userProfileId }) => userProfileId === profileId),
);

export const getConfigurableRolesIsLoading = (
  state: ControlRootState,
  { entityType = DEFAULT_ENTITY_TYPE } = {},
) => isActionLoadingSelector(state, GET_CONFIGURABLE_ROLES, entityType);

export const getConfigurableRolesWasLoaded = (
  state: ControlRootState,
  { entityType = DEFAULT_ENTITY_TYPE } = {},
) => wasActionLoadedSelector(state, GET_CONFIGURABLE_ROLES, entityType);

export const setUserProfilIsLoadingSelector = (
  state: ControlRootState,
  { profileId }: { profileId: string },
) => isActionLoadingSelector(state, SET_USER_PROFIL, profileId);

export const setUserProfilErrorSelector = (
  state: ControlRootState,
  { profileId }: { profileId: string },
) => getActionErrorsSelector(state, SET_USER_PROFIL, profileId);

export const deleteUserProfilIsLoadingSelector = (
  state: ControlRootState,
  { profileId }: { profileId: string },
) => isActionLoadingSelector(state, DELETE_USER_PROFIL, profileId);

export const deleteUserProfilErrorSelector = (
  state: ControlRootState,
  { profileId }: { profileId: string },
) => getActionErrorsSelector(state, DELETE_USER_PROFIL, profileId);

export const selectGetUserProfilByTenantIsLoading = (
  state: ControlRootState,
  { tenantId }: { tenantId: string },
) => isActionLoadingSelector(state, GET_USER_PROFILES, tenantId);

export const selectGetUserProfilByTenantWasLoaded = (
  state: ControlRootState,
  { tenantId }: { tenantId?: string },
) => wasActionLoadedSelector(state, GET_USER_PROFILES, tenantId ?? '');

export const getAssignablePermissionsIsLoadingSelector = (
  state: ControlRootState,
) => isActionLoadingSelector(state, GET_ASSIGNABLE_PERMISSIONS);

export const getUserManagementwasLoadedSelector = (
  state: ControlRootState,
  { entityType = DEFAULT_ENTITY_TYPE } = {},
) => wasActionLoadedSelector(state, GET_MANAGEMENT_USERS, entityType);

export const getUserManagementIsLoadingSelector = (
  state: ControlRootState,
  { entityType = DEFAULT_ENTITY_TYPE } = {},
) => isActionLoadingSelector(state, GET_MANAGEMENT_USERS, entityType);

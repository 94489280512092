import React, { FC } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { RocketLaunchIcon } from '@savgroup-front-common/core/src/protons/icons';

import { useChoosePlanContext } from '../../ChoosePlanDrawer/ChoosePlanDrawer.context';

import messages from './messages';
import { $UpgradePlanButtonContainer } from './UpgradePlanButton.styles';

export const UpgradePlanButton: FC = () => {
  const { handleDrawerOpen } = useChoosePlanContext();

  return (
    <$UpgradePlanButtonContainer>
      <Button
        primary
        onClick={() => handleDrawerOpen()}
        type={BUTTON_TYPES.BUTTON}
        icon={<RocketLaunchIcon />}
        small
      >
        <SafeFormattedMessageWithoutSpread message={messages.upgradeButton} />
      </Button>
    </$UpgradePlanButtonContainer>
  );
};

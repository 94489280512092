import concat from 'lodash/concat';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { all, put, select, take } from 'redux-saga/effects';

import { ActionCreators as ClaimActionCreators } from '@savgroup-front-common/core/src/domains/claims';
import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { getClaimById } from '@savgroup-front-common/core/src/domains/claims/selectors';
import { wasEveryActionLoadedSelector } from '@savgroup-front-common/core/src/domains/sagaRequestMetadata/selectors';
import {
  ActionCreators as WorkflowActionCreators,
  ActionTypes as WorkflowActionTypes,
  Selectors as WorkflowSelectors,
} from '@savgroup-front-common/core/src/domains/workflow';
import { ActionCreators as AdditionalInformationActionCreators } from 'control/domains/additionalInformation';
import { fileSummarySelectors } from 'control/domains/workflow/fileSummary';
import { GET_FILE_FULL_SUMMARY } from 'control/domains/workflow/fileSummary/actions';

import { getModelById } from '../../models/actionCreators';
import { GET_MODEL_BY_ID } from '../../models/actionTypes';
import { getDetailedOrderByOrderId } from '../../orders/selectors';
import { currentFileIdSelector } from '../fileDetails';

function extractAdditionalInformationIds({
  additionalClaimInformation = [],
  files = [],
  orderProductAdditionalInfo = [],
}) {
  const fileAdditionalInformationIds = files.reduce((acc, file) => {
    const { fileAdditionalInformation = [] } = file;

    return [
      ...acc,
      ...fileAdditionalInformation.map(
        ({ additionalInformationId }) => additionalInformationId,
      ),
    ];
  }, []);

  const claimAdditionalInformationIds = additionalClaimInformation.map(
    ({ additionalInformationId }) => additionalInformationId,
  );

  const orderAdditionalInformationIds = orderProductAdditionalInfo.map(
    ({ additionalInformationId }) => additionalInformationId,
  );

  return concat(
    claimAdditionalInformationIds,
    fileAdditionalInformationIds,
    orderAdditionalInformationIds,
  ).filter((value) => value);
}

function extractAdditionalInformationEnumValues({
  additionalClaimInformation = [],
  files = [],
  orderProductAdditionalInfo,
}) {
  const claimAdditionalInformationEnumValues = additionalClaimInformation.map(
    ({ additionalInformationEnumValue }) => additionalInformationEnumValue,
  );

  const fileAdditionalInformationEnumValues = files.reduce((acc, file) => {
    const { fileAdditionalInformation = [] } = file;

    return [
      ...acc,
      ...fileAdditionalInformation.map(
        ({ additionalInformationEnumValue }) => additionalInformationEnumValue,
      ),
    ];
  }, []);

  const orderAdditionalInformationIds = orderProductAdditionalInfo.map(
    ({ additionalInformationEnumValue }) => additionalInformationEnumValue,
  );

  return concat(
    claimAdditionalInformationEnumValues,
    fileAdditionalInformationEnumValues,
    orderAdditionalInformationIds,
  ).filter((value) => value);
}

const byOwnerProductId =
  (searchedOwnerId) =>
  ({ ownerProductId }) =>
    searchedOwnerId === ownerProductId;

export default function* productTabSaga() {
  const fileId = yield select(currentFileIdSelector);

  yield take(GET_FILE_FULL_SUMMARY.SUCCEEDED);
  const fileSummary = yield select(fileSummarySelectors.getFileSummaryValue, {
    fileId,
  });

  const fileProducts = get(fileSummary, ['value', 'fileProducts']);

  const modelIds = uniq(fileProducts.map((fileProduct) => fileProduct.modelId));

  yield all([
    yield put(
      ClaimActionCreators.loadClaimsByGroupId(
        fileSummary.value.claimInfoSummary.claimGroupId,
      ),
    ),
    yield take(ClaimActionTypes.LOAD_CLAIMS_BY_GROUP_ID.END),
  ]);

  for (const modelId of modelIds) {
    const wasModelLoaded = yield select(wasEveryActionLoadedSelector, [
      { action: GET_MODEL_BY_ID, indexer: modelId },
    ]);

    if (!wasModelLoaded) {
      yield all([put(getModelById(modelId)), take(GET_MODEL_BY_ID.END)]);
    }
  }

  yield all([
    put(
      WorkflowActionCreators.loadOwnerProductFiles({
        ownerProductIds: fileProducts.map(
          ({ ownerProductId }) => ownerProductId,
        ),
      }),
    ),
    take(WorkflowActionTypes.LOAD_OWNER_PRODUCT_FILES.END),
  ]);

  let allAdditionalInformationEnumValues = [];
  let allAdditionalInformationIds = [];

  for (const { ownerProductId, claimId, orderId } of fileProducts) {
    const ownerProductsFiles = yield select(
      WorkflowSelectors.loadOwnerProductFilesByOwnerProductId,
      { ownerProductId },
    );
    const files = map(
      ownerProductsFiles,
      (file) => find(get(file, ['fileProducts']), { ownerProductId }) || {},
    );

    for (const { issueId, reasonId, warrantyTypeId } of files) {
      if (issueId || reasonId || warrantyTypeId) {
        yield put(
          ClaimActionCreators.loadTranslation({
            issueId,
            reasonId,
            warrantyTypeId,
            ownerProductId,
          }),
        );
      }
    }

    const claim = yield select(getClaimById, { claimId }) || {};

    const detailedOrder = yield select(getDetailedOrderByOrderId, { orderId });

    const { orderProductAdditionalInfo = [] } = detailedOrder?.products?.find(
      byOwnerProductId(ownerProductId),
    ) || { orderProductAdditionalInfo: [] };

    allAdditionalInformationEnumValues = [
      ...allAdditionalInformationIds,
      ...extractAdditionalInformationEnumValues({
        additionalClaimInformation: claim?.additionalClaimInformation,
        files,
        orderProductAdditionalInfo,
      }),
    ];

    allAdditionalInformationIds = [
      ...allAdditionalInformationIds,
      ...extractAdditionalInformationIds({
        additionalClaimInformation: claim?.additionalClaimInformation,
        files,
        orderProductAdditionalInfo,
      }),
    ];
  }

  yield put(
    AdditionalInformationActionCreators.getAdditionalInformationEntitiesSummaries(
      {
        indexer: fileSummary.value.claimInfoSummary.claimGroupId,
        ids: concat(
          allAdditionalInformationEnumValues,
          allAdditionalInformationIds,
        ),
      },
    ),
  );
}

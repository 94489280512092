import React, { FunctionComponent } from 'react';

import { MIME_TYPES } from '@savgroup-front-common/constants/src/shared';
import {
  ImageIcon,
  PdfIcon,
  VideoIcon,
} from '@savgroup-front-common/core/src/protons/icons';

interface DocumentTypeIconProps {
  type?: string;
  size: string;
}

const DocumentTypeIcon: FunctionComponent<
  React.PropsWithChildren<DocumentTypeIconProps>
> = ({ type, size }) => {
  switch (type) {
    case MIME_TYPES.PDF:
      return <PdfIcon size={size} />;
    case MIME_TYPES.JPEG:
    case MIME_TYPES.PNG:
      return <ImageIcon size={size} />;
    case MIME_TYPES.OCTET_STREAM:
      return <VideoIcon size={size} />;

    default:
      return null;
  }
};

export default DocumentTypeIcon;

import { datadogRum } from '@datadog/browser-rum';
import React, { useCallback } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useLocalStorage, useLocation } from 'react-use';

import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import {
  selectLogin,
  selectLoginErrors,
} from '@savgroup-front-common/core/src/domains/login/selectors';
import {
  buildErrorNotification,
  getUniqErrorMessages,
} from '@savgroup-front-common/core/src/helpers';
import { useAppStatuses } from '@savgroup-front-common/core/src/molecules/AppStatuses';
import { LOCAL_STORAGE_KEYS } from '@savgroup-front-common/types';

import { PRODUCT_FRUITS_CONFIG } from '../../../configuration/productFruits';
import { signIn } from '../../../domains/login/actions';
import Layout from '../layout/Layout';

import { useIsCypressRunning } from './CheckAuthorized.hooks';
import LoadingScreen from './LoadingScreen';
import { useOidcContext } from './OidcProvider';

const CheckAuthorized = () => {
  const auth = useSelector((state) => state.auth);
  const login = useSelector(selectLogin);
  const errors = useSelector(selectLoginErrors);
  const locale = useSelector(selectLocale);
  const { pathname, search } = useLocation();
  const invitationId = new URLSearchParams(search).get('invitation_id');
  const invitationToken = new URLSearchParams(search).get('invitation_token');

  const dispatch = useDispatch();

  const handleSignIn = useCallback(() => {
    dispatch(signIn());
  }, [dispatch]);

  const { userManager } = useOidcContext();
  const { isComponentStatusLoaded } = useAppStatuses();

  const isCypressRunning = useIsCypressRunning();

  useDeepCompareEffect(() => {
    if (auth.failed) {
      logVerbose(
        `Failed authentication Control : redirectUrl => ${
          login.isLoggedIn ? '/' : pathname
        }`,
        {
          auth,
        },
      );

      setTimeout(() =>
        userManager.current.signinRedirect({
          extraQueryParams:
            invitationId && invitationToken
              ? {
                  invitation_id: invitationId,
                  invitation_token: invitationToken,
                }
              : {},
          data: {
            redirectUrl: login.isLoggedIn ? '/' : `${pathname}${search}`,
          },
        }),
      );
    } else if (login.failure || errors.length > 0) {
      logVerbose('Failed login signoutRedirect', {
        login,
        auth,
        errors,
      });

      // setTimeout to prevent from infinite loop problem
      setTimeout(() =>
        userManager.current.signoutRedirect({
          // eslint-disable-next-line camelcase
          post_logout_redirect_uri: login.isLoggedIn ? '/' : pathname,
        }),
      );
    } else if (auth.isLoggedIn) {
      handleSignIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, errors, login.failure, login.isLoggedIn]);

  const notifications = getUniqErrorMessages(errors || []).map(
    buildErrorNotification,
  );

  const [isNewUiEnabledFromStorage] = useLocalStorage(
    LOCAL_STORAGE_KEYS.NEW_BO_ENABLED,
    false,
    {
      raw: false,
      serializer: (value) => {
        if (value) {
          return 'true';
        }

        return 'false';
      },
      deserializer: (value) => {
        return value === 'true';
      },
    },
  );

  if (!login.isLoggedIn || !isComponentStatusLoaded) {
    return <LoadingScreen notifications={notifications} />;
  }

  datadogRum.setUser({
    id: login.userId,
    name: `${login.firstname} ${login.lastname}`,
    email: login.mail,
    accesses: login.accesses,
    permissions: login.permissions,
  });

  const userInfo = {
    username: login.mail,
    email: login.mail,
    firstname: login.firstname,
    lastname: login.lastname,
    props: {
      roles: login.roles,
      isNewUi: isNewUiEnabledFromStorage,
    },
  };

  return (
    <>
      <Layout />

      {!isCypressRunning && PRODUCT_FRUITS_CONFIG.workspaceCode && (
        <ProductFruits
          workspaceCode={PRODUCT_FRUITS_CONFIG.workspaceCode}
          language={locale}
          user={userInfo}
        />
      )}
    </>
  );
};

export default CheckAuthorized;

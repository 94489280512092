import { TypedDocumentNode } from '@apollo/client';
import { Client, createClient } from 'graphql-sse';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';

import { createGenericContext } from '../../helpers';
import { getGqlString } from '../../helpers/getGqlString';

export interface GraphQlSseContextArgs {
  graphqlSseClient: Client;
  onSubscribe: (args: {
    subscriptionKey: string;
    query: TypedDocumentNode<any, any>;
    variables?: any;
    onNext: (data: any) => void;
  }) => void;
  onUnsubscribe: (subscriptionKey: string) => void;
}

const [useGraphQlSseContext, CreateGraphQlSseProvider] =
  createGenericContext<GraphQlSseContextArgs>();

const client = createClient({
  url: `${APIConfiguration.graphQlGateway}`,
});

const state: Record<
  string,
  | {
      subscription: () => void;
      callbacks: ((data: any) => void)[];
    }
  | undefined
> = {};

export enum SubscriptionNames {
  OnStateUpdated = 'onStateUpdated',
}

const GraphQlSseContextProvider: FunctionComponent<
  PropsWithChildren<Record<never, never>>
> = ({ children }) => {
  const handleSubscribe = ({
    subscriptionKey,
    query,
    variables = {},
    onNext,
  }: {
    subscriptionKey: string;
    query: TypedDocumentNode<any, any>;
    variables?: any;
    onNext: (data: any) => void;
  }) => {
    const existingSubscription = state[subscriptionKey];

    if (existingSubscription) {
      state[subscriptionKey] = {
        ...existingSubscription,
        callbacks: [...existingSubscription.callbacks, onNext],
      };

      return;
    }

    const finalQuery = getGqlString(query) ?? '';

    const subscription = client.subscribe(
      {
        query: finalQuery,
        variables,
      },
      {
        next: (result: { data: any }) => {
          const existingSubscription = state[subscriptionKey];

          for (const callback of existingSubscription?.callbacks ?? []) {
            callback(result.data);
          }
        },
        error: (error: Error) => {
          logError(error);
        },
        complete: () => {
          return undefined;
        },
      },
    );

    state[subscriptionKey] = { subscription, callbacks: [onNext] };

    return;
  };

  const handleUnsubscribe = (subscriptionKey: string) => {
    const existingSubscription = state[subscriptionKey];

    if (existingSubscription?.subscription) {
      existingSubscription.subscription();

      state[subscriptionKey] = undefined;
    }
  };

  return (
    <CreateGraphQlSseProvider
      value={{
        graphqlSseClient: client,
        onSubscribe: handleSubscribe,
        onUnsubscribe: handleUnsubscribe,
      }}
    >
      {children}
    </CreateGraphQlSseProvider>
  );
};

export { GraphQlSseContextProvider, useGraphQlSseContext };

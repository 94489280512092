import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $Price = styled.span`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: ${({ theme }) => theme.fonts.size.header};
  line-height: 30px;

  @media ${media.maxWidth.lg} {
    font-size: ${({ theme }) => theme.fonts.size.big};
  }
`;

export const $PriceLine = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.megaSmall};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
`;

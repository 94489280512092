import React, { FunctionComponent, MouseEventHandler } from 'react';

import { useIsNewUiEnabled } from '../../hooks/useIsNewUiEnabled';

import { $Card } from './Card.styles';

interface CardProps {
  withoutMarginTop?: boolean;
  withoutMarginBottom?: boolean;
  withoutPaddingBottom?: boolean;
  withoutPaddingTop?: boolean;
  withBorder?: boolean;
  elevation?: number;
  minimal?: boolean;
  isOpen?: boolean;
  colored?: boolean;
  dataTestId?: string;
  title?: string;
  isVerticalyFluid?: boolean;
  isFluid?: boolean;
  className?: string;
  onClick?: MouseEventHandler;
  shouldRenderOnlyChildren?: boolean;
}

const Card: FunctionComponent<React.PropsWithChildren<CardProps>> = ({
  withoutMarginTop = false,
  withoutMarginBottom = false,
  withoutPaddingBottom = false,
  withoutPaddingTop = false,
  withBorder,
  elevation = 1,
  minimal = false,
  colored = false,
  isOpen = true,
  isVerticalyFluid = false,
  dataTestId,
  title,
  children,
  className,
  onClick,
  isFluid = false,
  shouldRenderOnlyChildren = false,
}) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  const defaultWithBorder = isNewUiEnabled;

  if (shouldRenderOnlyChildren) {
    return <>{children}</>;
  }

  return (
    <$Card
      $withoutMarginTop={withoutMarginTop}
      $withoutPaddingBottom={withoutPaddingBottom}
      $withoutPaddingTop={withoutPaddingTop}
      $isVerticalyFluid={isVerticalyFluid}
      $isOpen={isOpen}
      data-testid={dataTestId}
      title={title}
      $withoutMarginBottom={withoutMarginBottom}
      $withBorder={withBorder ?? defaultWithBorder}
      elevation={elevation}
      $minimal={minimal}
      $colored={colored}
      className={className} // styled components
      onClick={onClick}
      $isFluid={isFluid}
      $isNewUi={isNewUiEnabled}
    >
      {children}
    </$Card>
  );
};

export default Card;

import { PlanDto } from '../ChoosePlan.types';

import { ComparePlansDto } from './ComparePlans.types';

export const buildComparePlansDto = ({
  plans,
}: {
  plans: PlanDto[];
}): ComparePlansDto => {
  return plans.reduce((acc, plan) => {
    const sections = plan.features;

    return sections.reduce<ComparePlansDto>((acc, section) => {
      const newSectionStatus = section.items.reduce((acc, feature) => {
        return {
          ...acc,
          [feature.name]: {
            ...(acc[feature.name] ?? {}),
            [plan.name]: feature.localisationKey
              ? {
                  isAvailable: true,
                  localisationKey: feature.localisationKey,
                }
              : {
                  isAvailable: true,
                },
          },
        };
      }, acc[section.uniqueName] ?? {});

      return {
        ...acc,
        [section.uniqueName]: newSectionStatus,
      };
    }, acc);
  }, {});
};

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}adapter/status`;

export enum StandardAdapterType {
  None = 'None',
  ShippingBo = 'ShippingBo',
}

export enum CheckStatus {
  NON_APPLICABLE = 'NonApplicable',
  OK = 'Ok',
  KO = 'Ko',
}

export interface AdapterStatus {
  SearchModels: CheckStatus;
  GetModels: CheckStatus;
  GetOrderByReference: CheckStatus;
  GetOrders: CheckStatus;
  GetCustomer: CheckStatus;
  GetCustomers: CheckStatus;
  CreateCustomer: CheckStatus;
  EditCustomer: CheckStatus;
  CreatePayment: CheckStatus;
  SearchSoldServices: CheckStatus;
  GetSoldServices: CheckStatus;
  SearchSpareParts: CheckStatus;
  GetBilling: CheckStatus;
  GetBillingDocument: CheckStatus;
}

const checkAdapterStatusQuery = async ({
  sellerId,
}: {
  sellerId: string;
}): Promise<
  BackResponse<Record<StandardAdapterType, AdapterStatus>> | BackResponseFailure
> => {
  try {
    const url = buildUrl(ENDPOINT, { sellerId });

    const response = await apiCall<BackResponse<Record<string, AdapterStatus>>>(
      url,
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default checkAdapterStatusQuery;

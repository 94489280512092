import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.workflow}workflows/versionsWithDesigner`;

interface CreateWorkflowVersionWithDesignerFileCommandPayload {
  workflowJsonFile: string;
  savDesignerFile: File;
}

export async function createWorkflowVersionWithDesignerFileCommand({
  workflowJsonFile,
  savDesignerFile,
}: CreateWorkflowVersionWithDesignerFileCommandPayload): Promise<
  BackResponse<string> | BackResponseFailure
> {
  const body = new FormData();

  body.append('file', savDesignerFile);
  body.append('createWorkflowVersionWithDesignerFileCommand', workflowJsonFile);

  try {
    return await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      body,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

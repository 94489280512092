import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}Upload/AvailableImportTypes`;

export enum ImportLevel {
  MASTER = 'Master',
  NOT_SET = 'NotSet',
  TENANT = 'Tenant',
  SELLER = 'Seller',
}

export interface UploadImportTypeDetailsSummary {
  importType: string;
  importLevel: ImportLevel;
  isDeprecated: boolean;
  localizationKey: string;
}
interface GetAvailableImportTypesQueryPayload {
  tenantId: string;
  sellerId?: string;
  withMasterImportType?: boolean;
}

type GetAvailableImportTypesQuery = (
  payload: GetAvailableImportTypesQueryPayload,
) => Promise<
  BackResponse<UploadImportTypeDetailsSummary[]> | BackResponseFailure
>;

export const getAvailableImportTypesQuery: GetAvailableImportTypesQuery =
  async ({ tenantId, sellerId, withMasterImportType }) => {
    try {
      return await apiCall<BackResponse<UploadImportTypeDetailsSummary[]>>(
        buildUrl(ENDPOINT, {
          tenantId,
          sellerId,
          withMasterImportType,
        }),
        {
          method: SUPPORTED_METHODS.GET,
        },
      );
    } catch (exception) {
      return prepareResponseFailure(exception);
    }
  };

import React from 'react';
import { Transition } from 'react-transition-group';

import { BackgroundDimmerNaked } from './BackgroundDimmer.styles';

interface BackgroundDimmerProps {
  isOpen?: boolean;
  animationDuration?: number;
  onClick?: () => void;
  zIndex?: number;
  opacity?: number;
}

export default function BackgroundDimmer({
  isOpen = false,
  animationDuration = 400,
  onClick,
  zIndex = 3,
  opacity = 0.8,
}: BackgroundDimmerProps): JSX.Element {
  return (
    <Transition in={isOpen} timeout={animationDuration} unmountOnExit>
      {(animationState) => (
        <BackgroundDimmerNaked
          animationDuration={animationDuration}
          animationState={animationState}
          onClick={onClick}
          $zIndex={zIndex}
          $opacity={opacity}
        />
      )}
    </Transition>
  );
}

import React, { FC, PropsWithChildren } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { useGetControlTheme } from '@savgroup-front-common/core/src/theme/Theme';

export const ThemeProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const isNewUiEnabled = useIsNewUiEnabled();

  const { isDarkModeEnabled } = useNewUiContext();

  const theme = useGetControlTheme({}, isNewUiEnabled, isDarkModeEnabled);

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

import React, { FunctionComponent, useRef } from 'react';
import Avatar from 'react-avatar';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants/src/shared';
import { selectLogin } from '@savgroup-front-common/core/src/domains/login/selectors';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';
import { UserIcon } from '@savgroup-front-common/core/src/protons/icons';

import { useControlTypedSelector } from '../../../../hooks';

import {
  $MenuVerticalPosition,
  $ProfilButton,
  $ProfilButtonContainer,
  $ProfilButtonNewUi,
} from './HeaderProfile.styles';
import { useDefaultLanguage, useOpenProfile, useUserTracking } from './hooks';
import HeaderProfileMenu from './Menu';

const HeaderProfile: FunctionComponent = () => {
  const theme = useTheme();

  const login = useControlTypedSelector(selectLogin);

  useDefaultLanguage();
  useUserTracking();
  const { isOpen, handleToggleIsOpen, handleClose } = useOpenProfile();

  const wrapperRef = useRef(null);
  const isNewUiEnabled = useIsNewUiEnabled();

  if (isNewUiEnabled) {
    return (
      <$ProfilButtonContainer ref={wrapperRef}>
        <$ProfilButtonNewUi
          onClick={handleToggleIsOpen}
          type={BUTTON_TYPES.BUTTON}
          data-testid="profilButton"
        >
          <Avatar
            name={`${login.firstname} ${login.lastname}`}
            value={login.userId}
            round="5px"
            size="32px"
          />
        </$ProfilButtonNewUi>
        <$MenuVerticalPosition $isNewUiEnabled={isNewUiEnabled}>
          <HeaderProfileMenu
            onClose={handleClose}
            wrapperRef={wrapperRef}
            isOpen={isOpen}
          />
        </$MenuVerticalPosition>
      </$ProfilButtonContainer>
    );
  }

  return (
    <$ProfilButtonContainer ref={wrapperRef}>
      <$ProfilButton
        onClick={handleToggleIsOpen}
        type={BUTTON_TYPES.BUTTON}
        data-testid="profilButton"
      >
        <UserIcon color={theme.colors.white} size="32px" />
      </$ProfilButton>
      <$MenuVerticalPosition $isNewUiEnabled={isNewUiEnabled}>
        <HeaderProfileMenu
          onClose={handleClose}
          wrapperRef={wrapperRef}
          isOpen={isOpen}
        />
      </$MenuVerticalPosition>
    </$ProfilButtonContainer>
  );
};

export default React.memo(HeaderProfile);

import { createSelector } from 'reselect';

import { SELLERS_INFO_DOMAIN } from 'control/domains/sellersInfo/constants';

import { ControlRootState } from '../ControlRootState';

const sellersInfoState = (state: ControlRootState) => state.sellersInfo;

export const selectAllSellers = createSelector(
  [sellersInfoState],
  (sellersInfo) => sellersInfo[SELLERS_INFO_DOMAIN.ALL_SELLERS],
);

export const selectSellerInfoById = createSelector(
  [
    selectAllSellers,
    (
      _: ControlRootState,
      { sellerId, tenantId }: { tenantId: string; sellerId: string },
    ) => ({ sellerId, tenantId }),
  ],
  (allSellers, { sellerId, tenantId }) => {
    const tenantsInfo = allSellers?.value || [];

    const tenantInfo = tenantsInfo.find(
      (tenantInfo) => tenantInfo.id === tenantId,
    );

    if (!tenantInfo) {
      return undefined;
    }

    return (tenantInfo.sellers || []).find(
      (sellerInfo) => sellerInfo.id === sellerId,
    );
  },
);

export const selectTenantInfoById = createSelector(
  [
    selectAllSellers,
    (_: ControlRootState, { sellerId }: { sellerId?: string }) => ({
      sellerId,
    }),
  ],
  (allSellers, { sellerId }) => {
    const tenantsInfo = allSellers?.value || [];

    if (!sellerId) {
      return undefined;
    }

    return tenantsInfo.find((tenantInfo) => tenantInfo.id === sellerId);
  },
);

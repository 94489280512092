import { MessageDescriptor } from 'react-intl';

import { intl } from '../helpers/intl';

export const safeFormattedIntlStringWithId = (
  id?: string,
  values?: Record<string, string>,
): string => {
  if (!id) {
    return '';
  }

  const message = { id, defaultMessage: id };

  return intl?.formatMessage(message as MessageDescriptor, values) as string;
};

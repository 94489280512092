import update from 'immutability-helper';

import { workFlowFormatter } from '@savgroup-front-common/core/src/formatters';
import { createReducer } from '@savgroup-front-common/core/src/helpers';

import * as WorkflowConst from './actionTypes';

function getInitialState() {
  return Object.freeze({
    isStateUpdatePending: false,
    isLoaded: false,
    isDirty: false,
    currentState: {},
    id: null,
    workflowStates: [],
    carrierLabels: [],
  });
}

export const initialState = getInitialState();

function onGetWorkflowState(state, payload) {
  const { id } = payload;
  const isLoaded = !!(state.id === id && state.id);

  return {
    ...state,
    isDirty: isLoaded,
    isLoaded,
    id,
  };
}

function onGetWorkflowStateCallback(state, payload) {
  const { workflowStates, carrierLabels } = payload;
  const mappedWorkflowStates = workflowStates.map(
    ({ priority, module, transitions, currentState, stateDate, fileId }) => ({
      status: WorkflowConst.TODO_PROGRESS_STATUS.IN_PROGRESS,
      fileId,
      priority,
      module: {
        id: module.id,
        definitionId: module.definitionId,
        wave: module.wave,
      },
      stateDate,
      currentState: {
        name: currentState.name,
        shortLabel: currentState.shortLabel,
        label: currentState.label,
      },
      transitions: workFlowFormatter.mapTransitions(transitions),
    }),
  );

  return {
    ...state,
    isDirty: false,
    isLoaded: true,
    workflowStates: mappedWorkflowStates,
    carrierLabels,
  };
}

function onCarrierLabelsLoaded(state, payload) {
  const { carrierLabels } = payload;

  return {
    ...state,
    carrierLabels,
  };
}

function onUpdateTodoProgressStatus(state, { itemIndex, status }) {
  const { workflowStates } = state;
  const workingStates = workflowStates;

  workingStates[itemIndex].status = status;

  const newState = {
    ...state,
    workflowStates: workingStates,
  };

  return newState;
}

function onSetCurrentState(state, { label, shortLabel, name }) {
  return {
    ...state,
    currentState: {
      label,
      shortLabel,
      name,
    },
  };
}

function onEraseTodoAction(state, { itemIndex }) {
  return update(state, { workflowStates: { $splice: [[itemIndex, 1]] } });
}

function handleOnWorkflowStatePending(state) {
  return update(state, { isStateUpdatePending: { $set: true } });
}

function handleOnWorkflowStateSuccessful(state) {
  return update(state, { isStateUpdatePending: { $set: false } });
}

function handleOnWorkflowStateReset(state) {
  return update(state, { isStateUpdatePending: { $set: false } });
}

const reducer = createReducer(initialState, {
  [WorkflowConst.GET_WORKFLOW_STATE]: onGetWorkflowState,
  [WorkflowConst.GET_WORKFLOW_STATE_CALLBACK]: onGetWorkflowStateCallback,
  [WorkflowConst.GET_CARRIER_LABELS_FOR_FILE]: onCarrierLabelsLoaded,
  [WorkflowConst.SET_CURRENT_STATE]: onSetCurrentState,
  [WorkflowConst.UPDATE_PROGRESS_STATUS]: onUpdateTodoProgressStatus,
  [WorkflowConst.ERASE_TODO_ACTION]: onEraseTodoAction,
  [WorkflowConst.UPDATE_WORKFLOW_STATE_STARTED]: handleOnWorkflowStatePending,
  [WorkflowConst.UPDATE_WORKFLOW_STATE_PENDING]: handleOnWorkflowStatePending,
  [WorkflowConst.UPDATE_WORKFLOW_STATE_SUCCESSFUL]:
    handleOnWorkflowStateSuccessful,
  [WorkflowConst.UPDATE_WORKFLOW_STATE_ERRORED]:
    handleOnWorkflowStateSuccessful,
  [WorkflowConst.UPDATE_WORKFLOW_STATE_RESET]: handleOnWorkflowStateReset,
});

export default reducer;

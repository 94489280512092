import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

export const getAvailableCountriesQuery = async (): Promise<
  BackResponseFailure | BackResponse<string[]>
> => {
  try {
    return await apiCall<BackResponse<string[]>>(
      buildUrl(`${APIConfiguration.seller}sellers/countries`),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

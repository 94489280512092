import React, { FC } from 'react';

import { Didactic } from '@savgroup-front-common/core/src/atoms/Didactic';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import {
  $AvailablePlanContainer,
  $ChoosePricingContainer,
  $ChoosePricingHeader,
  $EmptyColumn,
} from './ChoosePlan.styles';
import { PlanDto } from './ChoosePlan.types';
import { ComparePlans } from './ComparePlans/ComparePlans';
import messages from './messages';
import { PlanPreview } from './PlanPreview/PlanPreview';
import plans from './plans.json';

export const ChoosePlan: FC = () => {
  const availablePlans = plans as PlanDto[];

  return (
    <$ChoosePricingContainer>
      <$ChoosePricingHeader>
        <Heading level={2}>
          <SafeFormattedMessageWithoutSpread message={messages.title} />
        </Heading>
        <Didactic>
          <SafeFormattedMessageWithoutSpread message={messages.didactic} />
        </Didactic>
      </$ChoosePricingHeader>

      <$AvailablePlanContainer>
        <$EmptyColumn />

        {availablePlans.map((availablePlan) => {
          return <PlanPreview key={availablePlan.id} plan={availablePlan} />;
        })}

        <ComparePlans plans={availablePlans} />
      </$AvailablePlanContainer>
    </$ChoosePricingContainer>
  );
};

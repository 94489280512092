import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-use';

import { useGetAdditionalInformationSummary } from '@savgroup-front-common/core/src/api/Claim/hooks/useGetAdditionalInformationSummary';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';

import { AdditionalInformationQrcodeUpload } from './AdditionalInformationQrcodeUpload/AdditionalInformationQrcodeUpload';
import { FileQrcodeUpload } from './FileQrcodeUpload/FileQrcodeUpload';
import { MultiAdditionalInformationQrcodeUpload } from './MultiAdditionalInformationQrcodeUpload/MultiAdditionalInformationQrcodeUpload';

const QrcodeUpload: FunctionComponent = () => {
  const { search } = useLocation();
  const { token } = useParams<{
    token: string;
  }>();

  const additionalInformationId = new URLSearchParams(search).get(
    'additionalInformationId',
  );
  const { additionalInformationSummary, isLoading } =
    useGetAdditionalInformationSummary({ additionalInformationId, token });

  const claimId = new URLSearchParams(search).get('claimId');
  const sellerId = new URLSearchParams(search).get('sellerId');

  if (isLoading) {
    return <BaseLoader />;
  }

  if (
    additionalInformationId &&
    claimId &&
    sellerId &&
    additionalInformationSummary?.additionalInformationEntity
      ?.informationType === ADDITIONAL_INFORMATION_TYPES.FILE
  ) {
    return (
      <AdditionalInformationQrcodeUpload
        additionalInformationId={additionalInformationId}
        claimId={claimId}
        sellerId={sellerId}
      />
    );
  }

  if (
    additionalInformationId &&
    claimId &&
    sellerId &&
    additionalInformationSummary?.additionalInformationEntity
      ?.informationType === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES
  ) {
    return (
      <MultiAdditionalInformationQrcodeUpload
        additionalInformationId={additionalInformationId}
        claimId={claimId}
        sellerId={sellerId}
      />
    );
  }

  return <FileQrcodeUpload />;
};

export default QrcodeUpload;

import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { DocumentService, WorkflowService } from 'control/api';

interface UseUploadFileArgs {
  fileId: string;
  token?: string;
}

const useUploadFile = ({ fileId, token }: UseUploadFileArgs) => {
  const [importedDocumentInErrorCount, setImportedDocumentInErrorCount] =
    useState(0);
  const [importedDocumentInSuccessCount, setImportedDocumentInSuccessCount] =
    useState(0);
  const {
    mutateAsync: handleUploadDocuments,
    isLoading: isUploadDocumentsLoading,
  } = useMutation(['uploadFileByQrCode'], async (files: File[]) => {
    if (!token) {
      return;
    }

    const filesCount = files.length;
    const documentResponse =
      await DocumentService.uploadFileAttachmentsFromControlCommand({
        fileId,
        token,
        files,
      });

    if (documentResponse.failure) {
      setImportedDocumentInErrorCount(filesCount);

      return;
    }
    const workflowResponse =
      await WorkflowService.createFileAttachmentsFromControlCommand({
        fileId,
        token,
        fileAttachmentIds: documentResponse.value,
      });

    if (workflowResponse.failure) {
      setImportedDocumentInErrorCount(filesCount);

      return;
    }
    setImportedDocumentInSuccessCount(filesCount);
  });

  useEffect(() => {
    const id = setTimeout(() => {
      setImportedDocumentInErrorCount(0);
      setImportedDocumentInSuccessCount(0);
    }, 2000);

    return () => {
      clearTimeout(id);
    };
  }, [importedDocumentInErrorCount, importedDocumentInSuccessCount]);

  return {
    handleUploadDocuments,
    isUploadDocumentsLoading,
    importedDocumentInErrorCount,
    importedDocumentInSuccessCount,
  };
};

export default useUploadFile;

import { defineMessages } from 'react-intl';

export default defineMessages({
  chooseThisPlan: {
    id: 'view.choosePricing.planPreview.chooseThisPlan',
    defaultMessage: 'Choose this plan',
  },
  contactSales: {
    id: 'view.choosePricing.planPreview.contactSales',
    defaultMessage: 'Contact sales',
  },
});

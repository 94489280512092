import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
  PRICING_PLAN,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/pushToProduction`;

export type PushToProductionCommandPayload = {
  sellerId: string;
  pricingPlan: PRICING_PLAN;
};

export const pushToProductionCommand = async ({
  sellerId,
  pricingPlan,
}: PushToProductionCommandPayload): Promise<
  BackResponseFailure | BaseBackResponse
> => {
  try {
    return await apiCall<BaseBackResponse>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId)),
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          pricingPlan,
        },
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

import { createGenericContext } from '@savgroup-front-common/core/src/helpers';
import { FileSummary } from '@savgroup-front-common/types';
import { TABS_FILE } from 'control/constants/routing';

import { getFileByFileId } from '../../../domains/fileInfo/fileDetails';
import { useControlTypedSelector } from '../../../hooks';

import { GraphQlFileSummary } from './FileInfoNewUi/FileInfoNewUi.types';

export interface FileInfoContextArgs {
  buildUrl: (arg?: { fileId: string; fileTabId?: TABS_FILE }) => string;
  fileId: string;
  file: FileSummary;
  fileSummary?: GraphQlFileSummary;
  handleRefetchFullSummary: () => Promise<void>;
}

const [useFileInfoContext, FileInfoProvider] =
  createGenericContext<FileInfoContextArgs>();

interface FileInfoContextProviderProps {
  buildUrl: (arg?: { fileId: string; fileTabId?: TABS_FILE }) => string;
  fileSummary?: GraphQlFileSummary;
  onRefetchFullSummary?: () => Promise<void>;
}

const FileInfoContextProvider: FunctionComponent<
  React.PropsWithChildren<FileInfoContextProviderProps>
> = ({ children, buildUrl, onRefetchFullSummary, fileSummary }) => {
  const { fileId } = useParams();

  const file = useControlTypedSelector((state) =>
    getFileByFileId(state, { fileId }),
  );

  if (!file || !fileId) {
    return <></>;
  }

  return (
    <FileInfoProvider
      value={{
        buildUrl,
        fileId,
        file,
        fileSummary,
        handleRefetchFullSummary:
          onRefetchFullSummary ?? (() => Promise.resolve()),
      }}
    >
      {children}
    </FileInfoProvider>
  );
};

export { FileInfoContextProvider, useFileInfoContext };

import { PERMISSIONS } from '@savgroup-front-common/constants';
import {
  useHasPermission,
  useHasSomePermissions,
} from '@savgroup-front-common/core/src/hooks';

const useHasSellerAccountPermission = (): boolean => {
  const hasPermission = useHasSomePermissions([
    PERMISSIONS.SELLER_CONFIGURATION,
    PERMISSIONS.SELLER_MANAGEMENT,
  ]);

  const hasWriteSellerAccountPermission = useHasPermission(
    PERMISSIONS.WRITE_SELLER_ACCOUNT,
  );

  return hasPermission || hasWriteSellerAccountPermission;
};

export default useHasSellerAccountPermission;

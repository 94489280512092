import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { showMessageId } from '../helpers/intl';

 
export const SafeFormattedMessageWithId: FC<{ id?: string }> = ({ id }) => {
  if (!id) {
    return <></>;
  }
  const el = React.createElement(FormattedMessage, {
    ...{ id },
  });
  const span = React.createElement('span', { title: id }, el);

  if (showMessageId) {
    return span;
  }

  return el;
};

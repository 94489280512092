import { matchPath } from 'react-router-dom';

import { MENU_CONF } from './SidBarMenu.constants';
import { MenuConf, MenuItem } from './SidBarMenu.types';

export function transformToMenu(
  menuConfiguration: MenuConf[],
  pathname: string,
): MenuItem[] {
  const menus = menuConfiguration.reduce<MenuItem[]>(
    (
      acc,
      {
        nav,
        count = 0,
        permission = true,
        submenus = [],
        expandIfAlone = false,
        forceIconIfAlone = false,
        isExternal = false,
      },
    ) => {
      if (!permission) {
        return acc;
      }

      const adaptedSubMenu = submenus.length
        ? transformToMenu(submenus, pathname)
        : [];

      const menu = MENU_CONF[nav];

      const match = matchPath(menu.url, pathname);

      const transformedMenu =
        adaptedSubMenu.length === 1
          ? {
              ...adaptedSubMenu[0],
              expandIfAlone,
              isExternal,
              icon: forceIconIfAlone ? menu.icon : adaptedSubMenu[0].icon,
            }
          : {
              expandIfAlone,
              isExternal,
              submenus: adaptedSubMenu,
              to: adaptedSubMenu.length > 0 ? adaptedSubMenu[0].to : menu.url,
              isActive:
                !!match || adaptedSubMenu.some(({ isActive }) => isActive),
              message: menu.message,
              icon: menu.icon,
              subCount: adaptedSubMenu.reduce(
                (acc, { count }) => count + acc,
                0,
              ),
              count,
              dataTestId: nav,
            };

      return [...acc, transformedMenu] as MenuItem[];
    },
    [],
  );

  const smartMenuCount = menus.filter(
    ({ expandIfAlone }) => expandIfAlone,
  ).length;

  return menus.reduce<MenuItem[]>((acc, menu) => {
    if (menu.expandIfAlone && smartMenuCount === 1) {
      return [...acc, ...menu.submenus];
    }

    return [...acc, menu];
  }, []);
}

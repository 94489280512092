import React, { FC, useCallback, useState } from 'react';

import { createGenericContext } from '@savgroup-front-common/core/src/helpers';

export interface ChoosePricingContextArgs {
  isOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const [useChoosePlanContext, ChoosePlanContextProvider] =
  createGenericContext<ChoosePricingContextArgs>();

interface ChoosePlanDrawerProviderProps {
  isInitialOpen?: boolean;
}

const ChoosePlanProviderContainer: FC<
  React.PropsWithChildren<ChoosePlanDrawerProviderProps>
> = ({ children, isInitialOpen = false }) => {
  const [isOpen, setIsOpen] = useState(isInitialOpen);

  const handleDrawerOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ChoosePlanContextProvider
      value={{
        isOpen,
        handleDrawerOpen,
        handleDrawerClose,
      }}
    >
      {children}
    </ChoosePlanContextProvider>
  );
};

export { ChoosePlanProviderContainer, useChoosePlanContext };

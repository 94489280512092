import React from 'react';

import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import { GlobalStyle } from '@savgroup-front-common/core/src/helpers';
import { useIsNewUiEnabled } from '@savgroup-front-common/core/src/hooks/useIsNewUiEnabled';

const GlobalStyleManager = () => {
  const isNewUiEnabled = useIsNewUiEnabled();
  const { isDarkModeEnabled } = useNewUiContext();

  return (
    <GlobalStyle
      control
      isNewUiEnabled={isNewUiEnabled}
      $isDarkModeEnabled={isDarkModeEnabled}
    />
  );
};

export default GlobalStyleManager;

import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants/src/shared';
import downloadAttachment from '@savgroup-front-common/core/src/api/Attachment/downloadAttachment';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { BackResponseFailure } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}download/${COMMON_QUERY_PARAMS.SELLER_ID}/${COMMON_QUERY_PARAMS.IMPORT_TYPE}`;

interface GetLatestVersionDownloadableQueryPayload {
  sellerId: string;
  importType: string;
}

type GetLatestVersionDownloadableQuery = (
  payload: GetLatestVersionDownloadableQueryPayload,
) => Promise<File | BackResponseFailure>;

export const getLatestVersionDownloadableQuery: GetLatestVersionDownloadableQuery =
  async ({ sellerId, importType }) => {
    try {
      return await downloadAttachment({
        endpoint: buildUrl(
          ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId).replace(
            COMMON_QUERY_PARAMS.IMPORT_TYPE,
            importType,
          ),
        ),
      });
    } catch (exception) {
      return prepareResponseFailure(exception);
    }
  };

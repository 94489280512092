import React, { ChangeEvent, FC, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';

import {
  ALLOWED_MIME_TYPES,
  BUTTON_TYPES,
  IMAGE_DOCUMENT_TYPES,
  MIME_TYPES,
} from '@savgroup-front-common/constants';
import { useGetAdditionalInformationSummary } from '@savgroup-front-common/core/src/api/Claim/hooks/useGetAdditionalInformationSummary';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { HTMLFileInputElement } from '@savgroup-front-common/types';

import {
  $CardContent,
  $CardPosition,
  $HiddenInput,
  $ImportMessage,
  $LogoPosition,
  $QrcodeUploadFileInputButton,
  $QrcodeUploadFileInputContainer,
} from '../QrcodeUpload.styles';

import { useLogo } from './AdditionalInformationQrcodeUpload.hooks';
import { $LogoImg } from './AdditionalInformationQrcodeUpload.styles';
import { useAdditionalInformationUploadFile } from './hooks/useAdditionalInformationUploadFile';
import messages from './messages';

interface AdditionalInformationQrcodeUploadProps {
  additionalInformationId: string;
  claimId: string;
  sellerId: string;
}

export const AdditionalInformationQrcodeUpload: FC<
  AdditionalInformationQrcodeUploadProps
> = ({ additionalInformationId, claimId, sellerId }) => {
  const { token } = useParams<{
    token: string;
  }>();

  const { search } = useLocation();

  const fileId = new URLSearchParams(search).get('fileId') as string;
  const fileReference = new URLSearchParams(search).get(
    'fileReference',
  ) as string;

  const fileUpload = useRef<HTMLInputElement>(null);

  const handleClickInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (fileUpload.current) {
      fileUpload.current.click();
    }
    event.preventDefault();
  };
  const { handleUploadDocuments, isUploadDocumentsLoading } =
    useAdditionalInformationUploadFile({
      fileId,
      additionalInformationId,
      claimId,
      token,
    });

  const { imgRef, logoUrl, onError } = useLogo({
    sellerId,
  });

  const { additionalInformationSummary } = useGetAdditionalInformationSummary({
    additionalInformationId,
    token,
  });

  const allowedMimeTypes = Object.values(IMAGE_DOCUMENT_TYPES).includes(
    additionalInformationSummary?.additionalInformationEntity
      ?.informationType ?? '',
  )
    ? [MIME_TYPES.JPEG, MIME_TYPES.PNG, MIME_TYPES.IMAGE_HEIC, MIME_TYPES.PDF]
    : ALLOWED_MIME_TYPES;

  return (
    <$CardPosition>
      <$CardContent>
        <$LogoPosition>
          <$LogoImg ref={imgRef} alt="logo" src={logoUrl} onError={onError} />
        </$LogoPosition>
        <$ImportMessage>
          <SafeFormattedMessageWithoutSpread message={messages.importMessage} />
        </$ImportMessage>
        <$ImportMessage>{fileReference}</$ImportMessage>
        <$QrcodeUploadFileInputContainer>
          <$QrcodeUploadFileInputButton
            primary
            type={BUTTON_TYPES.BUTTON}
            onClick={handleClickInput}
            isLoading={isUploadDocumentsLoading}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.qrcodeUploadButton}
            />
          </$QrcodeUploadFileInputButton>

          <$HiddenInput
            multiple
            type="file"
            accept={allowedMimeTypes.join(',')}
            ref={fileUpload}
            onChange={(e: ChangeEvent<HTMLFileInputElement>) => {
              const files: File[] = Array.from(e?.target?.files);

              if (files.length < 1) {
                return;
              }
              handleUploadDocuments(files);
              e.target.value = '';
            }}
          />
        </$QrcodeUploadFileInputContainer>
      </$CardContent>
    </$CardPosition>
  );
};

AdditionalInformationQrcodeUpload.displayName =
  'AdditionalInformationQrcodeUpload';

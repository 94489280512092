import { SORT_TYPES } from '@savgroup-front-common/constants/src/shared/sort';
import { RESULT_TYPE } from '@savgroup-front-common/types';
import { GET_ORDERS_BY_QUERY_CONTEXT } from 'control/api/Owner/order/getOrdersByQuery';

import { EmptyResponse, SearchResultGroup } from '../Search.types';

import searchFiles from './searchFiles';
import searchOrders from './searchOrders';
import searchSparePartRequestLines from './searchSparePartRequestLine';
import searchStockItems from './searchStockItems';

export const emptyResult = {
  order: {
    value: [],
    totalCount: 0,
  },
  file: {
    value: [],
    totalCount: 0,
  },
  sparePartRequest: {
    value: [],
    totalCount: 0,
  },
  stockItem: {
    value: [],
    totalCount: 0,
  },
};

const searchResults = async ({
  query,
  page,
  pageSize,
  focusedResultType,
  searchPermissions,
  enabledResultTypes,
  sellerId,
  context,
}: {
  focusedResultType?: RESULT_TYPE;
  query: string;
  page: number;
  pageSize?: number;
  searchPermissions: RESULT_TYPE[];
  enabledResultTypes: RESULT_TYPE[];
  sellerId?: string;
  context?: GET_ORDERS_BY_QUERY_CONTEXT;
}): Promise<SearchResultGroup | EmptyResponse> => {
  const isTypeActive = (type: RESULT_TYPE) => {
    return (
      searchPermissions.includes(type) &&
      enabledResultTypes.includes(type) &&
      (focusedResultType === undefined || focusedResultType === type)
    );
  };

  if (query.length === 0) {
    return emptyResult;
  }

  const sort = SORT_TYPES.LAST_STATE_MODIFICATION;
  const isDescending = true;

  const filesRequest = searchFiles({
    shouldLoad: isTypeActive(RESULT_TYPE.FILE),
    query,
    sort,
    isDescending,
    page,
    pageSize,
  });

  const ordersRequest = searchOrders({
    shouldLoad: isTypeActive(RESULT_TYPE.ORDER),
    page,
    pageSize,
    query,
    sort,
    isDescending,
    sellerId,
    context,
  });

  const sparePartRequestLinesRequest = searchSparePartRequestLines({
    shouldLoad: isTypeActive(RESULT_TYPE.SPARE_PART_REQUEST),
    page,
    pageSize,
    query,
    isDescending,
  });

  const stockItemsRequest = searchStockItems({
    shouldLoad: isTypeActive(RESULT_TYPE.STOCK_ITEM),
    page,
    pageSize,
    query,
    isDescending,
  });

  const [
    filesResponse,
    ordersResponse,
    sparePartRequestLinesResponse,
    stockItemsResponse,
  ] = await Promise.all([
    filesRequest,
    ordersRequest,
    sparePartRequestLinesRequest,
    stockItemsRequest,
  ])
    .then((data) => data)
    .catch((err) => err);

  return {
    file: filesResponse,
    order: ordersResponse,
    sparePartRequest: sparePartRequestLinesResponse,
    stockItem: stockItemsResponse,
  };
};

export default searchResults;

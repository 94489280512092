import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $PlanPreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(40)};
  & h4 {
    white-space: nowrap;
  }
`;

export const $PreviewFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const $PreviewFeatureHeading = styled.div`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  margin-bottom: 1rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::first-letter {
    text-transform: capitalize;
  }
`;

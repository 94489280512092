import React, { FunctionComponent } from 'react';
import { useMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import {
  $MainLink,
  $MenuCountBadge,
  $MenuIconContainer,
  $MenuItemTitle,
} from '../SidBarMenu.styles';

interface MenuLinkProps {
  to: string;
  message: MessageType;
  count: number;
  dataTestId: string;
  icon: FunctionComponent<
    React.PropsWithChildren<{
      color: string;
      size: string;
    }>
  >;
}
const MenuLink: FunctionComponent<React.PropsWithChildren<MenuLinkProps>> = ({
  to,
  message,
  count,
  icon: Icon,
  dataTestId,
}) => {
  const theme = useTheme();

  const isActive = useMatch(`${to}/*`);

  return (
    <$MainLink
      to={to}
      title={safeFormattedIntlString(message)}
      data-testid={dataTestId}
    >
      <$MenuIconContainer>
        <$MenuCountBadge $count={count}>
          {count > 99 ? '99+' : count}
        </$MenuCountBadge>
        <Icon color={isActive ? theme.colors.primary : '#ccc'} size="24px" />
      </$MenuIconContainer>
      <$MenuItemTitle>
        <SafeFormattedMessageWithoutSpread message={message} />
      </$MenuItemTitle>
    </$MainLink>
  );
};

export default React.memo(MenuLink);

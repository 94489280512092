import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import { SafeFormattedMessageWithId } from '@savgroup-front-common/core/src/formatters/SafeFormattedMessageWithId';
import { CheckIcon } from '@savgroup-front-common/core/src/protons/icons';

import { PreviewFeatureDto } from '../../ChoosePlan.types';

import { $PreviewFeature } from './PreviewFeature.styles';

interface PreviewFeaturePros {
  planName: string;
  previewFeature: PreviewFeatureDto;
}

export const PreviewFeature: FC<PreviewFeaturePros> = ({
  planName,
  previewFeature,
}) => {
  const theme = useTheme();

  return (
    <$PreviewFeature>
      <div>
        <CheckIcon color={theme.colors.primary} />
      </div>

      <div>
        <SafeFormattedMessageWithId
          id={`pricing.${planName}.previewFeature.${previewFeature.name}`}
        />
      </div>
    </$PreviewFeature>
  );
};

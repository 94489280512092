import 'array-flat-polyfill';
import 'polyfill-object.fromentries';
import React, { Suspense } from 'react';
import { ConfigProvider as ReactAvatarConfigProvider } from 'react-avatar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { REACT_AVATAR_DEFAULT_COLORS } from '@savgroup-front-common/constants/src/control';
import { ToastContainer } from '@savgroup-front-common/core/src/atoms/Toast';
import { ConfirmationContextProvider } from '@savgroup-front-common/core/src/components/Confirmation/ConfirmationContextProvider';
import { GraphQlSseContextProvider } from '@savgroup-front-common/core/src/components/GraphqlSseContext/GraphqlSseContext';
import { HotjarProvider } from '@savgroup-front-common/core/src/components/HotjarProvider';
import IntlManager from '@savgroup-front-common/core/src/components/IntlManager/IntlManager';
import { NewUiProvider } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import AppStatusesProvider from '@savgroup-front-common/core/src/molecules/AppStatuses';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import {
  ToastsConsumer,
  ToastsProvider,
} from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ExternalPortal } from '@savgroup-front-common/core/src/protons/ExternalPortal/ExternalPortal';
import { KeyboardLayoutProvider } from '@savgroup-front-common/core/src/protons/KeyboardLayoutProvider';
import HOTJAR_CONFIG from 'control/configuration/hotjar';

import { logCritical } from '../../configuration';
import QrcodeUploadPage from '../pages/QrcodeUpload';

import { store } from './App.config';
import { AppContextProvider } from './App.context';
import DebugLayout from './DebugLayout';
import GlobalStyleManager from './GlobalStyleManager';
import { useInitGoogleMap } from './hooks';
import CheckAuthorized from './login/CheckAuthorized';
import GraphqlProvider from './login/GaphqlProvider';
import OAuthServerCallback from './login/OAuthServerCallback';
import OidcProvider from './login/OidcProvider';
import { ThemeProvider } from './ThemeProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = ({ userManager }) => {
  useInitGoogleMap();

  try {
    return (
      <NewUiProvider>
        <HotjarProvider config={HOTJAR_CONFIG}>
          <ToastsProvider>
            <ReduxProvider store={store}>
              <KeyboardLayoutProvider>
                <OidcProvider store={store} userManager={userManager}>
                  <ThemeProvider>
                    <ReactAvatarConfigProvider
                      colors={REACT_AVATAR_DEFAULT_COLORS}
                    >
                      <IntlManager>
                        <GraphQlSseContextProvider>
                          <GraphqlProvider>
                            <ConfirmationContextProvider>
                              <GlobalStyleManager />
                              <Suspense fallback={<BaseLoader />}>
                                <QueryClientProvider client={queryClient}>
                                  <ReactQueryDevtools initialIsOpen={false} />
                                  <AppStatusesProvider componentName="BackOffice">
                                    <ExternalPortal>
                                      <ToastContainer topOffset="6rem" />
                                    </ExternalPortal>

                                    <ToastsConsumer />
                                    <AppContextProvider>
                                      <BrowserRouter
                                        future={{ v7_startTransition: true }}
                                      >
                                        <Routes>
                                          <Route
                                            path="/*"
                                            element={<DebugLayout />}
                                          >
                                            <Route
                                              path="callback"
                                              element={<OAuthServerCallback />}
                                            />

                                            <Route
                                              path="qrcodeUpload/:token"
                                              element={<QrcodeUploadPage />}
                                            />

                                            <Route
                                              path="*"
                                              element={<CheckAuthorized />}
                                            />
                                          </Route>
                                        </Routes>
                                      </BrowserRouter>
                                    </AppContextProvider>
                                  </AppStatusesProvider>
                                </QueryClientProvider>
                              </Suspense>
                            </ConfirmationContextProvider>
                          </GraphqlProvider>
                        </GraphQlSseContextProvider>
                      </IntlManager>
                    </ReactAvatarConfigProvider>
                  </ThemeProvider>
                </OidcProvider>
              </KeyboardLayoutProvider>
            </ReduxProvider>
          </ToastsProvider>
        </HotjarProvider>
      </NewUiProvider>
    );
  } catch (err) {
    logCritical(err);
    throw err;
  }
};

export default App;

import difference from 'lodash/difference';
import find from 'lodash/find';
import { matchPath } from 'react-router-dom';
import { all, cancel, fork, select, takeEvery } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ActionTypes } from '@savgroup-front-common/core/src/domains/i18n';
import { LOCATION_CHANGE } from '@savgroup-front-common/core/src/domains/router/actionCreators';
import { pathname as pathnameSelector } from '@savgroup-front-common/core/src/domains/selectors';

import { FILE_ROUTES, ROUTES } from '../constants/routing';

import folderClient from './fileInfo/fileTabs/client';
import folderProduct from './fileInfo/fileTabs/product';
import folderSummary from './fileInfo/fileTabs/summary';
import fileInfo from './fileInfo/saga';
import marketplaceAggregator from './marketplaceTickets/marketplaceAggregator/saga';
import orders from './orders/ordersSaga';

const viewSagas = {
  [ROUTES.TODOS_WITH_PARAMS]: fileInfo,
  [ROUTES.ALERTS_WITH_PARAMS]: fileInfo,
  [ROUTES.FILE_DETAILS]: fileInfo,

  [FILE_ROUTES.CLIENT]: folderClient,
  [FILE_ROUTES.SUMMARY]: folderSummary,
  [FILE_ROUTES.PRODUCT]: folderProduct,

  [FILE_ROUTES.TODO_CLIENT]: folderClient,
  [FILE_ROUTES.TODO_SUMMARY]: folderSummary,
  [FILE_ROUTES.TODO_PRODUCT]: folderProduct,

  [FILE_ROUTES.ALERT_CLIENT]: folderClient,
  [FILE_ROUTES.ALERT_SUMMARY]: folderSummary,
  [FILE_ROUTES.ALERT_PRODUCT]: folderProduct,

  [ROUTES.MESSAGES]: marketplaceAggregator,
  [ROUTES.ORDERS]: orders,
};

const tasks = {};

function* viewSaga(viewSagaKey) {
  const saga = viewSagas[viewSagaKey];

  if (tasks[viewSagaKey]) {
    yield cancel(tasks[viewSagaKey]);
    tasks[viewSagaKey] = null;
  }

  if (saga) {
    tasks[viewSagaKey] = yield fork(saga, viewSagaKey);
  }
}

let currentPage = {
  pathname: '',
};

function findPagename(pathname) {
  return find(Object.keys(viewSagas), (route) => !!matchPath(route, pathname));
}

function trackPageAndChangeLocation({ pathname }) {
  if (currentPage.pathname !== pathname && pathname.length > 0) {
    currentPage = {
      pathname,
      pagename: findPagename(pathname) || ROUTES.PAGE_NOT_FOUND,
    };
  }

  return currentPage;
}

function* locationChangeWorker({ payload }) {
  const defaultPathname = yield select(pathnameSelector);

  const pathname = payload?.pathname ?? defaultPathname;

  const currentPage = trackPageAndChangeLocation({ pathname });

  if (currentPage.pathname.length === 0) {
    return;
  }

  const viewSagaKeys = Object.keys(viewSagas).filter(
    (route) => !!matchPath(route, pathname),
  );

  yield all(
    difference(Object.keys(tasks), viewSagaKeys).map((viewSagaKey) => {
      const taskToCancel = tasks[viewSagaKey];

      if (taskToCancel) {
        tasks[viewSagaKey] = null;

        return cancel(taskToCancel);
      }

      return undefined;
    }),
  );
  yield all(viewSagaKeys.map((viewSagaKey) => viewSaga(viewSagaKey)));
}

function* locationChangeWatcher() {
  yield takeEvery(LOCATION_CHANGE.BASE, locationChangeWorker);
}
function* i18nChangeWatcher() {
  yield takeEvery(
    ActionTypes.CHANGE_LOCALE_LANGUAGE.BASE,
    locationChangeWorker,
  );
}

export default function* locationSaga() {
  try {
    yield all([locationChangeWatcher(), i18nChangeWatcher()]);
  } catch (error) {
    logCritical(error);
  }
}

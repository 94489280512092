import get from 'lodash/get';
import head from 'lodash/head';
import trim from 'lodash/trim';

import { logError } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import {
  FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
  STANDARD_DATE_FORMAT_DATE_FNS,
  STANDARD_DATE_TIME_FORMAT_DATE_FNS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { buildUrl, tryToParse } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';

import { ORDER_URL } from './order.url';

export async function getOrdersByReferenceAndSeller({
  sellerId,
  orderReference,
  context,
}) {
  try {
    if (!orderReference) {
      return [];
    }
    const response = await apiCall(
      buildUrl(
        ORDER_URL.GET_ORDER_BY_REFERENCE,
        {
          term: trim(orderReference),
          page: 1,
          pageSize: 32,
          sellerId,
          context,
        },
        { encode: true, arrayFormat: 'brackets' },
      ),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    const orders = response.value || [];

    return (
      orders
        .filter((order) => order.sellerId === sellerId)
        .map((order) => {
          const purchaseDateParseResponse = tryToParse({
            value: order.purchaseDate,
            patterns: [
              STANDARD_DATE_TIME_FORMAT_DATE_FNS,
              FULL_STANDARD_DATE_TIME_FORMAT_DATE_FNS,
              STANDARD_DATE_FORMAT_DATE_FNS,
            ],
          });

          if (purchaseDateParseResponse.failure) {
            throw new Error(
              `Failed to parse purchaseDate ${order.purchaseDate}`,
            );
          }

          return {
            ...order,
            storeId: order.storePurchasedAt,
            purchaseDate: purchaseDateParseResponse.value,
          };
        }) || []
    );
  } catch (e) {
    logError(e);

    return [];
  }
}

export async function getOrderIdByReceiptInformation({
  sellerId,
  receiptNumber,
  storeId,
  purchaseDateUtc,
}) {
  try {
    if (!sellerId || !receiptNumber || !storeId || !purchaseDateUtc) {
      return null;
    }

    const response = await apiCall(
      buildUrl(
        ORDER_URL.GET_ORDER_DETAILS_BY_RECEIPT_INFORMATION,
        {
          sellerId,
          receiptNumber,
          storeId,
          purchaseDateUtc,
        },
        { encode: true, arrayFormat: 'brackets' },
      ),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    if (!response || !response.value) {
      return null;
    }

    return response.value;
  } catch (e) {
    const firstError = head(get(e, ['errors']));

    if (
      (firstError && firstError.message === 'No Orders Found') ||
      firstError.message.includes('There is no data found')
    ) {
      return null;
    }

    logError(e, {
      endpoint: 'getOrderIdByReceiptInformation',
      sellerId,
      receiptNumber,
      storeId,
      purchaseDateUtc,
    });

    return null;
  }
}

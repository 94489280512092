import { useQuery } from 'react-query';

import { CommonClaimService } from '../CommonClaimService';

export const useGetAdditionalInformationSummary = ({
  additionalInformationId,
  token,
}: {
  additionalInformationId?: string | null;
  token?: string;
}) => {
  const { data, isLoading } = useQuery(
    ['getAdditionalInformationEntitiesSummaries', { additionalInformationId }],
    async () => {
      if (!additionalInformationId || !token) {
        return undefined;
      }

      const response =
        await CommonClaimService.getAdditionalInformationEntitiesSummariesAndTokenCommand(
          {
            ids: [additionalInformationId],
            token,
          },
        );

      if (response.failure) {
        return undefined;
      }

      return response.value[0];
    },
    {
      staleTime: Infinity,
    },
  );

  return {
    additionalInformationSummary: data,
    isLoading,
  };
};

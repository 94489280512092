import { defineMessages } from 'react-intl';

export default defineMessages({
  customPrice: {
    id: 'view.choosePricing.planPreview.planPrice.customPrice',
    defaultMessage: 'Custom',
  },
  onUsagePrice: {
    id: 'view.choosePricing.planPreview.planPrice.onUsagePrice',
    defaultMessage: '{price} /per file shipping included',
  },
  packagedPrice: {
    id: 'view.choosePricing.planPreview.planPrice.packagedPrice',
    defaultMessage: 'Starting from {price} /{billingCycle}',
  },
});

import { rgba } from 'polished';
import { DefaultTheme } from 'styled-components';

import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

export const newControlDefaultDarkTheme: Omit<
  Partial<DefaultTheme>,
  'newUI'
> & {
  newUI: Partial<DefaultTheme['newUI']>;
} = {
  colors: {
    primary: '#5369EB',
    primaryHover: undefined,
    secondary: '#5A72FF',
    secondaryColor1: '#2a2833',
    secondaryColor2: '#1f1e24',
    secondaryBackground: '#1a1a1d',
    tertiary: '#8C9FAA',
    success: '#0BB07B',
    danger: '#E15554',
    alert: '#f07300',
    neutralBackground: '#111111',
    white: '#ffffff',
    black: '#1F1F1F',
    darkBackground: '#111111',
    lightGrey: '#2a2833',
    default: '#C9CED6',
    disabled: '#3a3a3d',
    mainTextColor: '#FFFFFF',
    headingTextColor: '#5369EB',
    paragraphTextColor: '#8A94A6',
    headerTitleColor: '#212121',
    linkColor: '#5369EB',
    headerTextColor: '#54617a',
    purple: '#9c36f2',
    iconColor: '#FFFFFF',
    backgroundColor: '#212121',
    menuItemSeparator: '#666666',
    datatableRowBackgroundHoverColor: '#689ABA0D',
    datatableRowExpandedBackgroundColor: '#689ABA0D',
  },
  systemColors: {
    defaultTextColor: '#FFFFFF',
    invertedTextColor: '#2a2833',
    defaultBackground: '#111111',
    secondaryBackground: '#212121',
  },
  interactions: {
    basicTiming: '.2s',
    transformTiming: '.25s',
    activeScale: '.96',
    buttonHoverAsHollow: false,
  },
  buttons: {
    naked: {
      default: {
        highContrastColor: {
          value: '#FFFFFF',
        },
        highContrastOnHoverColor: {
          value: '#FFFFFF',
        },
        onHoverBackgroundColor: {
          value: 'rgba(255, 255, 255, 0.1)',
        },
        highContrastOnHoverBackgroundColor: {
          value: 'rgba(255, 255, 255, 0.08)',
        },
      },
      primary: {
        color: {
          value: 'colors.primary',
        },
        highContrastColor: {
          value: '#FFFFFF',
        },
        onHoverBackgroundColor: {
          value: rgba('#5369EB', 0.1),
        },
        highContrastOnHoverBackgroundColor: {
          value: 'rgba(255, 255, 255, 0.08)',
        },
      },
    },
  },
  components: {
    [SUPPORTED_COMPONENTS.DEFAULT_BUTTONS]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.white',
      },
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.headingTextColor',
      },
      [SUPPORTED_PROPERTIES.DISABLED_COLOR]: {
        fallback: 'colors.disabled',
      },
      [SUPPORTED_PROPERTIES.DISABLED_BACKGROUND_COLOR]: {
        fallback: 'colors.disabled',
      },
    },
    [SUPPORTED_COMPONENTS.COPY_TO_CLIPBOARD_BUTTONS]: {
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: `1px solid transparent`,
      },
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        value: 'transparent',
      },
      [SUPPORTED_PROPERTIES.ON_FOCUS_BACKGROUND_COLOR]: {
        value: 'transparent',
      },
    },
    [SUPPORTED_COMPONENTS.DEFAULT_LABEL]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.paragraphTextColor',
      },
    },
    [SUPPORTED_COMPONENTS.FINALIZE_CLAIM_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        value: '#d9b120',
      },
    },
    [SUPPORTED_COMPONENTS.VIEW_FILE_DETAILS_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        value: '#32c264',
      },
    },
    [SUPPORTED_COMPONENTS.SNOOZE_BUTTON]: {
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: '1px solid white',
      },
    },
    [SUPPORTED_COMPONENTS.MARK_AS_RESOLVED_BUTTON]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.white',
      },
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.primary',
      },
    },
    [SUPPORTED_COMPONENTS.BACK_BUTTON]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.mainTextColor',
      },
    },
    [SUPPORTED_COMPONENTS.AUTOCOMPLETE_OPTION]: {
      [SUPPORTED_PROPERTIES.ON_HOVER_BACKGROUND_COLOR]: {
        fallback: 'colors.secondaryColor1',
      },
      [SUPPORTED_PROPERTIES.ON_FOCUS_BACKGROUND_COLOR]: {
        fallback: 'colors.secondaryColor1',
      },
    },
    [SUPPORTED_COMPONENTS.CARD]: {
      coloredBackgroundColor: {
        value: '#212121',
      },
    },
    [SUPPORTED_COMPONENTS.IMPORT_REPORT_DELETED_BUTTON]: {
      [SUPPORTED_PROPERTIES.COLOR]: {
        value: '#636161',
      },
    },
    [SUPPORTED_COMPONENTS.ROUTE_TAB_SUB_MENU_CONTENT]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.darkBackground',
      },
      [SUPPORTED_PROPERTIES.COLOR]: {
        fallback: 'colors.white',
      },
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: '1px solid #3a3a3d',
      },
    },
    [SUPPORTED_COMPONENTS.CONFIGURATION_CONSOLE_SUMMARY_RULE]: {
      [SUPPORTED_PROPERTIES.BORDER]: {
        value: 'none',
      },
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.darkBackground',
      },
    },
    [SUPPORTED_COMPONENTS.CONFIGURATION_CONSOLE_DEFAULT]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.darkBackground',
      },
    },
    [SUPPORTED_COMPONENTS.DEBUG_INSPECTOR]: {
      [SUPPORTED_PROPERTIES.BACKGROUND_COLOR]: {
        fallback: 'colors.darkBackground',
      },
    },
  },
  newUI: {
    gradients: {
      blueStart: '#010B35',
      blueEnd: '#002E74',
      purpleStart: '#151F4C',
      purpleMiddle: '#482F99',
      purpleEnd: '#4F56D1',
      lightStart: '#EEEFFE',
      lightMiddle: '#EEEFFE99',
      lightEnd: '#E1E5FD',
    },
    defaultColors: {
      menu: '#212121',
      heading: '#fff',
      primary: '#5369EB',
      secondary: '#5A72FF',
      text: '#FFFFFF',
      secondaryText: '#eeeeee',
    },
    inputBorderColors: {
      default: '#CCD2F9',
    },
    dividerColors: {
      default: '#DDE1FB',
    },
    iconColors: {
      primary: {
        color: '#5369EB',
        bgColor: '#1f1e24',
      },
      secondary: {
        color: '#A661FF',
        bgColor: '#1f1e24',
      },
      alert: {
        color: '#F36C6C',
        bgColor: '#1f1e24',
      },
      caution: {
        color: '#FB6D86',
        bgColor: '#1f1e24',
      },
      danger: {
        color: '#E25E5E',
        bgColor: '#1f1e24',
      },
      info: {
        color: '#3AACC6',
        bgColor: '#1f1e24',
      },
      success: {
        color: '#31BF95',
        bgColor: '#1f1e24',
      },
      warning: {
        color: '#FF7B1A',
        bgColor: '#1f1e24',
      },
      disabled: {
        color: '#eeeeee',
        bgColor: '#1f1e24',
      },
      gold: {
        color: '#CC9300',
        bgColor: '#1f1e24',
      },
    },
    tagsColors: {
      primary: {
        color: '#3F51BD',
        bgColor: '#1f1e24',
      },
      secondary: {
        color: '#FFFFFF',
        bgColor: '#002E74',
      },
      danger: {
        color: '#E25E5E',
        bgColor: '#1f1e24',
      },
      success: {
        color: '#07875D',
        bgColor: '#1f1e24',
      },
      alert: {
        color: '#FF7B1A',
        bgColor: '#1f1e24',
      },
      disabled: {
        color: '#8A94A6',
        bgColor: '#1f1e24',
      },
      neutral: {
        color: '#FFFFFF',
        bgColor: '#2a2833',
      },
    },
    variationColors: {
      light1: '#5A72FF',
      light2: '#A2ABE9',
      light3: '#CCD2F9',
      light4: '#DDE1FB',
      light5: '#EEF0FD',
      light6: '#F4F6FF',
      light7: '#FBFBFF',
      dark1: '#465AD3',
      dark2: '#3F51BD',
    },
  },
};

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';

import {
  ALLOWED_MIME_TYPES,
  IMAGE_DOCUMENT_TYPES,
  MIME_TYPES,
} from '@savgroup-front-common/constants';
import { useGetAdditionalInformationSummary } from '@savgroup-front-common/core/src/api/Claim/hooks/useGetAdditionalInformationSummary';
import {
  FormGroup,
  MultiFileUploadHookForm,
} from '@savgroup-front-common/core/src/atoms/Form';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import {
  $CardContent,
  $CardPosition,
  $ImportMessage,
  $LogoPosition,
} from '../QrcodeUpload.styles';

import { useMultiAdditionalInformationUploadFile } from './hooks/useMultiAdditionalInformationUploadFile';
import messages from './messages';
import { useLogo } from './MultiAdditionalInformationQrcodeUpload.hooks';
import { $LogoImg } from './MultiAdditionalInformationQrcodeUpload.styles';

interface MultiAdditionalInformationQrcodeUploadProps {
  additionalInformationId: string;
  claimId: string;
  sellerId: string;
}

export const MultiAdditionalInformationQrcodeUpload: FC<
  MultiAdditionalInformationQrcodeUploadProps
> = ({ additionalInformationId, claimId, sellerId }) => {
  const { token } = useParams<{
    token: string;
  }>();

  const { search } = useLocation();

  const fileId = new URLSearchParams(search).get('fileId') as string;
  const fileReference = new URLSearchParams(search).get(
    'fileReference',
  ) as string;

  const { handleUploadDocuments, isUploadDocumentsLoading, files } =
    useMultiAdditionalInformationUploadFile({
      fileId,
      additionalInformationId,
      claimId,
      token,
    });

  const { imgRef, logoUrl, onError } = useLogo({
    sellerId,
  });

  const { additionalInformationSummary } = useGetAdditionalInformationSummary({
    additionalInformationId,
    token,
  });

  const allowedMimeTypes = Object.values(IMAGE_DOCUMENT_TYPES).includes(
    additionalInformationSummary?.additionalInformationEntity
      ?.informationType ?? '',
  )
    ? [MIME_TYPES.JPEG, MIME_TYPES.PNG, MIME_TYPES.IMAGE_HEIC, MIME_TYPES.PDF]
    : ALLOWED_MIME_TYPES;

  return (
    <$CardPosition>
      <$CardContent>
        <$LogoPosition>
          <$LogoImg ref={imgRef} alt="logo" src={logoUrl} onError={onError} />
        </$LogoPosition>
        <$ImportMessage>
          <SafeFormattedMessageWithoutSpread message={messages.importMessage} />
        </$ImportMessage>
        <$ImportMessage>{fileReference}</$ImportMessage>

        <FormGroup>
          <MultiFileUploadHookForm
            allowedMimeTypes={allowedMimeTypes}
            hollow
            dropzone
            isLiveUpload
            onSelect={(files) => handleUploadDocuments(files)}
            files={files}
            isFullWidth
            errors={{}}
            name="fileUpload"
            isLoading={isUploadDocumentsLoading}
            dropZoneLabel={messages.dropZoneFileLabel}
          />
        </FormGroup>
      </$CardContent>
    </$CardPosition>
  );
};

MultiAdditionalInformationQrcodeUpload.displayName =
  'MultiAdditionalInformationQrcodeUpload';

import React, { FC, useState } from 'react';
import { generatePath } from 'react-router-dom';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { ButtonLink } from '@savgroup-front-common/core/src/atoms/button';
import Button from '@savgroup-front-common/core/src/atoms/button/Button';
import { MODAL_SIZES } from '@savgroup-front-common/core/src/atoms/modal';
import Modal from '@savgroup-front-common/core/src/atoms/modal/Modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { PRICING_PLAN } from '@savgroup-front-common/types';

import { BASES } from '../../../../../../constants/routing';
import { useAppContext } from '../../../../../app/App.context';
import { useChoosePlanContext } from '../../../ChoosePlanDrawer.context';
import { PlanDto } from '../../ChoosePlan.types';

import { ConfirmPushToProductionModalContent } from './ConfirmPushToProductionModalContent/ConfirmPushToProductionModalContent';
import messages from './messages';

interface ChoosePlanButtonProps {
  plan: PlanDto;
}

export const ChoosePlanButton: FC<ChoosePlanButtonProps> = ({ plan }) => {
  const { handleDrawerClose } = useChoosePlanContext();
  const { selectedSeller } = useAppContext();
  const [
    isConfirmPushToProductionModalOpen,
    setIsConfirmPushToProductionModalOpen,
  ] = useState(false);

  const handleOpenConfirmPushToProductionModal = () => {
    setIsConfirmPushToProductionModalOpen(true);
  };

  const handleCloseConfirmPushToProductionModal = () => {
    setIsConfirmPushToProductionModalOpen(false);
  };

  const isDemoWithNoPricingPlan =
    selectedSeller?.pricingPlan === PRICING_PLAN.NONE;

  return (
    <>
      {!isDemoWithNoPricingPlan && (
        <ButtonLink
          primary
          href={generatePath(BASES.FROM_SCRATCH)}
          onClick={() => {
            handleDrawerClose();
          }}
        >
          <SafeFormattedMessageWithoutSpread message={messages.getStarted} />
        </ButtonLink>
      )}

      {isDemoWithNoPricingPlan && (
        <Button
          type={BUTTON_TYPES.BUTTON}
          primary
          onClick={() => {
            handleOpenConfirmPushToProductionModal();
          }}
          fluid
        >
          <SafeFormattedMessageWithoutSpread message={messages.getStarted} />
        </Button>
      )}

      <Modal
        isOpen={isConfirmPushToProductionModalOpen}
        onClose={handleCloseConfirmPushToProductionModal}
        size={MODAL_SIZES.SMALL}
      >
        <ConfirmPushToProductionModalContent
          onClose={handleCloseConfirmPushToProductionModal}
          plan={plan}
        />
      </Modal>
    </>
  );
};

import { addShopifyIntegrationCommand } from './addShopifyIntegrationCommand';
import checkAdapterStatusQuery from './checkAdapterStatusQuery';
import { createSellerCommand } from './createSellerCommand';
import createShippingBoTokenCommand from './createShippingBoTokenCommand';
import deleteAdapterConfiguration from './deleteAdapterConfiguration';
import deleteServiceBusConfiguration from './deleteServiceBusConfiguration';
import getAdapterConfiguration from './getAdapterConfiguration';
import { getAvailableCountriesQuery } from './getAvailableCountriesQuery';
import { getConsoleConfigurationQuery } from './getConsoleConfigurationQuery';
import { getConsoleEntriesVisibilityQuery } from './getConsoleEntriesVisibilityQuery';
import { getConsoleFullConfigurationQuery } from './getConsoleFullConfigurationQuery';
import { getConsoleRestrictedColumnsQuery } from './getConsoleRestrictedColumnsQuery';
import { getRefundMethodsBySellerQuery } from './getRefundMethodsBySellerQuery';
import getSalesChannelsBySellerId from './getSalesChannelsBySellerId';
import getSellerBillingConfigurationQuery from './getSellerBillingConfigurationQuery';
import getSellerConfigurationById from './getSellerConfigurationById';
import { getSellerConfigurationSalesforceQuery } from './getSellerConfigurationSalesforceQuery';
import getSellerExportBySellerId from './getSellerExportBySellerId';
import getSellerIntegrationStatusQuery from './getSellerIntegrationStatusQuery';
import getServiceBusConfiguration from './getServiceBusConfiguration';
import getStoresBySellerId from './getStoresBySellerId';
import { pushToProductionCommand } from './pushToProductionCommand';
import cancelSanityTest from './sanityTest/cancelSanityTest';
import getSanityTestsList from './sanityTest/getSanityTestsList';
import startNewSanityTest from './sanityTest/startNewSanityTest';
import { getSellersQuery } from './sellers/getSellersQuery';
import getSellerSummaryById from './sellers/getSellerSummaryById';
import setAdapterConfiguration from './setAdapterConfiguration';
import setSellerBillingConfigurationQuery from './setSellerBillingConfigurationQuery';
import { setSellerConfigurationSalesforceCommand } from './setSellerConfigurationSalesforceCommand';
import setServiceBusConfiguration from './setServiceBusConfiguration';
import setSetSellerConfigurationStripeAccountPublicKey from './setSetSellerConfigurationStripeAccountPublicKey';
import uploadThemeAssets from './uploadThemeAssets';
import { UpsertConsoleEntryVisibilityCommand } from './UpsertConsoleEntryVisibilityCommand';
import { UpsertConsoleRestrictedColumnsCommand } from './UpsertConsoleRestrictedColumnsCommand';
import upsertSellerCommand from './upsertSellerCommand';

export const SellerService = {
  upsertSellerCommand,
  createSellerCommand,
  getAvailableCountriesQuery,
  getSellersQuery,
  getSellerConfigurationSalesforceQuery,
  setSellerConfigurationSalesforceCommand,
  cancelSanityTest,
  getSanityTestsList,
  startNewSanityTest,

  getSellerSummaryById,
  getSellerConfigurationById,
  getSellerExportBySellerId,
  setSetSellerConfigurationStripeAccountPublicKey,
  uploadThemeAssets,

  getServiceBusConfiguration,
  deleteServiceBusConfiguration,
  setServiceBusConfiguration,
  getAdapterConfiguration,
  deleteAdapterConfiguration,
  setAdapterConfiguration,

  getConsoleEntriesVisibilityQuery,
  getConsoleRestrictedColumnsQuery,
  UpsertConsoleRestrictedColumnsCommand,
  UpsertConsoleEntryVisibilityCommand,
  getConsoleConfigurationQuery,
  getConsoleFullConfigurationQuery,
  getRefundMethodsBySellerQuery,
  getStoresBySellerId,
  getSalesChannelsBySellerId,
  getSellerBillingConfigurationQuery,
  setSellerBillingConfigurationQuery,
  getSellerIntegrationStatusQuery,
  createShippingBoTokenCommand,
  checkAdapterStatusQuery,

  addShopifyIntegrationCommand,
  pushToProductionCommand,
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { searchTodosList } from 'control/domains/todosList/actionCreators';
import { useControlTypedSelector } from 'control/hooks';

import { ROUTES } from '../../../../constants/routing';
import {
  selectTodosListErrors,
  selectTodosListIsLoading,
  selectTodosListWasLoaded,
} from '../../../../domains/todosList/selectors';

const useLoadTodos = (): void => {
  const dispatch = useDispatch();

  const wasLoaded = useControlTypedSelector((state) =>
    selectTodosListWasLoaded(state, { page: 1 }),
  );
  const isLoading = useControlTypedSelector((state) =>
    selectTodosListIsLoading(state, { page: 1 }),
  );
  const errors = useControlTypedSelector((state) =>
    selectTodosListErrors(state, { page: 1 }),
  );
  const isTodoRoute = useMatch(ROUTES.TODOS);

  useEffect(() => {
    if (!wasLoaded && !isLoading && !isTodoRoute && errors.length === 0) {
      dispatch(searchTodosList());
    }
  }, [dispatch, wasLoaded, isLoading, isTodoRoute, errors]);
};

export default useLoadTodos;

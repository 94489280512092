import React, { FunctionComponent } from 'react';

import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { MessageType } from '@savgroup-front-common/types';

import { $MainLink, $MenuItemTitle } from '../Menu.styles';

interface MenuLinkProps {
  to: string;
  message: MessageType;

  dataTestId: string;
}
const MenuLink: FunctionComponent<React.PropsWithChildren<MenuLinkProps>> = ({
  to,
  message,
  dataTestId,
}) => {
  const { isDarkModeEnabled } = useNewUiContext();

  return (
    <$MainLink
      to={to}
      title={safeFormattedIntlString(message)}
      data-testid={dataTestId}
      $isDarkModeEnabled={isDarkModeEnabled}
    >
      <$MenuItemTitle>
        <SafeFormattedMessageWithoutSpread message={message} />
      </$MenuItemTitle>
    </$MainLink>
  );
};

export default React.memo(MenuLink);

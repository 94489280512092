const { hostname } = window.location;

function setAuthority(authority: string | undefined = undefined) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.authority = authority || 'https://dev-login.revers.io';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this;
}

const mapClientIdByHostname: Record<string, string> = {
  'dev-app.revers.io': 'dev-control.revers.io',
  'dev-app2.revers.io': 'dev-control.revers.io',
  'qualif-app.revers.io': 'dev-control.revers.io',
  'qualif-app2.revers.io': 'dev-control.revers.io',
};

const AuthConfiguration = {
  authority: 'https://dev-login.revers.io',
  setAuthority,
  clientId: 'dev-control.revers.io',
  scope: 'openid email api',
};

if (hostname !== 'localhost' && !hostname.includes('.dev-control.pages.dev')) {
  AuthConfiguration.clientId =
    mapClientIdByHostname[hostname] || window.location.hostname;
}

AuthConfiguration.setAuthority();

export default AuthConfiguration;

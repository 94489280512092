import React, { ChangeEvent, FC, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';
import { useTheme } from 'styled-components';

import {
  ALLOWED_MIME_TYPES_QR_CODE,
  BUTTON_TYPES,
} from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { NewReversLogoIcon } from '@savgroup-front-common/core/src/protons/icons';
import { HTMLFileInputElement } from '@savgroup-front-common/types';

import { useUploadFile } from '../hooks';
import messages from '../messages';
import {
  $CardContent,
  $CardPosition,
  $HiddenInput,
  $ImportMessage,
  $LogoPosition,
  $QrcodeUploadFileInputButton,
  $QrcodeUploadFileInputContainer,
  $UploadMessage,
} from '../QrcodeUpload.styles';

export const FileQrcodeUpload: FC = () => {
  const { search } = useLocation();

  const fileId = new URLSearchParams(search).get('fileId') as string;
  const fileReference = new URLSearchParams(search).get(
    'fileReference',
  ) as string;

  const { token } = useParams<{
    token: string;
  }>();

  const fileUpload = useRef<HTMLInputElement>(null);

  const handleClickInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (fileUpload.current) {
      fileUpload.current.click();
    }
    event.preventDefault();
  };

  const {
    handleUploadDocuments,
    isUploadDocumentsLoading,
    importedDocumentInErrorCount,
    importedDocumentInSuccessCount,
  } = useUploadFile({
    fileId,
    token,
  });

  const theme = useTheme();

  return (
    <$CardPosition>
      <$CardContent>
        <div>
          <$LogoPosition>
            <NewReversLogoIcon
              size="120px"
              color={theme.newUI.defaultColors.menu}
              dotColor={theme.newUI.defaultColors.primary}
            />
          </$LogoPosition>
          <$ImportMessage>
            <SafeFormattedMessageWithoutSpread
              message={messages.importMessage}
            />
          </$ImportMessage>
        </div>

        <$ImportMessage>{fileReference}</$ImportMessage>
        <$QrcodeUploadFileInputContainer>
          <$QrcodeUploadFileInputButton
            primary
            type={BUTTON_TYPES.BUTTON}
            onClick={handleClickInput}
            isLoading={isUploadDocumentsLoading}
            danger={importedDocumentInErrorCount > 0}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.qrcodeUploadButton}
            />
          </$QrcodeUploadFileInputButton>
          {importedDocumentInErrorCount > 0 && (
            <$UploadMessage $error>
              <SafeFormattedMessageWithoutSpread
                message={messages.importDocumentError}
                values={{ count: importedDocumentInErrorCount }}
              />
            </$UploadMessage>
          )}
          {importedDocumentInSuccessCount > 0 && (
            <$UploadMessage>
              <SafeFormattedMessageWithoutSpread
                message={messages.importDocumentSuccess}
                values={{ count: importedDocumentInSuccessCount }}
              />
            </$UploadMessage>
          )}
          <$HiddenInput
            multiple
            type="file"
            accept={ALLOWED_MIME_TYPES_QR_CODE.join(',')}
            ref={fileUpload}
            onChange={(e: ChangeEvent<HTMLFileInputElement>) => {
              const files: File[] = Array.from(e?.target?.files);

              if (files.length < 1) {
                return;
              }
              handleUploadDocuments(files);
              e.target.value = '';
            }}
          />
        </$QrcodeUploadFileInputContainer>
      </$CardContent>
    </$CardPosition>
  );
};

FileQrcodeUpload.displayName = 'FileQrcodeUpload';

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from 'redux-oidc';

import { selectLoginErrors } from '@savgroup-front-common/core/src/domains/login/selectors';
import {
  buildErrorNotification,
  getUniqErrorMessages,
} from '@savgroup-front-common/core/src/helpers';
import { UserManagerConfig } from '@savgroup-front-common/core/src/services';

import { signIn } from '../../../domains/login/actions';

import LoadingScreen from './LoadingScreen';
import { useOidcContext } from './OidcProvider';

const useOAuthServer = () => {
  const { userManager } = useOidcContext();
  const dispatch = useDispatch();
  const errors = useSelector(selectLoginErrors);

  const notifications = getUniqErrorMessages(errors).map(
    buildErrorNotification,
  );

  const navigate = useNavigate();

  const onSuccess = useCallback(
    (user) => {
      const urlBeforeRedirection = user.state.redirectUrl;

      if (!urlBeforeRedirection.startsWith('/')) {
        throw new Error('Invalid redirect URL');
      }

      dispatch(
        signIn(() =>
          urlBeforeRedirection ? navigate(urlBeforeRedirection) : undefined,
        ),
      );
    },
    [dispatch, navigate],
  );

  const onError = useCallback(
    async (...params) => {
      const { cache: userManagerConfigCache } = UserManagerConfig;
      const resetPassword = userManagerConfigCache?.resetPassword;

      if (params && params.length > 0) {
        if (params[0].message.search('AADB2C90118') === 0) {
          resetPassword();
        } else {
          userManager.current.signinRedirect({
            data: {
              redirectUrl: '/',
            },
          });
        }
      }
    },
    [userManager],
  );

  return {
    onError,
    onSuccess,
    notifications,
  };
};

const OAuthServerCallback = () => {
  const { userManager } = useOidcContext();
  const { onError, onSuccess, notifications } = useOAuthServer();

  return (
    <CallbackComponent
      userManager={userManager.current}
      successCallback={onSuccess}
      errorCallback={onError}
    >
      <LoadingScreen notifications={notifications} />
    </CallbackComponent>
  );
};

export default OAuthServerCallback;
